import { Formik } from 'formik'
import { Button, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap'
import { object, string } from 'yup'
import * as PropTypes from 'prop-types'
import useDataService from '../../hooks/useDataService'
import { ROLE } from '../../helpers'
import { useContext } from 'react'
import { EventContext } from '../../contexts/EventContext'

const propTypes = {
  show: PropTypes.bool.isRequired,
  onCreated: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
}

const NewUserModal = ({ show, onCreated, onHide }) => {
  const { event } = useContext(EventContext)
  const { apiCreateStaff } = useDataService()

  const locationSchema = object({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    email: string().required('Email is required').email('Please enter a valid email'),
    phone: string().notRequired().matches(/^[+]?[0-9]+$/),
    role: string().required()
  })

  const onboardStaff = async (values, bag) => {
    bag.setSubmitting(true)
    await apiCreateStaff(values)
    onHide()
    onCreated()
  }

  return (
    <Modal show={show} fullscreen={true} onHide={onHide} >
      <Modal.Header closeButton className='mw-750 w-75 mx-auto'>
        <Modal.Title>Add new user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className='mw-750'>
          <Formik
            initialValues={{
              email: '',
              phone: '',
              firstName: '',
              lastName: '',
              role: ROLE.Stringer,
              country: 'AUS',
              tournaments: [event._id]
            }}
            validationSchema={locationSchema}
            onSubmit={onboardStaff}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              handleSubmit,
              isSubmitting
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col} xs={12} md={6} controlId='firstName' className='mb-3'>
                    <h5>First name</h5>
                    <Form.Control
                      type="text"
                      value={values.firstName}
                      onChange={handleChange}
                      placeholder='First name'
                      isInvalid={!!errors.firstName && touched.firstName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6} controlId='lastName' className='mb-3'>
                    <h5>Last name</h5>
                    <Form.Control
                      type="text"
                      value={values.lastName}
                      onChange={handleChange}
                      placeholder='Last name'
                      isInvalid={!!errors.lastName && touched.lastName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                  </Form.Group>
                </Row>

                <h5>User role</h5>
                <Dropdown onSelect={eventKey => setFieldValue('role', eventKey)} className='mb-3'>
                  <Dropdown.Toggle variant='input' className='w-100 justify-content-between'>{values.role}</Dropdown.Toggle>
                  <Dropdown.Menu className='bg-white mt-1 shadow-sm w-100'>
                    {[ROLE.Stringer, ROLE.Admin].map((r) => (
                      <Dropdown.Item key={r} eventKey={r} active={r === values.role}>{r}</Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <Form.Group controlId='email' className='mb-3'>
                  <h5>Email</h5>
                  <Form.Control
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                    placeholder='Email'
                    isInvalid={!!errors.email && touched.email}
                  />
                  <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId='phone' className='mb-3'>
                  <h5>Phone</h5>
                  <Form.Control
                    type="text"
                    value={values.phone}
                    onChange={handleChange}
                    placeholder='Phone number'
                    isInvalid={!!errors.phone && touched.phone}
                  />
                  <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </Form.Group>

                <div className='text-center'>
                  <Button
                    type='submit'
                    variant='primary'
                    disabled={isSubmitting}
                  >Create</Button>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

NewUserModal.propTypes = propTypes

export default NewUserModal
