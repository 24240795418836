import { Container, Row, Col } from 'react-bootstrap'
import * as PropTypes from 'prop-types'

const propTypes = {
  racquets: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  showPromoCode: PropTypes.bool
}

const TotalsRow = ({ racquets, promoCode, showPromoCode }) => {
  return (
    <Container className='mb-5 px-3'>
      <Row>
        <Col xs={6}>
          <h6 className='text-body'>Subtotal</h6>
        </Col>
        <Col xs={6} className='text-end'>
          <h6 className='text-body'>${racquets.reduce((prev, curr) => prev + (curr.price * curr.quantity), 0).toFixed(2)}</h6>
        </Col>
      </Row>
      {!!promoCode?.discount &&
        <Row>
          <Col xs={6}>
            <h6 className='text-body'>Discount {showPromoCode && `(${promoCode.code})`}</h6>
          </Col>
          <Col xs={6} className='text-end'>
            <h6 className='text-body'>-${(racquets.reduce((prev, curr) => prev + (curr.price * curr.quantity), 0) * (promoCode?.discount ?? 0)).toFixed(2)}</h6>
          </Col>
        </Row>
      }
      <Row>
        <Col xs={6}>
          <h5 className='text-body'>Total</h5>
        </Col>
        <Col xs={6} className='text-end'>
          <h5 className='text-body'>${(racquets.reduce((prev, curr) => prev + (curr.price * curr.quantity), 0) * (1 - (promoCode?.discount ?? 0))).toFixed(2)}</h5>
        </Col>
      </Row>
    </Container>
  )
}

TotalsRow.propTypes = propTypes

export default TotalsRow
