import _ from 'lodash'
import { STATUS } from '../../helpers'
import { useContext } from 'react'
import { OrderContext } from '../../contexts/OrderContext'

const ProgressTimeline = () => {
  const statuses = Object.values(STATUS)
  const { order } = useContext(OrderContext)

  return (
    <div id='progress-timeline' className='d-flex align-items-center overflow-x-scroll pt-5 pb-5'>
      {statuses.slice(0, 4).map((s, i, arr) => (
        <div key={i} className='flex-shrink-0'>
          <div className='d-flex align-items-center mb-5'>
            <span className={`status-node ${s.index <= order.status ? 'active' : ''}`}/>
            { i !== arr.length - 1 && <span className={`status-connector ${s.index === order.status ? 'active' : (s.index <= order.status ? 'done' : '')}`}/> }
          </div>
          <span className={s.index === order.status ? 'text-body' : 'text-muted text-small'}>{_.capitalize(s.name)}</span>
        </div>
      ))}
    </div>
  )
}

export default ProgressTimeline
