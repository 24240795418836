import { Container } from 'react-bootstrap'
import { OrderFormProvider } from '../contexts/OrderFormContext'
import CreateOrderManager from '../components/order/CreateOrderManager'
import withTournamentSelectionRequired from '../components/generic/withTournamentSelectionRequired'

const EditOrder = () => {
  return (
    <OrderFormProvider edit>
      <Container className='h-100 px-md-5 ms-md-0 mw-750'>
        <CreateOrderManager />
      </Container>
    </OrderFormProvider>
  )
}

export default withTournamentSelectionRequired(EditOrder)
