import { Button, Col, Container, Row } from 'react-bootstrap'
import { useContext } from 'react'
import OrderList from '../order-list/OrderList'
import { Formik } from 'formik'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import { Navigate, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import Icon from '../../icons/Icon'
import CurrentEventWidget from '../common/CurrentEventWidget'

const AdminDashboard = () => {
  const { tournament, features } = useContext(SharedDataContext)
  const navigate = useNavigate()

  if (!tournament) {
    return <Navigate to={ROUTES.SelectTournament} />
  }

  return (
    <Container className='mt-5 px-0'>
      <Row className='justify-content-between mx-1 mb-2'>
        <Col xs={12} md='auto'>
          <h3>Dashboard</h3>
          <CurrentEventWidget className='d-lg-none' />
        </Col>
        <Col xs={12} md='auto'>
          {features?.createOrder && new Date() < new Date(tournament.closedDate) && <Button
            type='button'
            variant='primary'
            className='w-100 mb-1'
            onClick={() => navigate(ROUTES.Order)}
          >
            <Icon icon='plus' size={16} />
            <span>Create re-string</span>
          </Button>
          }
        </Col>
      </Row>
      <Formik
        initialValues={{
          sort: {
            title: 'Order ID (desc)',
            sortField: 'id',
            sortDirection: 'desc'
          },
          filters: {
            tournament: tournament._id,
            status: [],
            cancelled: true,
            stringer: [],
            orderDate: '',
            paymentStatus: []
          },
          search: ''
        }}
        onSubmit={() => { }}
      >
        <OrderList filter search />
      </Formik>
    </Container>
  )
}

export default AdminDashboard
