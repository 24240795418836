import { DateTime } from 'luxon'
import { Customer } from './Customer'

export class Order {
  customer = new Customer(false)
  dueDate = DateTime.now().plus({ days: 1 }).set({ hour: 10, minute: 0, second: 0, millisecond: 0 })
  oneHrBeforeMatch = false
  restringTime = undefined
  racquets = []
  stringer = null
  promoCode = {
    code: '',
    discount: 0
  }

  constructor (tournament) {
    this.tournament = tournament
  }
}
