import { Container } from 'react-bootstrap'
import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import { Formik } from 'formik'
import OrderList from '../order-list/OrderList'

const StringerDashboard = () => {
  const { tournament, user } = useContext(SharedDataContext)

  if (!tournament) {
    return <Navigate to={ROUTES.SelectTournament} />
  }

  return (
    <Container className='mt-5'>
      <h4>Dashboard</h4>
      <Formik
        initialValues={{
          sort: {
            title: 'Order ID (desc)',
            sortField: 'id',
            sortDirection: 'desc'
          },
          filters: {
            tournament: tournament._id,
            status: [],
            cancelled: true,
            stringer: [user._id],
            orderDate: '',
            paymentStatus: []
          },
          search: ''
        }}
        onSubmit={() => { }}
      >
        <OrderList filter search />
      </Formik>
    </Container>
  )
}

export default StringerDashboard
