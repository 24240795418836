import { useFormikContext } from 'formik'
import DateRangeFields from './DateRangeFields'
import { Form } from 'react-bootstrap'
import EventOrDatePicker from './EventOrDatePicker'
import TournamentDropdown from '../common/TournamentDropdown'

const PlayerInvoiceForm = () => {
  const { values, errors, touched, setFieldValue, handleSubmit } = useFormikContext()

  return (
    <Form onSubmit={handleSubmit} noValidate className='mx-0 px-2 gy-1 mb-3'>
      <h5>Select a reporting period</h5>
      <EventOrDatePicker />
      { values.range === 'byEvent'
        ? <TournamentDropdown value={values.event} handleSelect={(t) => setFieldValue('event', t)} isInvalid={touched.event && errors.event}/>
        : <DateRangeFields />
      }
    </Form>
  )
}

export default PlayerInvoiceForm
