import { useContext } from 'react'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { ROLE } from '../../helpers'

const withCompleteProfileRequired = (WrappedComponent) => {
  const CompleteProfileRequired = (props) => {
    const { user } = useContext(SharedDataContext)

    if (user && user.role === ROLE.Player && (!user.firstName || !user.lastName)) {
      return <Navigate to={ROUTES.CompleteProfile} />
    } else if (!user) {
      return null
    }

    return <WrappedComponent {...props} />
  }
  return CompleteProfileRequired
}

export default withCompleteProfileRequired
