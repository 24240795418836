import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import useDataService from '../../hooks/useDataService'
import { Alert, Button, Col, Container, Dropdown, Form, Row } from 'react-bootstrap'
import { Formik } from 'formik'
import { EventContext } from '../../contexts/EventContext'
import { object, string } from 'yup'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import ConfirmationModal from '../generic/ConfirmationModal'

const PrinterSettingsForm = () => {
  const emptyPrinterSettings = {
    computer: {
      id: '',
      name: ''
    },
    racquetTagPrinter: {
      id: '',
      name: ''
    },
    throatStickerPrinter: {
      id: '',
      name: ''
    }
  }

  const parsePrinterSettings = data => {
    if (data) {
      return {
        ...data,
        computer: {
          id: data.racquetTagPrinter?.computer?.id ?? data.throatStickerPrinter?.computer?.id,
          name: data.racquetTagPrinter?.computer?.name ?? data.throatStickerPrinter?.computer?.name
        }
      }
    } else {
      return null
    }
  }

  const { setBanner, user, setUser } = useContext(SharedDataContext)
  const { event } = useContext(EventContext)
  const { apiGetPrinters, apiPutPrinterSettings, apiOverridePrinterSettings, apiDeletePrinterSettingsOverride } = useDataService()
  const [printers, setPrinters] = useState([])
  const [computers, setComputers] = useState([])
  const [override, setOverride] = useState(!!user.printerOverrides?.[event._id])
  const [showModal, setShowModal] = useState(false)

  const validationSchema = object({
    computer: object({
      id: string().required()
    }),
    racquetTagPrinter: object({
      id: string().required()
    }),
    throatStickerPrinter: object({
      id: string().optional()
    })
  })

  useEffect(() => {
    apiGetPrinters().then(res => {
      setComputers(_.uniqBy(res.map(p => p.computer), c => c.id))
      setPrinters(res)
    })
  }, [])

  const handleSave = async (data, bag) => {
    bag.setSubmitting(true)
    if (override) {
      const res = await apiOverridePrinterSettings(event._id, data)
      setUser(res)
      bag.setValues(parsePrinterSettings(res.printerOverrides[event._id]))
    } else {
      const res = await apiPutPrinterSettings(event._id, data)
      bag.setValues(parsePrinterSettings(res.printerSettings))
    }
    setBanner('Saved successfully')
    bag.setSubmitting(false)
  }

  const handleOverrideSwitch = async (current, setValues) => {
    if (current) {
      setShowModal(true)
    } else {
      setOverride(true)
      setValues(emptyPrinterSettings)
    }
  }

  return (
    <Container className='ps-md-5 pt-2 ms-md-0 mw-750'>
      <Formik
        initialValues={parsePrinterSettings(user.printerOverrides?.[event._id] ?? event.printerSettings) ?? emptyPrinterSettings}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={handleSave}
      >
        {({
          values,
          errors,
          handleSubmit,
          setFieldValue,
          validateForm,
          isValid,
          isSubmitting,
          setValues
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Alert variant='secondary'>
              <p className='fs-4 mb-2'>{override ? 'Personal printer settings in effect.' : 'Tournament-level printer settings in effect.'}</p>
              <Button type='button' variant='secondary' className='w-100' onClick={() => handleOverrideSwitch(override, setValues)}>{ override ? 'Revert to tournament settings' : 'Set personal printer settings' }</Button>
              {/* <p>You are using {override ? 'personal' : 'tournament-level'} printer settings</p> */}
            </Alert>
            <ConfirmationModal
              show={showModal}
              headerText='Reset settings?'
              bodyText='Are you sure you want to reset your personal printer settings?'
              actionButtonText='Reset'
              handleCancel={() => setShowModal(false)}
              handleConfirm={async () => {
                const res = await apiDeletePrinterSettingsOverride(event._id)
                setUser(res)
                setOverride(false)
                setValues(parsePrinterSettings(event.printerSettings) ?? emptyPrinterSettings)
                setShowModal(false)
              }}
              danger
            />
            <Row>
              <Col xs={12} md={8}>
                <h4 className='mb-2'>Choose a computer</h4>
                <p>Make sure to pick a computer that works for your location.<br />If unsure, ask your colleagues for help.</p>
                <Dropdown
                  onSelect={async (eventKey) => {
                    const fieldName = 'computer'
                    const computer = _.find(computers, c => c.id.toString() === eventKey)
                    await setFieldValue(`${fieldName}.id`, computer.id)
                    await setFieldValue(`${fieldName}.name`, computer.name)
                    validateForm()
                    const resetFields = ['racquetTagPrinter', 'throatStickerPrinter']
                    resetFields.forEach(f => {
                      setFieldValue(`${f}.id`, '')
                      setFieldValue(`${f}.name`, '')
                      setFieldValue(`${f}.computer.id`, '')
                      setFieldValue(`${f}.computer.name`, '')
                    })
                  }} className='mt-2 mb-4'>
                  <Dropdown.Toggle variant="input" className='w-100 justify-content-between'>
                    {values.computer?.id !== '' ? values.computer?.name : 'Select computer'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='w-100 bg-white shadow-sm'>
                    {computers.map(c => (
                      <Dropdown.Item key={c.id} eventKey={c.id}>{c.name} <span className={`${c.state === 'disconnected' ? 'text-danger' : 'text-success'}`}>&#x2022; {c.state}</span></Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className={`${errors.computer ? 'opacity-25' : ''}`}>
                  <h4 className='mb-2'>Raquet tag printer</h4>
                  <p>Prints stickers with detailed order information to help stringers understand the order requirements.</p>
                  <Dropdown
                    onSelect={async (eventKey) => {
                      const fieldName = 'racquetTagPrinter'
                      const printer = _.find(printers, p => p.id.toString() === eventKey)
                      await setFieldValue(`${fieldName}.id`, printer.id)
                      await setFieldValue(`${fieldName}.name`, printer.name)
                      await setFieldValue(`${fieldName}.computer.id`, printer.computer.id)
                      await setFieldValue(`${fieldName}.computer.name`, printer.computer.name)
                      validateForm()
                    }}
                    className='mt-2 mb-4'
                  >
                    <Dropdown.Toggle variant="input" disabled={errors.computer} className='w-100 justify-content-between'>
                      {values.racquetTagPrinter.id !== '' ? values.racquetTagPrinter.name : 'Select printer'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='w-100 bg-white shadow-sm'>
                      {printers.filter(p => p.computer.id === parseInt(values.computer.id)).map(p => (
                        <Dropdown.Item key={p.id} eventKey={p.id}>{p.name} <span className={`${p.state === 'online' ? 'text-success' : 'text-danger'}`}>&#x2022; {p.state}</span></Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>

                  <h4 className='mb-2 mt-2'>Throat sticker printer</h4>
                  <p>Prints stickers with a summary of stringing parameters to help players quickly identify a racquet.</p>
                  <Dropdown
                    onSelect={async (eventKey) => {
                      const fieldName = 'throatStickerPrinter'
                      const printer = _.find(printers, p => p.id.toString() === eventKey)
                      await setFieldValue(`${fieldName}.id`, printer.id)
                      await setFieldValue(`${fieldName}.name`, printer.name)
                      await setFieldValue(`${fieldName}.computer.id`, printer.computer.id)
                      await setFieldValue(`${fieldName}.computer.name`, printer.computer.name)
                      validateForm()
                    }}
                    className='mt-2 mb-4'
                  >
                    <Dropdown.Toggle variant="input" disabled={errors.computer} className='w-100 justify-content-between'>
                      {values.throatStickerPrinter.id !== '' ? values.throatStickerPrinter.name : 'Select printer'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='w-100 bg-white shadow-sm'>
                      {printers.filter(p => p.computer.id === parseInt(values.computer.id)).map(p => (
                        <Dropdown.Item key={p.id} eventKey={p.id}>{p.name} <span className={`${p.state === 'online' ? 'text-success' : 'text-danger'}`}>&#x2022; {p.state}</span></Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
            {isValid && <Button
              disabled={isSubmitting}
              type='submit'
              variant='primary'
              className='mb-4'
            >Save settings</Button>}
          </Form>
        )
        }
      </Formik>
    </Container>
  )
}

export default PrinterSettingsForm
