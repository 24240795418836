import { capitalize } from 'lodash'
import { useFormikContext } from 'formik'
import { Col, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import PlayerCard from './PlayerCard'
import NewPlayerForm from './NewPlayerForm'
import { useContext } from 'react'
import { OrderFormContext } from '../../../contexts/OrderFormContext'
import { Customer } from '../../../classes/Customer'
import UserSearch from '../../common/UserSearch'
import { ROLE } from '../../../helpers'

const PlayerSelection = () => {
  const { edit } = useContext(OrderFormContext)
  const { values, errors, touched, setFieldValue, setFieldTouched, validateField } = useFormikContext()

  const saveCustomer = async (customer) => {
    await setFieldValue('customer', { new: values.customer.new, ...customer })
    validateField('customer')
    setFieldTouched('customer')
  }

  const removeCustomer = async () => {
    setFieldValue('customer', { new: values.customer.new })
  }

  return (
    <Row className='mb-5'>
      <Col xs={12} className='d-flex justify-content-between mb-4'>
        <Col xs='auto'>
          <h3>Customer</h3>
        </Col>
        <Col xs='auto' className='bg-white rounded align-self-center' style={{ padding: '0.15rem' }}>
          <ToggleButtonGroup
            name='new'
            type='radio'
            value={values.customer.new ? 'new' : 'existing'}
            onChange={value => setFieldValue('customer', new Customer(value === 'new'), true)}
          >
            {['existing', 'new'].map(choice => (
              <ToggleButton
                key={choice}
                variant="ghost"
                className='rounded'
                id={choice}
                value={choice}
                disabled={edit}
              >{capitalize(choice)}</ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Col>
      </Col>

        {values.customer._id
          ? <PlayerCard removeCustomer={removeCustomer} removable={!edit} />
          : (values.customer.new ? <NewPlayerForm /> : <UserSearch roles={[ROLE.Player]} onUserSelected={saveCustomer} isInvalid={errors.customer && touched.customer} limit={25} />)
        }
    </Row>
  )
}

export default PlayerSelection
