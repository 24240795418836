import { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { SharedDataContext } from '../contexts/SharedDataContext'
import useDataService from '../hooks/useDataService'
import { Formik } from 'formik'
import { object, string } from 'yup'
import withTournamentSelectionRequired from '../components/generic/withTournamentSelectionRequired'

const PrinterSettings = () => {
  const { setBanner, tournament } = useContext(SharedDataContext)
  const [printerSettings, setPrinterSettings] = useState()
  const { apiGetTournament, apiPutPrinterSettings } = useDataService()

  const parsePrinterSettings = data => {
    if (data.printerSettings) {
      return {
        ...data.printerSettings,
        computer: {
          id: data.printerSettings?.racquetTagPrinter?.computer?.id ?? data.printerSettings?.throatStickerPrinter?.computer?.id,
          name: data.printerSettings?.racquetTagPrinter?.computer?.name ?? data.printerSettings?.throatStickerPrinter?.computer?.name
        }
      }
    } else {
      return emptyPrinterSettings
    }
  }

  useEffect(() => {
    apiGetTournament(tournament._id).then(res => {
      setPrinterSettings(parsePrinterSettings(res))
    })
  }, [])

  const handleSave = async (data, bag) => {
    bag.setSubmitting(true)
    const res = await apiPutPrinterSettings(tournament._id, data)
    setPrinterSettings(parsePrinterSettings(res))
    setBanner('Saved successfully')
    bag.setSubmitting(false)
  }

  const emptyPrinterSettings = {
    computer: {
      id: '',
      name: ''
    },
    racquetTagPrinter: {
      id: '',
      name: ''
    },
    throatStickerPrinter: {
      id: '',
      name: ''
    }
  }

  return (
    <Container>
      <h2 className='mb-2'>Printer settings</h2>
      <h5 className='text-muted mb-3'>Pick a computer for your tournament venue,<br/>then proceed to select label printers.</h5>
      <hr/>
      {printerSettings && <Formik
        initialValues={printerSettings}
        initialErrors={printerSettings.computer.id === '' ? { computer: 1 } : {} }
        validationSchema={object({
          computer: object({
            id: string().required()
          }),
          racquetTagPrinter: object({
            id: string().required()
          }),
          throatStickerPrinter: object({
            id: string().optional()
          })
        })}
        onSubmit={handleSave}
      >
        {/* <PrinterSettingsForm/> */}
      </Formik>}
    </Container>
  )
}

export default withTournamentSelectionRequired(PrinterSettings)
