import { Button, Container } from 'react-bootstrap'
import useAuth from '../hooks/useAuth'
import { Form, Formik } from 'formik'
import { object, string } from 'yup'
import { useContext } from 'react'
import { SharedDataContext } from '../contexts/SharedDataContext'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'
import PasswordField from '../components/signup/PasswordField'
import useDataService from '../hooks/useDataService'

const ChangePassword = () => {
  const { loginWithEmailAndPassword } = useAuth()
  const { setBanner, user } = useContext(SharedDataContext)
  const { apiUpdatePassword } = useDataService()
  const navigate = useNavigate()

  const validationSchema = object({
    password: string().required('Password is required'),
    newPassword: string().required('Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/, { message: 'Password must be at least 6 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.' })
  })

  const handlePasswordChange = async (values, bag) => {
    bag.setSubmitting(true)
    const success = await loginWithEmailAndPassword(user.email, values.password)
    if (success) {
      const res = await apiUpdatePassword(values.newPassword)
      if (res.ok) {
        await loginWithEmailAndPassword(user.email, values.newPassword)
        setBanner('Password changed successfully.')
        navigate(ROUTES.MyProfile)
      }
    }
    bag.setSubmitting(false)
  }

  return (
    <Container fluid className='mx-md-0 mw-750'>
      <h2 className='mb-3'>Change your password</h2>
      <Formik
        initialValues={{
          password: '',
          newPassword: ''
        }}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={handlePasswordChange}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <PasswordField
              controlId='password'
              placeholder='Current password'
              value={values.password}
              isInvalid={!!errors.password && touched.password}
              error={errors.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <PasswordField
              controlId='newPassword'
              placeholder='New password'
              value={values.newPassword}
              isInvalid={!!errors.newPassword && touched.newPassword}
              handleBlur={handleBlur}
              handleChange={handleChange}
              error={errors.newPassword}
            />
            <Button
              className="w-100"
              type="Submit"
              disabled={isSubmitting}
            >
              {isSubmitting
                ? 'Loading'
                : 'Confirm'
              }
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default ChangePassword
