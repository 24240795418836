import { Button, Col, Container, Row } from 'react-bootstrap'
import LogInOptions from '../components/login/LogInOptions'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'
import { useEffect } from 'react'
import useAuth from '../hooks/useAuth'
import LogInForm from '../components/login/LogInForm'

const Home = () => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()

  useEffect(() => {
    if (currentUser) {
      navigate(ROUTES.Dashboard)
    }
  }, [currentUser])

  const handleLoginWithEmailAndPassword = () => {
    navigate(ROUTES.Dashboard)
  }

  return (
    <Container id="home" className='d-flex flex-column justify-content-around'>
      <div className='text-center mt-5'><img src="/images/logo.svg" style={{ width: '15rem' }}/></div>
      <Container className='mb-5'>
        <LogInForm onLogIn={handleLoginWithEmailAndPassword}/>
        <Row className='align-items-center mt-3 mb-5'>
          <Col xs={5}><hr/></Col>
          <Col xs={2} className='text-muted text-center'>OR</Col>
          <Col xs={5}><hr/></Col>
        </Row>
        <Button className='mb-5 w-100' variant='info' onClick={() => navigate(ROUTES.SignUp)}>Sign Up</Button>
        <LogInOptions/>
        <Row>
          <Button variant='ghost' className='mt-5' onClick={() => navigate(ROUTES.ForgotPassword)}>Forgot password?</Button>
        </Row>
      </Container>
    </Container>
  )
}

export default Home
