import { Button, Col, Form, Row } from 'react-bootstrap'
import DateRangeFields from './DateRangeFields'
import { useFormikContext } from 'formik'
import UserSearch from '../common/UserSearch'
import { ROLE } from '../../helpers'
import PlayerCard from '../order/player-selection/PlayerCard'
import EventOrDatePicker from './EventOrDatePicker'
import TournamentDropdown from '../common/TournamentDropdown'

const AdminInvoiceForm = () => {
  const { values, errors, touched, setFieldValue, handleChange, handleSubmit } = useFormikContext()

  return (
    <Form onSubmit={handleSubmit} noValidate className='mx-0 px-2 gy-1 mb-3'>
      <h5>Select a customer</h5>
      <Row className='mb-2'>
        { values.customer?._id
          ? <Col><PlayerCard removable removeCustomer={() => setFieldValue('customer', {})}/></Col>
          : <UserSearch roles={[ROLE.Player]} onUserSelected={u => setFieldValue('customer', u)} isInvalid={errors.customer?._id && touched.customer} />
        }
      </Row>
      <Form.Group controlId='customer.invoiceRecipient' className='mb-2'>
        <h5>Send email to</h5>
        <Form.Control
          type='text'
          name='customer.invoiceRecipient'
          value={values.customer?.invoiceRecipient ?? ''}
          onChange={handleChange}
          isInvalid={!!errors.customer?.invoiceRecipient && touched.customer?._id}
        />
        <Form.Control.Feedback type='invalid'>{errors.customer?.invoiceRecipient}</Form.Control.Feedback>
        {values.customer?.email &&
          <Button
            type='button'
            variant='link'
            className='px-1'
            size='sm'
            onClick={() => setFieldValue('customer.invoiceRecipient', values.customer.email)}
          >Use customer's email</Button>
        }
      </Form.Group>
      <h5>Select a reporting period</h5>
      <EventOrDatePicker />
      { values.range === 'byEvent'
        ? <TournamentDropdown value={values.event} handleSelect={(t) => setFieldValue('event', t)} isInvalid={touched.event && errors.event} />
        : <DateRangeFields />
      }
    </Form>
  )
}

export default AdminInvoiceForm
