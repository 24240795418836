import { useFormikContext } from 'formik'
import { Button, Form, Col, Row } from 'react-bootstrap'
import Icon from '../../../icons/Icon'
import { useState } from 'react'
import { bool, func, string } from 'prop-types'

const propTypes = {
  onApply: func.isRequired,
  onRemove: func,
  className: string,
  title: bool
}

const PromoCodeField = ({ onApply, onRemove, title, className }) => {
  const { handleChange, values } = useFormikContext()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const handleApply = async (values) => {
    try {
      setLoading(true)
      setError(false)
      await onApply(values)
    } catch (e) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  if (!values.promoCode?.discount) {
    return (
      <Row className={`align-items-start ${className}`}>
        {title && (
          <Col xs={12} md={5}>
            <h5>Promo code</h5>
          </Col>
        )}
        <Col xs={8} md={title ? 5 : 8}>
          <Form.Control
            type='text'
            id='promoCode.code'
            placeholder='Promo code'
            value={values.promoCode?.code}
            isInvalid={error}
            onChange={handleChange}
          />
          <Form.Control.Feedback type='invalid' className={error ? 'd-block' : ''}>Invalid promo code.</Form.Control.Feedback>
        </Col>
        <Col xs={4} md={title ? 2 : 4} className='text-end'>
          <Button
            variant='light'
            type='button'
            className='p-1 w-100'
            disabled={loading || values.promoCode?.code.length === 0}
            onClick={() => handleApply(values.promoCode)}
          >Apply</Button>
        </Col>
      </Row>
    )
  } else {
    return (
      <Row className={`align-items-start mb-3 ${className}`}>
        <Col xs={12} md={5}>
          <h5>Promo code</h5>
        </Col>
        <Col xs={12} md={7}>
          <div className='d-flex justify-content-between bg-white rounded p-1 border border-success'>
            <div>
              <span className='text-success'><Icon icon='success' size={18} /></span>
              <span className='text-success mx-1'>{values.promoCode?.code} applied</span>
            </div>
            <div><Button variant='info' className='close' onClick={onRemove}><Icon icon='close' size={16} /></Button></div>
          </div>
        </Col>
      </Row>
    )
  }
}

PromoCodeField.propTypes = propTypes

export default PromoCodeField
