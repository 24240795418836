import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useState } from 'react'
import { Order } from '../classes/Order'
import useDataService from '../hooks/useDataService'
import { useLocation, useParams } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { DateTime } from 'luxon'
import { SharedDataContext } from './SharedDataContext'

const propTypes = {
  children: PropTypes.element,
  edit: PropTypes.bool
}

const OrderFormContext = createContext()

const OrderFormProvider = ({ children, edit = false }) => {
  const location = useLocation()
  const { currentUser } = useAuth()
  const params = new URLSearchParams(location.search)
  const reloadLast = params.get('reloadLast')
  const { tournament } = useContext(SharedDataContext)
  const { id } = useParams()
  const { apiGetRacquets, apiGetPreviousOrder, apiGetOrder } = useDataService()
  const [racquets, setRacquets] = useState([])
  const [draft, setDraft] = useState(new Order(tournament))
  const [savedDraft, setSavedDraft] = useState()
  const [step, setStep] = useState(0)

  useEffect(() => {
    apiGetRacquets().then(setRacquets)
    if (edit) {
      apiGetOrder(id).then(order => setDraft({
        ...order,
        customer: {
          new: false,
          ...order.customer
        },
        tournament: order.tournament._id,
        dueDate: DateTime.fromISO(order.dueDate)
      }))
    } else if (currentUser.role === 'player' && reloadLast) {
      apiGetPreviousOrder({ tournament: tournament._id }).then(order => {
        setDraft({
          ...draft,
          racquets: order.racquets
        })
      })
    }
  }, [])

  const next = () => {
    setStep(Math.min(step + 1, 2))
  }

  const previous = () => {
    setStep(Math.max(step - 1, 0))
  }

  const refreshRacquets = () => {
    apiGetRacquets().then(setRacquets)
  }

  const value = {
    edit,
    step,
    next,
    previous,
    draft,
    setDraft,
    savedDraft,
    setSavedDraft,
    racquets,
    refreshRacquets
  }

  return (
    <OrderFormContext.Provider value={value}>
      { draft && children }
    </OrderFormContext.Provider>
  )
}

OrderFormProvider.propTypes = propTypes

export {
  OrderFormContext,
  OrderFormProvider
}
