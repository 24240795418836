import { Button, Col, Row } from 'react-bootstrap'
import Icon from '../../icons/Icon'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'

const PasswordResetSuccess = () => {
  const navigate = useNavigate()
  return (
    <Row className="h-100 flex-column align-items-center justify-content-center">
      <Col xs={12} className='d-flex flex-column align-items-center justify-content-center text-center flex-grow-1'>
        <Icon icon='success' size={120} className='mb-5'/>
        <h5>Check your email!</h5>
        <p>We've sent you an email with a link to reset your password. Follow the instructions in the email to access your account.</p>
      </Col>
      <Col xs={12}>
        <Button
          type='button'
          variant='primary'
          className='w-100 mb-5'
          onClick={() => navigate(ROUTES.Home)}
        >Done</Button>
      </Col>
    </Row>
  )
}

export default PasswordResetSuccess
