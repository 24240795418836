import { Container } from 'react-bootstrap'
import SignupManager from '../components/signup/SignupManager'
import { useContext, useEffect } from 'react'
import { SharedDataContext } from '../contexts/SharedDataContext'
import { useNavigate, useSearchParams } from 'react-router-dom'
import useDataService from '../hooks/useDataService'
import { ROUTES } from '../routes'

const CompleteProfile = () => {
  const { user, setUser } = useContext(SharedDataContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { apiUpdateUser } = useDataService()

  useEffect(() => {
    if (user.firstName?.length > 0 && user.lastName?.length > 0) {
      navigate(ROUTES.Dashboard)
    }
  }, [user])

  const initialValues = {
    firstName: searchParams.get('fName') ?? '',
    lastName: searchParams.get('lName') ?? ''
  }

  const handleCompletedProfile = async (data) => {
    const u = await apiUpdateUser(user._id, data)
    if (u) {
      setUser(u)
      navigate(ROUTES.Dashboard)
    }
    return false
  }

  return (
    <Container className='mw-750'>
      <SignupManager completeProfileMode initialValues={initialValues} onCompleted={handleCompletedProfile} />
    </Container>
  )
}

export default CompleteProfile
