import { useContext, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import PropTypes from 'prop-types'

const AlertHeading = ({ children }) => {
  return (
    <Alert.Heading className='h5'>
      {children}
    </Alert.Heading>
  )
}

AlertHeading.propTypes = {
  children: PropTypes.any
}

const propTypes = {
  children: PropTypes.any,
  variant: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  dismissible: PropTypes.bool
}

const CustomAlert = ({ children, variant, type, dismissible }) => {
  const [show, setShow] = useState(true)
  const { setError } = useContext(SharedDataContext)

  const handleClose = () => {
    setError()
    setShow(false)
  }

  return (
    <Alert
      variant={variant}
      show={show}
      dismissible={dismissible}
      className={`mt-5 mb2 alert-${type}`}
      onClose={handleClose}
    >
      {children}
    </Alert>
  )
}

CustomAlert.propTypes = propTypes

export {
  CustomAlert,
  AlertHeading
}
