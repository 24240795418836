import { GoogleAuthProvider, signInWithPopup, getAdditionalUserInfo, signInWithEmailAndPassword, OAuthProvider, FacebookAuthProvider, confirmPasswordReset, applyActionCode, fetchSignInMethodsForEmail, reload } from 'firebase/auth'
import { useEffect, createContext, useState, useContext } from 'react'
import { auth } from '../services/firebase-service'
import PropTypes from 'prop-types'
import { SharedDataContext } from './SharedDataContext'
import { AppError } from '../classes/AppError'

const propTypes = {
  children: PropTypes.element
}

const AuthContext = createContext()

function AuthProvider ({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [additionalUserInfo, setAdditionalUserInfo] = useState()
  const [idToken, setIdToken] = useState()
  const [loading, setLoading] = useState(true)
  const { setError, setTournament } = useContext(SharedDataContext)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setLoading(true)
      if (user) {
        user.getIdToken().then(console.log)
        user.getIdToken().then(setIdToken)
        user.getIdTokenResult().then(result => {
          user.role = result.claims.role
          setCurrentUser(user)
        })
        setError()
      } else {
        setIdToken()
        setCurrentUser()
        setAdditionalUserInfo()
      }
      setLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  const parseFirebaseError = (error) => {
    switch (error.code) {
      case 'auth/user-not-found':
        return new AppError("We couldn't find this user", 'Check if the email is correct or contact support')
      case 'auth/wrong-password':
        return new AppError('Invalid password', 'Check if the password is correct or reset your password')
      case 'auth/email-already-in-use':
        return new AppError('This email is used by another account', 'Sign up with a different email or reset your password')
    }
  }

  const loginWithEmailAndPassword = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      const user = getAdditionalUserInfo(userCredential)
      setAdditionalUserInfo(user)
      return true
    } catch (error) {
      setError(parseFirebaseError(error))
      return false
    }
  }

  const loginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider())
      const user = getAdditionalUserInfo(result)
      setAdditionalUserInfo(user)
    } catch (error) {
      console.log(error)
      throw new Error('Login error')
    }
  }

  const loginWithFacebook = async () => {
    try {
      const result = await signInWithPopup(auth, new FacebookAuthProvider())
      const user = getAdditionalUserInfo(result)
      setAdditionalUserInfo(user)
    } catch (error) {
      console.log(error)
      throw new Error('Login error')
    }
  }

  const loginWithApple = async () => {
    try {
      const result = await signInWithPopup(auth, new OAuthProvider('apple.com'))
      const user = getAdditionalUserInfo(result)
      setAdditionalUserInfo(user)
    } catch (error) {
      console.log(error)
      throw new Error('Login error')
    }
  }

  const refreshToken = async () => {
    const token = await currentUser.getIdToken(true)
    const result = await currentUser.getIdTokenResult()
    const updatedUser = currentUser
    updatedUser.role = result.claims.role
    setIdToken(token)
    setCurrentUser(updatedUser)
    return token
  }

  const logout = () => {
    setTournament()
    return auth.signOut()
  }

  const resetPassword = async (actionCode, newPassword) => {
    await confirmPasswordReset(auth, actionCode, newPassword)
  }

  const verifyEmail = async (actionCode) => {
    await applyActionCode(auth, actionCode)
  }

  const getSignInMethods = async (email) => {
    const list = await fetchSignInMethodsForEmail(auth, email)
    return list
  }

  const reloadUser = async () => {
    await reload(currentUser)
  }

  const value = {
    currentUser,
    additionalUserInfo,
    idToken,
    loginWithEmailAndPassword,
    loginWithGoogle,
    loginWithFacebook,
    loginWithApple,
    refreshToken,
    logout,
    resetPassword,
    verifyEmail,
    getSignInMethods,
    reloadUser
  }

  return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
  )
}

AuthProvider.propTypes = propTypes

export {
  AuthContext,
  AuthProvider
}
