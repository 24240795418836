import { useFormikContext } from 'formik'
import { Fragment } from 'react'
import { Form } from 'react-bootstrap'

const EventOrDatePicker = () => {
  const { values, initialValues, resetForm } = useFormikContext()
  return (
    <Fragment>
      <Form.Check
        type='radio'
        className='my-1'
        id='byEvent'
        name='range'
        label='A single event (tournament)'
        value='byEvent'
        onChange={() => resetForm({
          values: {
            customer: values.customer,
            range: 'byEvent',
            startDate: initialValues.startDate,
            endDate: initialValues.endDate
          }
        })}
        checked={values.range === 'byEvent'}
      />
      <Form.Check
        type='radio'
        className='my-1'
        id='byDate'
        name='range'
        label='Start date and end date'
        value='byDate'
        onChange={() => resetForm({
          values: {
            customer: values.customer,
            range: 'byDate',
            startDate: initialValues.startDate,
            endDate: initialValues.endDate
          }
        })}
        checked={values.range === 'byDate'}
      />
    </Fragment>
  )
}

export default EventOrDatePicker
