import { Container } from 'react-bootstrap'
import { useState } from 'react'
import PasswordResetRequest from '../components/password-reset/PasswordResetRequest'
import PasswordResetSuccess from '../components/password-reset/PasswordResetSuccess'

const ForgotPassword = () => {
  const [stepNumber, setStepNumber] = useState(0)

  const next = () => {
    setStepNumber(Math.min(stepNumber + 1, 1))
  }

  return (
  <Container className='h-100'>
    {stepNumber === 0 && <PasswordResetRequest next={next} />}
    {stepNumber === 1 && <PasswordResetSuccess /> }
  </Container>

  )
}

export default ForgotPassword
