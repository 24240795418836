import { useState } from 'react'
import EmailStep from './EmailStep'
import PasswordStep from './PasswordStep'
import PlayerIdStep from './PlayerIdStep'
import NameStep from './NameStep'
import { Button, Col, Row } from 'react-bootstrap'
import Icon from '../../icons/Icon'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import ProgressBar from '../common/ProgressBar'
import { bool, func, object } from 'prop-types'

const propTypes = {
  initialValues: object,
  onCompleted: func.isRequired,
  completeProfileMode: bool
}

const SignupManager = ({ initialValues, onCompleted, completeProfileMode }) => {
  const MAX_STEPS = completeProfileMode ? 3 : 4
  const initData = {
    firstName: initialValues?.firstName ?? '',
    lastName: initialValues?.lastName ?? '',
    playerId: '',
    email: '',
    password: ''
  }
  const { loginWithEmailAndPassword } = useAuth()
  const navigate = useNavigate()
  const [step, setStep] = useState(0)
  const [data, setData] = useState(initData)

  const next = () => {
    setStep(Math.min(step + 1, MAX_STEPS))
  }

  const previous = () => {
    if (step === 0) {
      navigate(ROUTES.Home)
    } else {
      setStep(Math.max(step - 1, 0))
    }
  }

  const hadnleFormCompletion = async (values) => {
    const updatedData = { ...data, ...values }
    setData(updatedData)
    const res = await onCompleted(updatedData)
    if (res) {
      setStep(4)
    }
  }

  const onSignupCompleted = async () => {
    await loginWithEmailAndPassword(data.email, data.password)
  }

  const handleContinue = values => {
    setData({ ...data, ...values })
    next()
  }

  return (
    <Row>
      <Col xs={12}>
        <ProgressBar size={MAX_STEPS} current={step} startAt={completeProfileMode ? 1 : 0}/>
      </Col>
      <Col xs={12}>
        {step === 0 && <NameStep onContinue={handleContinue} onBack={previous} values={{ firstName: data.firstName, lastName: data.lastName }} />}
        {step === 1 && <PlayerIdStep onContinue={completeProfileMode ? hadnleFormCompletion : handleContinue} onBack={previous} values={{ playerId: data.playerId }} />}
        {step === 2 && <EmailStep onContinue={handleContinue} onBack={previous} values={{ email: data.email }} />}
        {step === 3 && <PasswordStep onContinue={hadnleFormCompletion} onBack={previous} values={{ password: data.password }} />}
        {step === 4 && (
          <Row className="h-100 align-items-center justify-content-center mb-3">
            <Col xs={12} className='d-flex flex-column align-items-center justify-content-center text-center flex-grow-1'>
              <Icon icon='success' size={120} className='mb-5' />
              <h5>Welcome!</h5>
              <p>We've sent you an email with a link to verify your email address. Follow the instructions in the email to complete registration.</p>
              <Button type='button' onClick={onSignupCompleted}>Done</Button>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  )
}

SignupManager.propTypes = propTypes

export default SignupManager
