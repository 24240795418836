import { Alert } from 'react-bootstrap'
import Icon from '../../icons/Icon'

const OrdersPlaceholder = () => {
  return (
    <Alert variant='light'>
      <Alert.Heading className='h5'>
        <Icon icon='loader'/>
        <span>No orders currently</span>
      </Alert.Heading>
      <p className='text-muted'>Start fresh or load your previous re-string.</p>
    </Alert>
  )
}

export default OrdersPlaceholder
