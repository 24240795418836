import useAuth from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'
import { useEffect } from 'react'
import SignupManager from '../components/signup/SignupManager'
import { Container } from 'react-bootstrap'
import useDataService from '../hooks/useDataService'

const SignUp = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const { apiCreateUser } = useDataService()

  useEffect(() => {
    if (currentUser) {
      navigate(ROUTES.Dashboard)
    }
  }, [currentUser])

  const handleSignUp = async (data) => {
    const res = await apiCreateUser(data)
    return !!res?.userId
  }

  return (
    <Container className='mw-750'>
      <SignupManager onCompleted={handleSignUp}/>
    </Container>
  )
}

export default SignUp
