import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useState } from 'react'
import BackButton from '../common/BackButton'
import { useFormikContext } from 'formik'
import Icon from '../../icons/Icon'
import StatusFilter from './StatusFilter'
import StringerFilter from './StringerFilter'
import PaymentStatusFilter from './PaymentStatusFilter'
import useAuth from '../../hooks/useAuth'
import { ROLE } from '../../helpers'

const propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

const Filters = ({ show, close }) => {
  const { currentUser } = useAuth()
  const formik = useFormikContext()
  const [showStatusFilter, setShowStatusFilter] = useState(false)
  const [showStringerFilter, setShowStringerFilter] = useState(false)
  const [showPaymentFilter, setShowPaymentFilter] = useState(false)

  const handleClear = () => {
    const values = formik.values
    values.filters = {
      tournament: values.filters.tournament,
      status: [],
      stringer: [],
      orderDate: '',
      paymentStatus: []
    }
    formik.resetForm(values)
  }

  return (
    <Offcanvas show={show} onHide={close} placement='end'>
      <StatusFilter show={showStatusFilter} close={() => setShowStatusFilter(false)}/>
      <StringerFilter show={showStringerFilter} close={() => setShowStringerFilter(false)}/>
      <PaymentStatusFilter show={showPaymentFilter} close={() => setShowPaymentFilter(false)}/>
      <Offcanvas.Header>
        <BackButton onClick={close} />
        <Offcanvas.Title className='flex-grow-1 text-center'>
          Filters
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form noValidate onSubmit={formik.handleSubmit} className='ms-5 me-5'>
          <Row className='justify-content-between pt-5 pb-5 border-bottom' onClick={() => setShowStatusFilter(true)}>
            <Col xs='auto'>
              <span>Status</span>
              {formik.values.filters.status.length > 0 && <span className='filter-counter ms-5'>{formik.values.filters.status.length}</span>}
            </Col>
            <Col xs='auto'>
              <Icon icon='forward' size={18}/>
            </Col>
          </Row>
          { currentUser.role !== ROLE.Stringer && (
            <Row className='justify-content-between pt-5 pb-5 border-bottom' onClick={() => setShowStringerFilter(true)}>
              <Col xs='auto'>
                <span>Stringr</span>
                {formik.values.filters.stringer.length > 0 && <span className='filter-counter ms-5'>{formik.values.filters.stringer.length}</span>}
              </Col>
              <Col xs='auto'>
                <Icon icon='forward' size={18}/>
              </Col>
            </Row>
          )}
          <Form.Group as={Row} controlId='filters.orderDate' className='justify-content-between pt-5 pb-5 border-bottom'>
            <Form.Label className='w-auto'>Order Date</Form.Label>
            <Form.Control
              className='w-auto'
              type='date'
              name='filters.orderDate'
              value={formik.values.filters.orderDate}
              onChange={formik.handleChange}
            />
          </Form.Group>
          <Row className='justify-content-between pt-5 pb-5 border-bottom' onClick={() => setShowPaymentFilter(true)}>
            <Col xs='auto'>
              <span>Payment</span>
              {formik.values.filters.paymentStatus.length > 0 && <span className='filter-counter ms-5'>{formik.values.filters.paymentStatus.length}</span>}
            </Col>
            <Col xs='auto'>
              <Icon icon='forward' size={18}/>
            </Col>
          </Row>
          <Row className='justify-content-between mt-5'>
            <Col xs='auto' className='flex-grow-1'>
              <Button
                type='button'
                variant='info'
                className='w-100'
                onClick={handleClear}
              >Clear all</Button>
            </Col>
            <Col xs='auto' className='flex-grow-1'>
              <Button
                type='submit'
                variant='primary'
                className='w-100'
                onClick={close}
              >Save</Button>
            </Col>
          </Row>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

Filters.propTypes = propTypes

export default Filters
