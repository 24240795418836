import { useEffect, useState } from 'react'
import useDataService from '../../hooks/useDataService'
import { object } from 'prop-types'
import { ListGroup } from 'react-bootstrap'
import Loader from './Loader'

const propTypes = {
  event: object.isRequired
}

const TopRacquetModels = ({ event }) => {
  const { apiGetMetrics } = useDataService()
  const [data, setData] = useState([])
  const [totals, setTotals] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    Promise.all([
      apiGetMetrics(event._id, 'racquet-model-stats'),
      apiGetMetrics(event._id, 'status-count')
    ]).then(([res1, res2]) => {
      setData(res1)
      setTotals(res2[0])
      setLoading(false)
    })
  }, [event])

  if (loading) {
    return <Loader />
  } else if (data?.length === 0) {
    return (
    <div className='text-center'>
      <h5>Top racquet models</h5>
      No data to display
    </div>
    )
  }

  return (
    <div className='px-3'>
      <h5 className='text-center mb-2'>Top racquet models</h5>
      <div className='d-flex justify-content-between mx-2 text-body fw-bold mb-1'>
        <span>#</span>
        <span className='ms-1 me-auto'>Model name</span>
        <span>%</span>
      </div>
      <ListGroup as='ol' numbered>
          {data.map((r, i) => (
            <ListGroup.Item as='li' key={i} className='d-flex justify-content-between'>
              <span className='ms-1 me-auto'>{r._id}</span>
              <span>{(r.total / totals.total * 100).toFixed()} %</span>
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  )
}

TopRacquetModels.propTypes = propTypes

export default TopRacquetModels
