import _ from 'lodash'
import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

const propTypes = {
  order: PropTypes.object.isRequired
}

const RacquetTag = ({ order }) => {
  return (
    <Document style={{ fontFamily: 'Helvetica', fontSize: 11 }}>
      {order.racquets.map(r => {
        const res = []
        for (let i = 0; i < r.quantity; i++) {
          res.push(
            <Page key={r._id} size={{ width: 288, height: 153 }} style={{ marginVertical: 10, paddingHorizontal: 15 }}>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between', fontFamily: 'Helvetica-Bold', fontSize: 12, paddingBottom: '5px' }}>
                <Text>{order.customer.firstName} {order.customer.lastName}</Text>
                <Text>Due: {DateTime.fromISO(order.dueDate).toFormat('dd MMM h:mm a')}</Text>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                {order.tournament.retailVenue && <Text><Text style={{ fontFamily: 'Helvetica-Bold' }}>Price: </Text>${order.total.toFixed(2)}{order.promoCode && ` (${order.promoCode.code})`}</Text>}
                <Text><Text style={{ fontFamily: 'Helvetica-Bold' }}>Paid: </Text>{order.paid ? 'Y' : 'N'}</Text>
                <Text><Text style={{ fontFamily: 'Helvetica-Bold' }}>Restring: </Text>{order.restringTime ?? '  '}</Text>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <View>
                  <Text style={{ fontFamily: 'Helvetica-Bold' }}>Racquet:</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold' }}>Main:</Text>
                  <Text style={{ fontFamily: 'Helvetica-Bold' }}>Cross:</Text>
                </View>
                <View style={{ paddingLeft: '5mm' }}>
                  <Text>{r.model.fullName} {r.quantity > 1 && `(${i + 1})`}</Text>
                  <Text>
                    {`${(r.mains.strings.own?.title ?? r.mains.strings.purchased?.title ?? r.mains.strings.other?.title ?? '').slice(-28)}`}
                  </Text>
                  <Text>
                    {`${(r.crosses.strings.own?.title ?? r.crosses.strings.purchased?.title ?? r.crosses.strings.other?.title ?? '').slice(-28)}`}
                  </Text>
                </View>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text><Text style={{ fontFamily: 'Helvetica-Bold' }}>Tension: </Text>{r.mains.tension.toFixed(2)}/{r.crosses.tension.toFixed(2)} {r.measure}</Text>
                <Text><Text style={{ fontFamily: 'Helvetica-Bold' }}>Pre-stretch: </Text>{r.mains.preStretch}/{r.crosses.preStretch} %</Text>
              </View>
              <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <Text><Text style={{ fontFamily: 'Helvetica-Bold' }}>Stencil: </Text>{_.capitalize(r.stencil.logo)}{r.stencil.logo !== 'none' && `, ${r.stencil.color[0].toUpperCase()}`}</Text>
                <Text><Text style={{ fontFamily: 'Helvetica-Bold' }}>Knots: </Text>{r.knots}</Text>
                <Text><Text style={{ fontFamily: 'Helvetica-Bold' }}>Overgrip: </Text>{r.overgrip ? 'Y' : 'N'}</Text>
              </View>
              <View>
                <Text><Text style={{ fontFamily: 'Helvetica-Bold' }}>Notes: </Text>{order.oneHrBeforeMatch && '1 hr before match;' }{r.notes.replaceAll(/\n/g, '; ')}</Text>
              </View>
              <View style={{ position: 'absolute', bottom: '7mm', left: 15 }}>
                <Text>Order #{order.id}</Text>
                <Text>{order.stringer ? `${order.stringer.firstName[0]}. ${order.stringer.lastName[0]}.` : 'N/A'}</Text>
              </View>
              <View style={{ position: 'absolute', bottom: '7mm', right: '7mm', flexDirection: 'column', alignItems: 'center' }}>
                <Image src='https://tournaments-stringr-bucket.s3.ap-southeast-2.amazonaws.com/sticker-logos.png' style={{ height: '25px' }}/>
              </View>
            </Page>
          )
        }
        return res
      }).flat()}
    </Document>
  )
}

RacquetTag.propTypes = propTypes

export default RacquetTag
