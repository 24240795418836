import PropTypes from 'prop-types'
import { createContext, useEffect, useState } from 'react'
import useDataService from '../hooks/useDataService'
import { useParams } from 'react-router-dom'

const propTypes = {
  children: PropTypes.element
}

const OrderContext = createContext()

const OrderProvider = ({ children }) => {
  const { id } = useParams()
  const { apiGetOrder } = useDataService()
  const [order, setOrder] = useState()

  useEffect(() => {
    apiGetOrder(id).then(setOrder)
  }, [])

  const value = {
    order,
    setOrder
  }

  return (
    <OrderContext.Provider value={value}>
      { order && children }
    </OrderContext.Provider>
  )
}

OrderProvider.propTypes = propTypes

export {
  OrderContext,
  OrderProvider
}
