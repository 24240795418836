import useAuth from '../hooks/useAuth'
import { OrderProvider } from '../contexts/OrderContext'
import PlayerOrderView from '../components/order-view/PlayerOrderView'
import AdminOrderView from '../components/order-view/AdminOrderView'
import StringerOrderView from '../components/order-view/StringerOrderView'
import withTournamentSelectionRequired from '../components/generic/withTournamentSelectionRequired'
import { ROLE } from '../helpers'

const Order = () => {
  const { currentUser } = useAuth()

  return (
    <OrderProvider>
        { currentUser?.role === ROLE.Player && <PlayerOrderView /> }
        { [ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser?.role) && <AdminOrderView /> }
        { currentUser?.role === ROLE.Stringer && <StringerOrderView /> }
    </OrderProvider>
  )
}

export default withTournamentSelectionRequired(Order)
