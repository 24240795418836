import { Formik } from 'formik'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { Tournament } from '../../classes/Tournament'
import useDataService from '../../hooks/useDataService'
import LocationsDropdown from '../location/LocationsDropdown'
import { array, date, number, object, string } from 'yup'
import Feedback from 'react-bootstrap/esm/Feedback'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import ServicePriceForm from './ServicePriceForm'

const EventForm = () => {
  const { apiCreateTournament } = useDataService()
  const navigate = useNavigate()

  const validationSchema = object({
    name: string().required('Enter the name of the tournament'),
    startDate: date().required('Enter the start date'),
    endDate: date().required('Enter the end date').when('startDate', (startDate, yup) => {
      return startDate && yup.min(startDate)
    }),
    location: object({
      _id: string().required('Select a location')
    }),
    addOns: array().of(object({
      _id: string().required(),
      price: number().min(0, 'Invalid price').required('Enter the price')
    })).required().min(1)
  })

  const createTournament = async (values, bag) => {
    bag.setSubmitting(true)
    console.log(values)
    const t = await apiCreateTournament(values)
    bag.setSubmitting(false)
    navigate(`${ROUTES.Tournaments}/${t._id}`)
  }

  return (
    <Formik
      initialValues={new Tournament()}
      validationSchema={validationSchema}
      onSubmit={createTournament}
    >
      {({
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleSubmit
      }) => (
        <Form onSubmit={handleSubmit}>
          <h5 className='mb-2'>Tournament name</h5>
          <Form.Group controlId='name' className='mb-3'>
            <Form.Control
              type='text'
              name='name'
              value={values.name}
              isInvalid={!!errors?.name && touched.name}
              onChange={handleChange}
            />
            <Feedback type='invalid'>{errors?.name}</Feedback>
          </Form.Group>

          <Row className='mb-3'>
            <Form.Group as={Col} xs={12} md={6} controlId='startDate'>
              <h5 className='mb-2'>Start date</h5>
              <Form.Control
                type='date'
                name='startDate'
                value={values.startDate}
                isInvalid={!!errors?.startDate && touched.startDate}
                onChange={handleChange}
              />
              <Feedback type='invalid'>{errors?.startDate}</Feedback>
            </Form.Group>
            <Form.Group as={Col} xs={12} md={6} controlId='endDate'>
              <h5 className='mb-2'>End date</h5>
              <Form.Control
                type='date'
                name='endDate'
                value={values.endDate}
                isInvalid={!!errors?.endDate && touched.endDate}
                onChange={handleChange}
              />
              <Feedback type='invalid'>{errors?.endDate}</Feedback>
            </Form.Group>
          </Row>

          <h5 className='mb-2'>Location</h5>
          <LocationsDropdown/>

          <h4 className='mb-2'>Service prices</h4>

          <ServicePriceForm fluid/>

          <div className='text-center mt-3'>
            <Button
              type='submit'
              variant='primary'
              disabled={isSubmitting}
            >Submit for review</Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default EventForm
