import { Button, Col, Form, Row } from 'react-bootstrap'
import { useContext, useEffect, useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { ROUTES } from '../../routes'
import { useNavigate } from 'react-router-dom'
import { OrderFormContext } from '../../contexts/OrderFormContext'
import { DateTime } from 'luxon'
import RacquetAccordion from '../order-view/RacquetAccordion'
import useDataService from '../../hooks/useDataService'
import Icon from '../../icons/Icon'
import { Formik } from 'formik'
import { PAYMENT_METHOD } from '../../helpers'
import TotalsRow from '../common/TotalsRow'

const Checkout = () => {
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const { apiConfirmOrder } = useDataService()
  const { savedDraft, previous } = useContext(OrderFormContext)
  const [ready, setReady] = useState(false)
  const [element, setElement] = useState()

  // eslint-disable-next-line no-unused-vars
  const [paymentIntent, setPaymentIntent] = useState()
  const [message, setMessage] = useState()

  useEffect(() => {
    if (stripe) {
      stripe.retrievePaymentIntent(savedDraft.clientSecret).then(setPaymentIntent)
    }
  }, [stripe])

  useEffect(() => {
    if (ready) {
      setElement(elements.getElement('payment'))
    }
  }, [ready])

  // useEffect(() => {
  //   console.log(element)
  // }, [element])

  const handleSubmit = async (values, bag) => {
    bag.setSubmitting(true)
    switch (values.paymentMethod) {
      case 'stripe':
      {
        const validation = await elements.submit()

        if (!stripe || !elements) {
          return
        }

        if (validation.error) {
          return
        }
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: `${process.env.REACT_APP_REDIRECT_URL}/orders/${savedDraft._id}`
          }
        })

        if (error) {
          setMessage(error.message)
        }
        break
      }
      case 'eft':
      case 'prize':
        await apiConfirmOrder(savedDraft._id, values)
        navigate(`${ROUTES.Orders}/${savedDraft._id}`)
        break
    }
    bag.setSubmitting(false)
  }

  const paymentElementOptions = {
    layout: {
      type: 'accordion',
      radios: true,
      defaultCollapsed: true
    }
  }

  return (
    <Formik
      initialValues={{
        paymentMethod: 'stripe'
      }}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Button
            type='button'
            variant='icon-wrapper'
            className='mt-1 mb-2'
            onClick={previous}
          >
            <Icon icon='back' />
          </Button>
          <h2 className='mb-5'>Checkout</h2>
          <Row>
            <Col xs={12} className='border-bottom pb-1'>
              <h5 className='mb-3'>Tournament</h5>
              <p>
                {savedDraft.tournament.name},<br />
                {savedDraft.tournament.location.address}
              </p>
            </Col>
            <Col xs={12} className='border-bottom pb-1 pt-2'>
              <h5 className='mb-3'>Customer</h5>
              <p>{savedDraft.customer.firstName} {savedDraft.customer.lastName}</p>
              <p>{savedDraft.customer.email}</p>
              <p>{savedDraft.customer.phone}</p>
            </Col>
            <Col xs={12} className='border-bottom pb-1 pt-2'>
              <h5 className='mb-3'>Pickup date & time</h5>
              <p>{DateTime.fromISO(savedDraft.dueDate).toLocaleString(DateTime.DATETIME_SHORT)}</p>
            </Col>
            <Col xs={12} className='border-bottom pb-1 pt-2'>
              <h5>Payment method</h5>
              <Form.Check
                type='radio'
                className='my-1'
                id={`paymentMethod-${PAYMENT_METHOD.Online.code}`}
                name='paymentMethod'
                label={PAYMENT_METHOD.Online.name}
                value={PAYMENT_METHOD.Online.code}
                onChange={e => {
                  if (e.target.value === 'stripe') {
                    element.mount('#payment-element')
                  } else {
                    element.unmount()
                  }
                  formik.setFieldValue('paymentMethod', e.target.value)
                }}
                checked={formik.values.paymentMethod === PAYMENT_METHOD.Online.code}
              />
              <PaymentElement
                id='payment-element'
                onReady={() => setReady(true)}
                options={paymentElementOptions}
              />
              <Form.Check
                type='radio'
                className='my-1'
                id={`paymentMethod-${PAYMENT_METHOD.InStore.code}`}
                name='paymentMethod'
                label={PAYMENT_METHOD.InStore.name}
                value={PAYMENT_METHOD.InStore.code}
                onChange={e => {
                  if (e.target.value === 'stripe') {
                    element.mount('#payment-element')
                  } else {
                    element.unmount()
                  }
                  formik.setFieldValue('paymentMethod', e.target.value)
                }}
                checked={formik.values.paymentMethod === PAYMENT_METHOD.InStore.code}
              />
              <Form.Check
                type='radio'
                className='my-1'
                id={`paymentMethod-${PAYMENT_METHOD.PrizeMoney.code}`}
                name='paymentMethod'
                label={PAYMENT_METHOD.PrizeMoney.name}
                value={PAYMENT_METHOD.PrizeMoney.code}
                onChange={e => {
                  if (e.target.value === 'stripe') {
                    element.mount('#payment-element')
                  } else {
                    element.unmount()
                  }
                  formik.setFieldValue('paymentMethod', e.target.value)
                }}
                checked={formik.values.paymentMethod === PAYMENT_METHOD.PrizeMoney.code}
              />
            </Col>
          </Row>

          <Row className='mt-3'>
            <h3 className='mb-3'>Racquets summary</h3>
            <RacquetAccordion racquets={savedDraft.racquets} />
          </Row>

          <TotalsRow racquets={savedDraft.racquets} promoCode={savedDraft.promoCode} showPromoCode />

          <Row className='justify-content-center mb-4'>
            {message && <div id='payment-message' className='text-danger'>{message}</div>}
            <Col xs={12} md='auto'>
              <Button
                type='submit'
                variant='primary'
                className='w-100'
                disabled={formik.isSubmitting || !stripe || !elements}
              >
                <span id='button-text'>
                  {formik.isSubmitting ? 'Loading...' : 'Confirm'}
                </span>
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default Checkout
