import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import BackButton from '../common/BackButton'
import { useFormikContext } from 'formik'
import { useContext } from 'react'
import { SharedDataContext } from '../../contexts/SharedDataContext'

const propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

const StringerFilter = ({ show, close }) => {
  const formik = useFormikContext()
  const { tournament } = useContext(SharedDataContext)

  const handleClear = () => {
    const values = formik.values
    values.filters.stringer = []
    formik.resetForm(values)
  }

  return (
    <Offcanvas show={show} onHide={close} placement='end'>
      <Offcanvas.Header>
        <BackButton onClick={close} />
        <Offcanvas.Title className='flex-grow-1 text-center'>
          Stringrs
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form noValidate className='ms-5 me-5 mb-5'>
          <Form.Group controlId='filters.stringer'>
            <Form.Check
                type="checkbox"
                name='filters.stringer'
                onChange={(e) => {
                  formik.setFieldValue('filters.stringer', e.target.checked ? ['none'] : [])
                }}
                id='none'
                label='Not Assigned'
                value='none'
                checked={formik.values.filters.stringer.includes('none')}
              />
            {tournament.stringers.map(s => (
              <Form.Check
                key={s._id}
                type="checkbox"
                name='filters.stringer'
                onChange={formik.handleChange}
                id={`${s.index}`}
                label={`${s.firstName} ${s.lastName}`}
                value={s._id}
                disabled={formik.values.filters.stringer.includes('none')}
                checked={formik.values.filters.stringer.includes(s._id)}
              />
            ))}
          </Form.Group>
        </Form>
        <Row className='justify-content-between mt-5'>
          <Col xs='auto' className='flex-grow-1'>
            <Button
              disabled={formik.values.filters.stringer.length === 0}
              type='button'
              variant='info'
              className='w-100'
              onClick={handleClear}
            >Clear all</Button>
          </Col>
          <Col xs='auto' className='flex-grow-1'>
            <Button
              type='button'
              variant='primary'
              className='w-100'
              onClick={close}
            >Save</Button>
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

StringerFilter.propTypes = propTypes

export default StringerFilter
