import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import useDataService from '../../hooks/useDataService'
import { useState } from 'react'

const propTypes = {
  orderId: PropTypes.string.isRequired,
  friendlyOrderId: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  paymentMethod: PropTypes.string,
  paymentMethodDesc: PropTypes.string,
  show: PropTypes.bool.isRequired,
  update: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
}

const InStorePaymentModal = ({ orderId, friendlyOrderId, total, paymentMethod, paymentMethodDesc, show, update, close }) => {
  const { apiPatchPayment } = useDataService()
  const [submitting, setSubmitting] = useState(false)

  const handlePayment = async (e) => {
    e.stopPropagation()
    setSubmitting(true)
    const data = { paymentMethod }
    const res = await apiPatchPayment(orderId, data)
    update(res)
    close()
  }

  const handleClose = e => {
    e.stopPropagation()
    close()
  }

  return (
    <Modal show={show}>
      <Modal.Header>
        <h5>Confirm <strong>{paymentMethodDesc} payment</strong> for #{friendlyOrderId}?</h5>
      </Modal.Header>
      <Modal.Body>
        <p>Grand total</p>
        <h4>${total.toFixed(2)}</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handlePayment} disabled={submitting}>
          Confirm
        </Button>
        <Button variant="info" onClick={handleClose} disabled={submitting}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

InStorePaymentModal.propTypes = propTypes

export default InStorePaymentModal
