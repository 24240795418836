import { useFormikContext } from 'formik'
import PasswordField from './PasswordField'
import * as yup from 'yup'
import withFormStepWrapper from './withFormStepWrapper'

const validationSchema = yup.object({
  password: yup.string().required('Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/, { message: 'Password must be at least 6 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.' })
})

const PasswordStep = (props) => {
  const formik = useFormikContext()

  return (
    <PasswordField
      controlId='password'
      value={formik.values.password}
      error={formik.errors.password}
      isInvalid={formik.errors.password}
      handleBlur={formik.handleBlur}
      handleChange={formik.handleChange}
      label="Let's set a password"
      autoFocus
    />
  )
}

export default withFormStepWrapper(PasswordStep, validationSchema)
