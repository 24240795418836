import { Col, Container, Row } from 'react-bootstrap'

const Unauthorized = () => {
  return (
    <Container className='pt-5 px-md-5 ms-md-0 mw-750 h-100'>
      <Row className='flex-column h-100 align-items-center justify-content-center'>
        <Col xs='auto' className='my-5 fw-medium' style={{ fontSize: '4rem' }}>
          <p>Uh-oh!</p>
        </Col>
        <Col xs='auto' className='text-center'>
          <h5>Unauthorized</h5>
          <p>Uh-oh! You are not allowed to view this page.</p>
        </Col>
      </Row>
    </Container>
  )
}

export default Unauthorized
