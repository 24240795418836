import PropTypes from 'prop-types'
import { useContext } from 'react'
import { Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'
import useDataService from '../hooks/useDataService'
import { SharedDataContext } from '../contexts/SharedDataContext'
import LogInForm from './login/LogInForm'
import useAuth from '../hooks/useAuth'

const propTypes = {
  actionCode: PropTypes.string.isRequired
}

const EmailChangeConfirmation = ({ actionCode }) => {
  const { loginWithEmailAndPassword } = useAuth()
  const { apiConfirmEmailChange } = useDataService()
  const navigate = useNavigate()
  const { setBanner, setUser } = useContext(SharedDataContext)

  const handleConfirm = async (newPassword) => {
    const res = await apiConfirmEmailChange(actionCode)
    if (res) {
      await loginWithEmailAndPassword(res.email, newPassword)
      setBanner('Email updated successfully!')
      setUser(res)
      navigate(ROUTES.MyProfile)
    }
  }

  return (
    <Container>
      <h2 className='mb-2'>Sign in to confirm email change</h2>
      <p className='mb-3'>Sign in using your <strong>old email address</strong> to confirm the change.</p>
      <LogInForm onLogIn={handleConfirm} buttonText='Confirm' />
    </Container>
  )
}

EmailChangeConfirmation.propTypes = propTypes

export default EmailChangeConfirmation
