import { parseChartsData } from './chart-helpers'
import { object, string } from 'prop-types'
import { DateTime } from 'luxon'
import AnalyticsChart from './AnalyticsChart'

const propTypes = {
  event: object.isRequired,
  stringer: string
}

const DailyCompletedTotalsChart = ({ event, stringer }) => {
  const CHART_ID = 'restrung-restring-time-count'

  const parseData = data => parseChartsData(data.map(v => ({
    ...v,
    _id: DateTime.fromISO(v._id).toJSDate()
  })))

  const options = {
    isStacked: true,
    colors: ['#64B97B', '#A95E79', '#F4A56B', '#818FDA'],
    // hAxis: {
    //   gridlines: {
    //     units: {
    //       days: {
    //         interval: 0
    //       }
    //     }
    //   }
    // },
    legend: {
      position: 'bottom'
    }
  }

  return (
    <AnalyticsChart
      title='Daily Completed Totals'
      chartId={CHART_ID}
      chartType='ColumnChart'
      parseData={parseData}
      options={options}
      formatters={[{
        type: 'DateFormat',
        column: 0,
        options: {
          pattern: 'MMM d',
          timeZone: DateTime.now().offset / 60
        }
      }]}
      event={event}
      filters={{ stringer }}
      calculateTicks
    />
  )
}

DailyCompletedTotalsChart.propTypes = propTypes

export default DailyCompletedTotalsChart
