import { useEffect, useState } from 'react'
import useDataService from '../../hooks/useDataService'
import { object } from 'prop-types'
import { ListGroup } from 'react-bootstrap'
import Loader from './Loader'

const propTypes = {
  event: object.isRequired
}

const PlayerLeaderboard = ({ event }) => {
  const { apiGetMetrics } = useDataService()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    apiGetMetrics(event._id, 'player-leaderboard').then(res => {
      setData(res)
      setLoading(false)
    })
  }, [event])

  if (loading) {
    return <Loader />
  } else if (data?.length === 0) {
    return (
    <div className='text-center'>
      <h5>Player Leaderboard</h5>
      No data to display
    </div>
    )
  }

  return (
    <div className='px-3'>
      <h5 className='text-center mb-2'>Player Leaderboard</h5>
      <div className='d-flex justify-content-between mx-2 text-body fw-bold mb-1 align-items-center'>
        <div className='me-2'>
          <span>#</span>
          <span className='ms-1'>Player</span>
        </div>
        <div className='ms-auto d-flex justify-content-end'>
          <span className='text-end'>Total Racquets</span>
          <span className='ms-2 text-end' style={{ width: '9rem' }}>Average Racquets per Order</span>
          <span className='ms-2 text-end d-none d-md-inline' style={{ width: '9rem' }}>On Court</span>
        </div>
      </div>
      <ListGroup as='ol' numbered>
          {data.map((r, i) => (
            <ListGroup.Item as='li' key={i} className='d-flex justify-content-start'>
              <span className='ms-1'>{r.customer}</span>
              <div className='flex-grow-1 d-flex justify-content-end'>
                <span>{r.totalRacquets}</span>
                <span className='text-end' style={{ width: '10rem' }}>{(r.totalRacquets / r.totalOrders).toFixed(0)}</span>
                <span className='text-end d-none d-md-inline' style={{ width: '10rem' }}>{r.onCourt}</span>
              </div>
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  )
}

PlayerLeaderboard.propTypes = propTypes

export default PlayerLeaderboard
