import { createContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import useDataService from '../hooks/useDataService'
import * as PropTypes from 'prop-types'

const EventContext = createContext()

const propTypes = {
  children: PropTypes.element
}

const EventProvider = ({ children }) => {
  const { id } = useParams()
  const [event, setEvent] = useState()
  const { apiGetTournament } = useDataService()

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    apiGetTournament(id).then(setEvent)
  }

  const value = {
    event,
    setEvent,
    refresh
  }

  return (
    <EventContext.Provider value={value}>
      {event && children}
    </EventContext.Provider>
  )
}

EventProvider.propTypes = propTypes

export {
  EventContext,
  EventProvider
}
