import { useContext } from 'react'
import { Button, Container } from 'react-bootstrap'
import { SharedDataContext } from '../contexts/SharedDataContext'
import UserProfile from '../components/profile/UserProfile'
import LogoutButton from '../components/LogoutButton'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'
import Icon from '../icons/Icon'
import useAuth from '../hooks/useAuth'

const MyProfile = () => {
  const { currentUser } = useAuth()
  const { user, setUser } = useContext(SharedDataContext)
  const navigate = useNavigate()

  return (
    <Container fluid className='mx-md-0 px-md-0 mw-750'>
      {user && <UserProfile user={user} setUser={setUser} /> }
      <Container className='px-5 pt-2'>
        {currentUser.providerData?.some(p => p.providerId === 'password') && <div>
          <Button variant='logout' onClick={() => navigate(ROUTES.ChangePassword)} className='px-1'>
            <Icon icon='lock' size={24} className='d-none d-md-inline d-lg-none' />
            <Icon icon='lock' size={18} className='d-inline d-md-none d-lg-inline' />
            <span>Change password</span>
          </Button>
        </div>
        }
        <div>
          <LogoutButton />
        </div>
      </Container>
    </Container>
  )
}

export default MyProfile
