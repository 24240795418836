import useAuth from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'
import { useContext, useEffect } from 'react'
import PlayerDashboard from '../components/dashboard/PlayerDashboard'
import StringerDashboard from '../components/dashboard/StringerDashboard'
import AdminDashboard from '../components/dashboard/AdminDashboard'
import { SharedDataContext } from '../contexts/SharedDataContext'
import { Container } from 'react-bootstrap'
import withTournamentSelectionRequired from '../components/generic/withTournamentSelectionRequired'
import { ROLE } from '../helpers'

const Dashboard = () => {
  const { currentUser, additionalUserInfo } = useAuth()
  const { user } = useContext(SharedDataContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (user && !(user.firstName && user.lastName) && currentUser?.role === 'player') {
      const firstName = additionalUserInfo?.profile.given_name ?? additionalUserInfo?.profile.first_name ?? ''
      const lastName = additionalUserInfo?.profile.family_name ?? additionalUserInfo?.profile.last_name ?? ''
      navigate(`${ROUTES.CompleteProfile}?fName=${firstName}&lName=${lastName}`)
    }
  }, [user])

  return (
    <Container className='h-100 px-0 mx-0'>
      { user && currentUser?.role === ROLE.Player && <PlayerDashboard user={user}/> }
      { user && currentUser?.role === ROLE.Stringer && <StringerDashboard/> }
      { user && [ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser?.role) && <AdminDashboard /> }
    </Container>
  )
}

export default withTournamentSelectionRequired(Dashboard)
