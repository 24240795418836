import { Row } from 'react-bootstrap'
import SocialLogin from '../SocialLogin'
import useAuth from '../../hooks/useAuth'

const LogInOptions = () => {
  const { loginWithGoogle, loginWithApple, loginWithFacebook } = useAuth()

  const loginHandlers = {
    google: loginWithGoogle,
    apple: loginWithApple,
    facebook: loginWithFacebook
  }

  const handleAlternativeLogin = async (type) => {
    try {
      await loginHandlers[type]()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Row className="d-flex">
      <SocialLogin
        id="google-login"
        type="google"
        className="mb-5 w-100"
        onClick={ () => handleAlternativeLogin('google') }
      />
      <SocialLogin
        id="apple-login"
        type="apple"
        className="mb-5 w-100"
        onClick={ () => handleAlternativeLogin('apple') }
      />
      <SocialLogin
        id="facebook-login"
        type="facebook"
        className='w-100'
        onClick={ () => handleAlternativeLogin('facebook') }
      />

    </Row>

  )
}

export default LogInOptions
