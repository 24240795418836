import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import BackButton from '../common/BackButton'
import { useFormikContext } from 'formik'
import { STATUS } from '../../helpers'

const propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

const StatusFilter = ({ show, close }) => {
  const formik = useFormikContext()

  const handleClear = () => {
    const values = formik.values
    values.filters.status = []
    formik.resetForm(values)
  }

  return (
    <Offcanvas show={show} onHide={close} placement='end'>
      <Offcanvas.Header>
        <BackButton onClick={close} />
        <Offcanvas.Title className='flex-grow-1 text-center'>
          Status
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form noValidate className='ms-5 me-5 mb-5'>
          <Form.Group controlId='filters.status'>
            {Object.values(STATUS).slice(0, 4).map(s => (
              <Form.Check
                key={s.index}
                type="checkbox"
                name='filters.status'
                onChange={formik.handleChange}
                id={`${s.index}`}
                label={s.name}
                value={s.index}
                defaultChecked={formik.values.filters.status.includes(s.index.toString())}
              />

            ))}
          </Form.Group>
          <Form.Group controlId='filters.cancelled'>
              <Form.Check
                type='switch'
                name='filters.cancelled'
                onChange={formik.handleChange}
                label='Show cancelled orders'
                checked={formik.values.filters.cancelled}
              />
          </Form.Group>
        </Form>
        <Row className='justify-content-between mt-5'>
          <Col xs='auto' className='flex-grow-1'>
            <Button
              disabled={formik.values.filters.status.length === 0}
              type='button'
              variant='info'
              className='w-100'
              onClick={handleClear}
            >Clear all</Button>
          </Col>
          <Col xs='auto' className='flex-grow-1'>
            <Button
              type='button'
              variant='primary'
              className='w-100'
              onClick={close}
            >Save</Button>
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

StatusFilter.propTypes = propTypes

export default StatusFilter
