import { Button, Container, Navbar } from 'react-bootstrap'
import Menu from './Menu'
import Icon from '../icons/Icon'
import { useState } from 'react'

const Navigation = () => {
  const [expanded, setExpanded] = useState(false)
  return (
    <Navbar expand={false} expanded={expanded} onSelect={() => setExpanded(false)} fixed='top' className='border-bottom p-0 d-md-none'>
      <Container>
        <Navbar.Brand href="/" className='flex-md-grow-0 flex-grow-1 text-center'>
          <img src='/images/logo.svg' style={{ position: 'absolute', top: '10px', left: '9.5rem', height: '25px' }}/>
        </Navbar.Brand>
        <Button variant='icon-wrapper' onClick={() => setExpanded(!expanded)}>
          <Icon icon={expanded ? 'close' : 'menu'}/>
        </Button>
        <Navbar.Collapse className='flex-grow-0'>
          <Menu />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Navigation
