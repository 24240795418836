import { clamp } from 'lodash'
import { Formik, useFormikContext } from 'formik'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'
import { bool, func, string } from 'prop-types'
import * as yup from 'yup'
import useDataService from '../../hooks/useDataService'

const propTypes = {
  userId: string.isRequired,
  show: bool.isRequired,
  onSuccess: func.isRequired,
  onHide: func.isRequired
}

const AuthorizationCodeModal = ({ userId, show, onSuccess, onHide }) => {
  const { values } = useFormikContext()
  const { apiUpdateUser } = useDataService()

  const handleSubmit = async (data, bag) => {
    bag.setSubmitting(true)
    const user = await apiUpdateUser(userId, {
      code: data.code.join(''),
      ...values
    })
    onSuccess(user)
    bag.setSubmitting(false)
  }

  return (
    <Modal show={show} backdrop='static' onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Verification code</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            code: Array(6).fill('')
          }}
          validationSchema={yup.object({
            code: yup.array().of(yup.string().required().length(1)).length(6)
          })}
          validateOnMount={true}
          onSubmit={handleSubmit}
        >
          <AuthorizationCodeForm />
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

AuthorizationCodeModal.propTypes = propTypes

const AuthorizationCodeForm = () => {
  const { values, isValid, isSubmitting, handleSubmit, setValues } = useFormikContext()
  const [index, setIndex] = useState(0)

  const handleBack = () => {
    setIndex(clamp(index - 1, 0, 5))
  }

  const handleFocus = (i) => {
    setIndex(i)
  }

  const handlePaste = (value) => {
    console.log(value.split('').slice(0, 6))
    setValues({ code: value.split('').slice(0, 6) })
  }

  return (
    <Form noValidate onSubmit={handleSubmit}>
      <p className='mb-3'>Please enter the 6-digit code we've sent to your email:</p>
      <Row className='mb-2'>
        {values.code.map((v, i) => (
          <DigitField
            key={`code-${i}`}
            id={`code[${i}]`}
            value={values.code[i]}
            isFocused={index === i}
            onFocus={() => handleFocus(i)}
            onChange={() => setIndex(clamp(index + 1, 0, 5))}
            onBackspace={handleBack}
            onPaste={handlePaste}
          />
        ))}
      </Row>
      <Button
        type='submit'
        variant='primary'
        className='w-100'
        disabled={!isValid || isSubmitting}
      >
        {isSubmitting
          ? 'Loading...'
          : 'Confirm'
        }
      </Button>
    </Form>
  )
}

const DigitField = ({ id, value, isFocused, onChange, onBackspace, onFocus, onPaste }) => {
  const ref = useRef(null)
  const { handleChange } = useFormikContext()

  useEffect(() => {
    if (ref.current && isFocused) {
      ref.current.focus()
    }
  }, [ref.current, isFocused])

  const handleInput = (evt) => {
    handleChange(evt)
    onChange(evt)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace') {
      e.preventDefault()
      onBackspace()
    }
  }

  const handleFocus = (e) => {
    e.target.setSelectionRange(0, 1)
    onFocus()
  }

  const handlePaste = (e) => {
    onPaste(e.clipboardData.getData('text'))
  }

  return (
    <Form.Group as={Col} xs={2} controlId={id}>
      <Form.Control
        ref={ref}
        className='text-center'
        type='text'
        value={value}
        htmlSize='1'
        maxLength={1}
        onFocus={handleFocus}
        onChange={handleInput}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
      />
    </Form.Group>
  )
}

DigitField.propTypes = {
  id: string.isRequired,
  value: string.isRequired,
  isFocused: bool.isRequired,
  onChange: func.isRequired,
  onBackspace: func.isRequired,
  onFocus: func.isRequired,
  onPaste: func.isRequired
}

export default AuthorizationCodeModal
