import { capitalize } from 'lodash'
import { Badge, Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { ROLE, STATUS, parseStatus } from '../../helpers'
import Icon from '../../icons/Icon'
import { forwardRef, useRef, useState } from 'react'
import useAuth from '../../hooks/useAuth'
import useDataService from '../../hooks/useDataService'
import useRootClose from 'react-overlays/useRootClose'

const propTypes = {
  orderId: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  status: PropTypes.number.isRequired,
  cancelled: PropTypes.bool
}

const StatusControl = ({ orderId, update, status, cancelled }) => {
  const statuses = Object.values(STATUS)
  const { currentUser } = useAuth()
  const [show, setShow] = useState(false)
  const ref = useRef()
  const { apiPatchStatus } = useDataService()
  const currStatus = parseStatus(status, cancelled)

  const handleSelect = async (e, eventKey) => {
    e.stopPropagation()
    const res = await apiPatchStatus(orderId, eventKey)
    update(res)
    setShow(false)
  }

  const handleRootClose = event => {
    event.stopPropagation()
    event.preventDefault()
    setShow(false)
  }

  useRootClose(ref, handleRootClose, { disabled: !show })

  if (currentUser.role === ROLE.Player || cancelled) {
    return <StatusBadge dropdownControl={false} currStatus={currStatus} />
  }

  return (
    <Dropdown show={show} className='h-100'>
      <Dropdown.Toggle as={StatusBadge} onClick={() => setShow(!show)} currStatus={currStatus} dropdownControl />
      <Dropdown.Menu ref={ref} className='bg-white shadow-sm mt-1'>
        {statuses.slice(0, 4).map(s => (
          <Dropdown.Item
            key={`${orderId}-${s.index}`}
            onClick={e => handleSelect(e, s.index)}
            active={status === s.index}
          >{capitalize(s.name)}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

StatusControl.propTypes = propTypes

const statusBadgePropTypes = {
  dropdownControl: PropTypes.bool,
  currStatus: PropTypes.object.isRequired,
  onClick: PropTypes.func
}

const StatusBadge = forwardRef(({ dropdownControl, currStatus, onClick }, ref) => {
  const catchClick = event => {
    event.preventDefault()
    event.stopPropagation()
  }
  const handleClick = (event) => {
    catchClick(event)
    onClick()
  }

  return (
    <Badge
      pill
      bg='dummy'
      className='d-flex align-items-center user-select-none h-100'
      style={{ backgroundColor: currStatus.badgeColor, width: 'fit-content' }}
      onClick={dropdownControl ? handleClick : catchClick}
      role='button'
    >
      <span className='dot' style={{ backgroundColor: currStatus.dotColor }}></span>
      <span className='text-body px-1'>{currStatus.name}</span>
      {dropdownControl && <Icon icon='drop-arrow' size={16} />}
    </Badge>
  )
})

StatusBadge.displayName = 'StatusBadge'
StatusBadge.propTypes = statusBadgePropTypes

export default StatusControl
