import { useFormikContext } from 'formik'
import { forwardRef, Fragment, useContext, useState } from 'react'
import { Col, Dropdown, Form, Row } from 'react-bootstrap'
import { OrderFormContext } from '../../contexts/OrderFormContext'
import Feedback from 'react-bootstrap/esm/Feedback'
import * as PropTypes from 'prop-types'
import { find, uniq } from 'lodash'
import { RacquetModel } from '../../classes/RacquetModel'

const RacquetSelection = () => {
  const { values, setFieldValue, errors, touched } = useFormikContext()
  const { racquets } = useContext(OrderFormContext)
  const [search, setSearch] = useState('')

  const handleSelect = async (eventKey) => {
    if (eventKey === 'other') {
      setFieldValue('model', new RacquetModel(true), false)
    } else {
      const selected = find(racquets, r => r._id === eventKey)
      await setFieldValue('model', selected, true)
    }
  }

  return (
    <Fragment>
      <Dropdown
        onSelect={handleSelect}
        className="mb-2"
      >
        <Dropdown.Toggle
          variant='input'
          className={`w-100 justify-content-between ${errors.model?.name && touched.model?.name && !values.model.other ? 'is-invalid' : ''}`}
        >
          {values.model.other ? 'other' : (values.model?.fullName ? values.model?.fullName : 'Select racquet')}
        </Dropdown.Toggle>
        <Feedback type='invalid'>Select racquet</Feedback>
        <Dropdown.Menu as={CustomMenu} search={search} setSearch={setSearch} className='w-100 shadow-lg mt-1 bg-white' style={{ maxHeight: '30vh', overflowY: 'scroll' }}>
          {racquets.filter(r => r.fullName.toLowerCase().includes(search.toLowerCase())).map(r => (
            <Dropdown.Item key={r._id} eventKey={r._id}>{r.fullName}</Dropdown.Item>
          ))}
        <Dropdown.Item eventKey="other"><u>+ Add option</u></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {values.model.other && <NewRacquetForm />}
    </Fragment>
  )
}

const CustomMenu = forwardRef(
  ({ children, search, setSearch, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className='m-1'>
          <Form.Control
            autoFocus
            className="mb-1 border"
            placeholder="Type to filter..."
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
        <ul className="list-unstyled">
          {children}
        </ul>
      </div>
    )
  }
)
CustomMenu.displayName = 'CustomMenu'
CustomMenu.propTypes = {
  children: PropTypes.any,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  style: PropTypes.any,
  className: PropTypes.string,
  'aria-labelledby': PropTypes.string
}

const NewRacquetForm = () => {
  const { racquets } = useContext(OrderFormContext)
  const { values, setFieldValue, handleChange, errors, touched } = useFormikContext()
  return (
    <Row className='mb-3 gy-2'>
      <Col xs={12} lg={3}>
        <Dropdown
          onSelect={eventKey => setFieldValue('model.brand', eventKey, true)}
        >
          <Dropdown.Toggle
            variant='input'
            className={`w-100 justify-content-between ${errors.model?.brand && touched.model?.brand ? 'is-invalid' : ''}`}
          >
            {values.model?.brand ? values.model?.brand : 'Select brand'}
          </Dropdown.Toggle>
          <Feedback type='invalid'>Select brand</Feedback>
          <Dropdown.Menu className='w-100 shadow-lg mt-1 bg-white' style={{ maxHeight: '25vh', overflowY: 'scroll' }}>
            {uniq(racquets.map(r => r.brand)).map(b => (
              <Dropdown.Item key={b} eventKey={b}>{b}</Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={12} lg={9}>
        <Form.Group id='model.name'>
          <Form.Control
            type='text'
            name='model.name'
            placeholder='Model name'
            value={values.model.name}
            onChange={handleChange}
            isInvalid={!!errors.model?.name && touched.model?.name}
          />
          <Form.Control.Feedback type='invalid'>{errors.model?.name}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  )
}

export default RacquetSelection
