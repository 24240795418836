import { useContext } from 'react'
import { Alert, Container } from 'react-bootstrap'
import { OrderContext } from '../../contexts/OrderContext'
import { DateTime } from 'luxon'
import ProgressTimeline from './ProgressTimeline'
import { AlertHeading, CustomAlert } from '../generic/CustomAlert'
import BackButton from '../common/BackButton'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import RacquetAccordion from './RacquetAccordion'
import TotalsRow from '../common/TotalsRow'
import LocationInfo from '../location/LocationInfo'

const PlayerOrderView = () => {
  const navigate = useNavigate()
  const { order } = useContext(OrderContext)
  return (
    <Container>
      <BackButton onClick={() => navigate(ROUTES.Dashboard)} text='All orders' />
      <h2>#{order?.id}</h2>
      <p className='text-body'>{order.tournament.name}</p>
      <p className='text-small'>{DateTime.fromISO(order.orderDate).toLocaleString(DateTime.DATETIME_SHORT)}</p>
      { order.cancelled
        ? <Alert variant='danger' className='mt-3 shadow-none'>
          <Alert.Heading className='h5 text-danger'>&#128683; Order cancelled</Alert.Heading>
          <p className='text-danger'>
            Please consider creating a new order.
          </p>
        </Alert>
        : <ProgressTimeline />
      }

      { order.status !== 5 && (
        <>
          <CustomAlert variant='light'>
            <AlertHeading>
              <span>&#128076; Your order is confirmed</span>
            </AlertHeading>
            <p className='text-muted'>Provide the administrator with your order number and drop off the racquets at the pre-selected address.</p>
          </CustomAlert>
          <h4 className='mb-3'>Your racquets will be ready on</h4>
          <CustomAlert variant='light'>
            <h5 className='mb-0'>&#128337; {DateTime.fromISO(order.dueDate).toFormat("MMMM dd 'at' h:mm a")}</h5>
          </CustomAlert>
          {order.status === 1 && (
            <Alert variant='danger' className='my-4'>
              <p>Drop off your racquets <strong>today</strong> so we can restring them on time.</p>
            </Alert>
          )}
          <LocationInfo location={order.tournament.location} />
        </>
      )}
      <h4 className='mt-5 mb-5'>Racquets</h4>
      <RacquetAccordion racquets={order.racquets} />
      <TotalsRow racquets={order.racquets} promoCode={order.promoCode} />
      {/* <Button
        type='button'
        variant='info'
        className='w-100'
      >
        <Icon icon='phone'/>
        <span>Contact us</span>
      </Button> */}
    </Container>
  )
}

export default PlayerOrderView
