import { useContext, useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Col, Row, Table } from 'react-bootstrap'
import TournamentDropdown from '../common/TournamentDropdown'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import useDataService from '../../hooks/useDataService'
import { DateTime } from 'luxon'

const UnpaidOrdersReport = () => {
  const { tournament } = useContext(SharedDataContext)
  const { apiGetReport } = useDataService()
  const [selectedTournament, setSelectedTournament] = useState(tournament)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    setLoading(true)
    if (selectedTournament) {
      apiGetReport(selectedTournament._id, 'unpaid').then(res => {
        setData(res)
        setLoading(false)
      })
    }
  }, [selectedTournament])

  const prepareData = (data) => {
    return data.map(r => ({
      'Order ID': r.id,
      'Customer name': r.customer.fullName,
      'Player ID': r.customer.playerId,
      Email: r.customer.email,
      Phone: r.customer.phone,
      'Order date': DateTime.fromISO(r.orderDate).toLocaleString(DateTime.DATE_SHORT),
      Racquets: r.racquetCount,
      'Payment method': r.paymentMethod,
      Paid: r.paid,
      Total: r.orderTotal
    }))
  }

  return (
    <>
      <Row className='mx-0 px-1 pb-3 mb-3 border-bottom justify-content-between align-items-end'>
        <Col xs='auto'>
          <h3>Unpaid orders report</h3>
        </Col>
        <Col xs='auto'>
          <CSVLink filename='unpaid-orders.csv' className={`btn btn-primary ${loading ? 'disabled' : ''}`} data={prepareData(data)}>Get csv</CSVLink>
        </Col>
      </Row>
      <Row className='mx-0 px-1 gy-1 mb-3 w-md-50'>
        <Col xs={12}>
          <TournamentDropdown value={selectedTournament} handleSelect={(t) => setSelectedTournament(t)} />
        </Col>
        <Col xs={12}>
          <p>The data below is a preview from {selectedTournament.name}.<br/>Please click "Get CSV" to view the entire data.</p>
        </Col>
      </Row>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th className='border-left-0'>Order ID</th>
            <th>Customer</th>
            <th>Player ID</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Order date</th>
            <th>Racquets</th>
            <th>Payment method</th>
            <th>Paid</th>
            <th className='border-right-0'>Total</th>
          </tr>
        </thead>
        <tbody>
          {data.slice(0, 10).map(r => (
            <tr key={r._id}>
              <td className='border-left-0'>{r.id}</td>
              <td>{r.customer.fullName}</td>
              <td>{r.customer.playerId}</td>
              <td>{r.customer.email}</td>
              <td>{r.customer.phone}</td>
              <td>{DateTime.fromISO(r.orderDate).toLocaleString(DateTime.DATE_SHORT)}</td>
              <td>{r.racquetCount}</td>
              <td>{r.paymentMethod}</td>
              <td>{String(r.paid)}</td>
              <td className='border-right-0'>${r.orderTotal}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default UnpaidOrdersReport
