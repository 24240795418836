export class StringAxis {
  tension = ''
  preStretch = 0

  constructor (ownStrings) {
    this.strings = new String(ownStrings)
  }
}

class String {
  ownStrings = true
  name = ''
  id = ''
  other = ''

  constructor (ownStrings) {
    this.ownStrings = ownStrings
  }
}
