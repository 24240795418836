import { useFormikContext } from 'formik'
import { bool } from 'prop-types'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'

const propTypes = {
  fluid: bool
}

const ServicePriceForm = ({ fluid }) => {
  const { values, errors, touched, handleChange } = useFormikContext()
  return (
    <>
      {values.addOns.map((a, i) => (
        <Form.Group as={Row} key={a._id} controlId={`addOns[${i}].price`} className='mb-2 align-items-center'>
          <Col xs={6}>
            <h5>{values.addOns[i].title}</h5>
          </Col>
          <Col xs={6} md={fluid ? 6 : 4}>
            <InputGroup hasValidation={true} className='w-100'>
              <InputGroup.Text>$</InputGroup.Text>
              <Form.Control
                type='number'
                name={`addOns[${i}].price`}
                value={a.price}
                isInvalid={!!errors?.addOns?.[i]?.price && touched.addOns?.[i]?.price}
                onChange={handleChange}
              />
              <Form.Control.Feedback type='invalid'>{errors?.addOns?.[i]?.price}</Form.Control.Feedback>
            </InputGroup>
          </Col>
        </Form.Group>

      ))}
    </>
  )
}

ServicePriceForm.propTypes = propTypes

export default ServicePriceForm
