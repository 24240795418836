import { Form, Formik } from 'formik'
import { func, object } from 'prop-types'
import { Button } from 'react-bootstrap'
import BackButton from '../common/BackButton'

const withFormStepWrapper = (WrappedComponent, validationSchema) => {
  const FormStep = (props) => {
    const { values, onContinue, onBack } = props

    const handleSubmit = async (data, bag) => {
      bag.setSubmitting(true)
      await onContinue(data)
      bag.setSubmitting(false)
    }
    return (
      <div>
        <div className='mb-3'>
          <BackButton onClick={onBack} text='Back' />
        </div>
        <Formik
          initialValues={values}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={handleSubmit}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit} noValidate id='signup-form' className='mx-3'>
              <WrappedComponent {...props} />
              <Button
                type='submit'
                disabled={!formik.isValid || formik.isSubmitting}
                className='w-100'
              >Continue</Button>
            </Form>
          )}
        </Formik >
      </div>
    )
  }

  FormStep.propTypes = {
    onContinue: func.isRequired,
    onBack: func.isRequired,
    values: object.isRequired
  }

  return FormStep
}

export default withFormStepWrapper
