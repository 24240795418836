import { Form } from 'react-bootstrap'
import { useState } from 'react'
import Icon from '../../icons/Icon'
import PropTypes from 'prop-types'

const propTypes = {
  controlId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  label: PropTypes.string,
  autoFocus: PropTypes.bool
}

const PasswordField = ({ controlId, value, placeholder, isInvalid, error, handleChange, handleBlur, label, autoFocus }) => {
  const [show, setShow] = useState(false)
  const toggleShow = () => setShow(!show)
  return (
    <Form.Group controlId={controlId} className="mb-5">
      { label && <Form.Label className='h2 mb-3'>{label}</Form.Label>}
      <Form.Control
        className='password-field'
        type={show ? 'text' : 'password'}
        placeholder={placeholder ?? 'Enter password'}
        value={value}
        onChange={handleChange}
        onBeforeInput={handleBlur}
        isInvalid={isInvalid}
        autoFocus={autoFocus}
      />
      <span onClick={toggleShow}>
        <Icon icon={show ? 'eye-crossed' : 'eye'} size={16} />
      </span>
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  )
}

PasswordField.propTypes = propTypes

export default PasswordField
