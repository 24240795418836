import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Pagination } from 'react-bootstrap'

const propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired
}

const ListPagination = ({ page, setPage, totalPages }) => {
  const MAX_VISIBLE_COUNT = 4

  const range = (lo, hi) => Array.from({ length: hi - lo }, (_, i) => i + lo)

  const pagination = (count, ellipsis = undefined) => (page, total) => {
    const start = Math.max(1, Math.min(page - Math.floor((count - 3) / 2), total - count + 2))
    const end = Math.min(total, Math.max(page + Math.floor((count - 2) / 2), count - 1))
    return [
      ...(start > 2 ? [1, ellipsis] : start > 1 ? [1] : []),
      ...range(start, end + 1),
      ...(end < total - 1 ? [ellipsis, total] : end < total ? [total] : [])
    ]
  }

  const [visiblePages, setVisiblePages] = useState(pagination(MAX_VISIBLE_COUNT)(page, totalPages))

  useEffect(() => {
    setVisiblePages(pagination(MAX_VISIBLE_COUNT)(page, totalPages))
  }, [page, totalPages])

  return (
    <Pagination className='justify-content-center'>
      <Pagination.Prev onClick={() => setPage(page - 1)} disabled={page === 1}/>
      {visiblePages.map((p, i) => {
        if (p) {
          return (
            <Pagination.Item
              key={p}
              active={p === page}
              onClick={() => setPage(p)}
            >{p}</Pagination.Item>
          )
        } else {
          return <Pagination.Ellipsis key={`ellipsis-${i}`} />
        }
      })}
      {/* <Pagination.Item onClick={() => setPage(totalPages)} active={page === totalPages}>{totalPages}</Pagination.Item> */}
      <Pagination.Next onClick={() => setPage(page + 1)} disabled={page === totalPages}/>
    </Pagination>
  )
}

ListPagination.propTypes = propTypes

export default ListPagination
