import { Col, Row } from 'react-bootstrap'
import Icon from '../../icons/Icon'
import * as PropTypes from 'prop-types'

const propTypes = {
  location: PropTypes.object.isRequired
}

const LocationInfo = ({ location }) => {
  return (
    <Col xs='auto'>
      <h5>{location.name}</h5>
      <Row className='my-1 text-muted'>
        <Col xs='auto'>
          <Icon icon='location' size={16} />
        </Col>
        <Col xs='auto'>
          <span>{location.address}</span>
        </Col>
      </Row>
      <Row className='my-1 text-muted'>
        <Col xs='auto'>
          <Icon icon='phone' size={16} />
        </Col>
        <Col xs='auto'>
          <span>{location.phone}</span>
        </Col>
      </Row>
      <Row className='my-1 text-muted'>
        <Col xs='auto'>
          <Icon icon='clock' size={16} />
        </Col>
        <Col xs='auto'>
          <span>{location.hours}</span>
        </Col>
      </Row>
    </Col>
  )
}

LocationInfo.propTypes = propTypes

export default LocationInfo
