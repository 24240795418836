import PropTypes from 'prop-types'

const propTypes = {
  user: PropTypes.object.isRequired,
  size: PropTypes.string,
  onClick: PropTypes.func
}

const UserIcon = ({ user, size, onClick }) => {
  return (
    <span
      className={`user-icon user-icon-${size ?? 'md'}`}
      style={{ backgroundColor: user?.color }}
      role={onClick ? 'button' : ''}
      onClick={onClick}
    >
      {user?.firstName?.[0]}
    </span>
  )
}

UserIcon.propTypes = propTypes

export default UserIcon
