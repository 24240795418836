import { Formik } from 'formik'
import { Container } from 'react-bootstrap'
import { useContext } from 'react'
import { SharedDataContext } from '../contexts/SharedDataContext'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'
import EventList from '../components/events/EventList'
import withCompleteProfileRequired from '../components/generic/withCompleteProfileRequired'

const Events = () => {
  const { tournament, setTournament } = useContext(SharedDataContext)
  const navigate = useNavigate()

  const handleSubmit = (values) => {
    setTournament(values.tournament)
    navigate(ROUTES.Dashboard)
  }

  return (
    <Container className='px-md-5 ms-md-0 mw-750 h-100'>
      <Formik
        initialValues={{
          tournament: tournament ?? { _id: '' }
        }}
        onSubmit={handleSubmit}
      >
        <EventList />
      </Formik>
    </Container>
  )
}

export default withCompleteProfileRequired(Events)
