import { Document, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { DateTime } from 'luxon'
import { array } from 'prop-types'

const propTypes = {
  data: array.isRequired
}

const PDFInvoice = ({ data }) => {
  const TAX_RATE = 0.1

  const getSubtotal = total => total / (1 + TAX_RATE)

  const getGST = total => total / (1 + TAX_RATE) * 0.1

  const styles = StyleSheet.create({
    page: {
      paddingHorizontal: '2cm',
      paddingTop: '1.5cm',
      paddingBottom: '3.5cm',
      fontSize: '11'
    },
    header: {
      flexDirection: 'row',
      maxHeight: '15%',
      fontSize: 16
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      height: '3cm',
      fontSize: 10
    },
    row: {
      flexDirection: 'row'
    },
    tableHeaderRow: {
      paddingBottom: '3px',
      borderBottom: '1px solid #000000'
    },
    tableRow: {
      paddingTop: '5px',
      paddingBottom: '10px'
    },
    tableFooterRow: {
      paddingTop: '3px',
      borderTop: '1px solid #000000'
    },
    dateCol: {
      width: '13%'
    },
    orderIdCol: {
      width: '10%'
    },
    eventCol: {
      width: '28%',
      paddingRight: '5px'
    },
    itemCol: {
      width: '13%'
    },
    qtyCol: {
      width: '5%'
    },
    subtotalCol: {
      width: '11%'
    },
    taxCol: {
      width: '7%'
    },
    totalCol: {
      width: '8%'
    },
    currencyCol: {
      width: '5%'
    },
    bold: {
      fontFamily: 'Helvetica-Bold'
    },
    grandTotalTitleCol: {
      width: '85%'
    },
    grandTotalNumberCol: {
      width: '10%'
    }
  })

  if (!data || data.length === 0) {
    return null
  }

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={styles.header} fixed>
          <View style={{ flexGrow: 1 }}>
            <Text style={[{ margin: 'auto' }, styles.bold]}>TAX INVOICE</Text>
          </View>
          <View style={{ width: '30%' }}>
            <Image src='https://tournaments-stringr-bucket.s3.ap-southeast-2.amazonaws.com/invoice-ta-logo.png' />
          </View>
        </View>
        <View style={[styles.row, { justifyContent: 'space-between' }]}>
          <View>
            <Text style={{ marginBottom: '10px' }}>Generated on {DateTime.now().toLocaleString(DateTime.DATETIME_SHORT)}</Text>
            <Text style={[styles.bold, { marginBottom: '5px' }]}>BILL TO</Text>
            <Text>{data[0].customer.fullName}</Text>
          </View>
          <View style={{ flexDirection: 'column', alignItems: 'flex-end' }}>
            <Text>Tennis Australia</Text>
            <Text>ABN: 61 006 281 125</Text>
            <Text>Olympic Boulevard, Melbourne</Text>
            <Text>Victoria Australia 3121</Text>
          </View>
        </View>
        <View style={{ paddingTop: '5vh' }}>
          <View style={[styles.row, styles.tableHeaderRow, styles.bold]} fixed>
            <Text style={styles.dateCol}>Order Date</Text>
            <Text style={styles.orderIdCol}>Order ID</Text>
            <Text style={styles.eventCol}>Event</Text>
            <Text style={styles.itemCol}>Item</Text>
            <Text style={styles.qtyCol}>Qty</Text>
            <Text style={styles.subtotalCol}>Subtotal</Text>
            <Text style={styles.taxCol}>GST</Text>
            <Text style={styles.totalCol}>Total</Text>
            <Text style={styles.currencyCol}>CCY</Text>
          </View>
          {data.map(o => (
            <View style={[styles.row, styles.tableRow]} wrap={false} key={o.orderId}>
              <Text style={styles.dateCol}>{o.orderDate}</Text>
              <Text style={styles.orderIdCol}>{o.orderId}</Text>
              <Text style={styles.eventCol}>{o.tournament}</Text>
              <Text style={styles.itemCol}>Restringing</Text>
              <Text style={styles.qtyCol}>{o.racquets}</Text>
              <Text style={styles.subtotalCol}>{getSubtotal(o.total).toFixed(2)}</Text>
              <Text style={styles.taxCol}>{getGST(o.total).toFixed(2)}</Text>
              <Text style={styles.totalCol}>{o.total.toFixed(2)}</Text>
              <Text style={styles.currencyCol}>AUD</Text>
            </View>
          ))}
          <View style={[styles.row, styles.tableFooterRow, styles.bold]}>
            <Text style={styles.grandTotalTitleCol}>Subtotal</Text>
            <Text style={styles.grandTotalNumberCol}>{data.reduce((prev, curr) => prev + getSubtotal(curr.total), 0).toFixed(2)}</Text>
            <Text style={styles.currencyCol}>AUD</Text>
          </View>
          <View style={[styles.row, styles.bold]}>
            <Text style={styles.grandTotalTitleCol}>Total GST</Text>
            <Text style={styles.grandTotalNumberCol}>{data.reduce((prev, curr) => prev + getGST(curr.total), 0).toFixed(2)}</Text>
            <Text style={styles.currencyCol}>AUD</Text>
          </View>
          <View style={[styles.row, styles.bold]}>
            <Text style={styles.grandTotalTitleCol}>Grand Total</Text>
            <Text style={styles.grandTotalNumberCol}>{data.reduce((prev, curr) => prev + curr.total, 0).toFixed(2)}</Text>
            <Text style={styles.currencyCol}>AUD</Text>
          </View>
        </View>
        <View>
          <Text style={{ marginHorizontal: 'auto', marginTop: '20px' }}>- END OF DOCUMENT -</Text>
        </View>
        <View style={styles.footer} fixed>
          <View style={[styles.row, { width: '100vw', justifyContent: 'center', alignItems: 'center' }]}>
            <Text>Powered by </Text>
            <Image
              src='https://tournaments-stringr-bucket.s3.ap-southeast-2.amazonaws.com/stringr-logo.jpeg'
              style={{ height: '20px', width: '78px' }}
            />
          </View>
          <Text style={{ marginHorizontal: 'auto' }}>
            <Link src='https://tournaments.stringr.pro'>https://tournaments.stringr.pro</Link>
          </Text>
          <Text render={({ pageNumber, totalPages }) => (
            `Page ${pageNumber} of ${totalPages}`
          )} style={{ marginLeft: 'auto', paddingRight: '2cm' }} />
        </View>
      </Page>
    </Document>
  )
}

PDFInvoice.propTypes = propTypes

export default PDFInvoice
