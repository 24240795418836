import { useFormikContext } from 'formik'
import { Button, Col, Offcanvas, Row } from 'react-bootstrap'
import UserIcon from '../../common/UserIcon'
import Icon from '../../../icons/Icon'
import { bool, func } from 'prop-types'
import { useState } from 'react'
import UserProfile from '../../profile/UserProfile'

const PlayerCard = ({ removeCustomer, removable }) => {
  const { values, setFieldValue } = useFormikContext()
  const [showProfile, setShowProfile] = useState(false)

  return (
    <Col xs={12} md={6} className='rounded p-2 bg-white'>
      <Offcanvas show={showProfile} onHide={() => setShowProfile(false)} placement='end'>
        <Offcanvas.Header closeButton className='justify-content-end' />
        <Offcanvas.Body>
          <UserProfile user={values.customer} setUser={(user) => setFieldValue('customer', { new: false, ...user })} />
        </Offcanvas.Body>
      </Offcanvas>
      <Row className='justify-content-between'>
        <Col xs={10} className='d-flex'>
          <Row className='justify-content-end w-100'>
            <Col xs={2} className='text-center'>
              <UserIcon user={values.customer} />
            </Col>
            <Col xs={10}>
              <p className='text-body'>{values.customer.firstName} {values.customer.lastName}</p>
              <p>{values.customer.playerId && 'ID:'} {values.customer.playerId}</p>
              <p>{values.customer.email}</p>
              <p>{values.customer.phone}</p>
            </Col>
          </Row>
        </Col>
        <Col xs={2} className='text-center'>
          {removable && (
            <Button variant='info' className='close' onClick={removeCustomer}><Icon icon='close' size={16} /></Button>
          )}
        </Col>
      </Row>
      <Row className='mt-2'>
        <Button
          variant='info'
          onClick={() => setShowProfile(true)}
        >
            <Icon icon='person' size={16} />
            <span>View and edit profile</span>
        </Button>
      </Row>
    </Col>
  )
}

PlayerCard.propTypes = {
  removeCustomer: func.isRequired,
  removable: bool,
  allowOnboarding: bool
}

export default PlayerCard
