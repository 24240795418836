import { useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import useRootClose from 'react-overlays/useRootClose'
import useDataService from '../../hooks/useDataService'
import { func, number, string } from 'prop-types'
import { ROLE, STATUS } from '../../helpers'
import useAuth from '../../hooks/useAuth'

const propTypes = {
  orderId: string.isRequired,
  status: number.isRequired,
  restringTime: string,
  update: func.isRequired
}

const RestringTimeCell = ({ orderId, status, restringTime, update }) => {
  const ref = useRef()
  const { currentUser } = useAuth()
  const { apiPatchRestringTime } = useDataService()
  const [show, setShow] = useState(false)

  const handleRootClose = event => {
    event.stopPropagation()
    event.preventDefault()
    setShow(false)
  }

  useRootClose(ref, handleRootClose, { disabled: !show })

  const handleSelect = async (e, eventKey) => {
    e.stopPropagation()
    await apiPatchRestringTime(orderId, eventKey)
    update()
    setShow(false)
  }

  if (![ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role) || status > STATUS.Open.index) {
    return restringTime ?? 'N/A'
  } else {
    return (
        <Dropdown show={show}>
          <Dropdown.Toggle
            variant="link"
            className='px-0'
            onClick={e => {
              e.stopPropagation()
              setShow(!show)
            }}
          >{restringTime ?? 'N/A'}</Dropdown.Toggle>
          <Dropdown.Menu ref={ref} className='w-100 bg-white shadow-sm'>
          {['AM', 'PM', 'On court'].map((e) => (
            <Dropdown.Item key={e} eventKey={e} active={e === restringTime} onClick={(evt) => handleSelect(evt, e)}>{e}</Dropdown.Item>
          ))}
          </Dropdown.Menu>
        </Dropdown>
    )
  }
}

RestringTimeCell.propTypes = propTypes

export default RestringTimeCell
