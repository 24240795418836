import { Button, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'

const ErrorFallback = () => {
  const navigate = useNavigate()
  return (
    <Container fluid style={{ height: '100vh' }}>
      <Row className='flex-column h-100 justify-content-center'>
        <Col xs={12} className='text-center'>
          <div className='mb-4'>
            <h2 className='text-muted mb-2'>Oops...</h2>
            <p className='fs-5'>Something went wrong. Don't worry, we have received the error report.</p>
          </div>
          <Button
            variant='primary'
            type='button'
            onClick={() => navigate(ROUTES.Dashboard)}
          >Go to dahboard</Button>
        </Col>
      </Row>
    </Container>
  )
}

export default ErrorFallback
