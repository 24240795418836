import { Formik } from 'formik'
import { func, object, string } from 'prop-types'
import { Button, Col, Form, Row } from 'react-bootstrap'
import useDataService from '../../hooks/useDataService'
import { useContext, useState } from 'react'
import AuthorizationCodeModal from '../common/AuthorizationCodeModal'
import { SharedDataContext } from '../../contexts/SharedDataContext'

const propTypes = {
  userId: string.isRequired,
  name: string.isRequired,
  placeholder: string,
  initialValue: string,
  validationSchema: object.isRequired,
  update: func.isRequired
}

const ProfileField = ({ userId, name, initialValue, placeholder, validationSchema, update }) => {
  const { user } = useContext(SharedDataContext)
  const { apiGetUpdateAuthorization, apiUpdateUser } = useDataService()
  const [showModal, setShowModal] = useState(false)

  const handleSave = async (values, bag) => {
    bag.setSubmitting(true)

    if (user._id === userId || (name === 'email' && initialValue === undefined)) {
      const res = await apiUpdateUser(userId, values)
      if (res) {
        update(res)
      }
    } else {
      const res = await apiGetUpdateAuthorization(userId, values)
      if (res.ok) {
        setShowModal(true)
      }
    }
  }

  const handleUpdateSuccess = (user) => {
    if (user) {
      update(user)
    }
    setShowModal(false)
  }

  return (
    <Formik
      initialValues={{ [name]: initialValue }}
      validationSchema={validationSchema}
      onSubmit={handleSave}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleSubmit
      }) => (
        <Form noValidate onSubmit={handleSubmit} className='w-100 profile-field-form'>
          <AuthorizationCodeModal
            userId={userId}
            show={showModal}
            onSuccess={handleUpdateSuccess}
            onHide={() => setShowModal(false)}
          />
          <Row>
            <Form.Group as={Col} controlId={name}>
              <Form.Control
                type='text'
                inputMode='numeric'
                pattern='[0-9]'
                value={values[name]}
                placeholder={placeholder}
                onChange={handleChange}
                isInvalid={!!errors[name] && touched[name]}
                autoFocus
                />
              <Form.Control.Feedback type='invalid'>{errors[name]}</Form.Control.Feedback>
              { name === 'email' && user._id === userId && (
                <Form.Text>The update will come into effect after confirmation via email.</Form.Text>
              )}
            </Form.Group>
            <Col xs={12} md='auto' className='mt-1 mt-md-0'>
              <Button
                className='w-100'
                type='submit'
                variant='primary'
                disabled={isSubmitting}
              >Save</Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

ProfileField.propTypes = propTypes

export default ProfileField
