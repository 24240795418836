import { find } from 'lodash'
import { useContext, useState } from 'react'
import { OrderContext } from '../../contexts/OrderContext'
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap'
import Icon from '../../icons/Icon'
import useAuth from '../../hooks/useAuth'
import InStorePaymentModal from './InStorePaymentModal'
import { PAYMENT_METHOD, ROLE } from '../../helpers'
import { Formik } from 'formik'
import { string } from 'prop-types'
import PromoCodeFieldWrapper from '../order/promo-code/PromoCodeFieldWrapper'

const PaymentStatus = () => {
  const { order, setOrder } = useContext(OrderContext)
  const { currentUser } = useAuth()
  const [modalData, setModalData] = useState({ show: false })

  const handleSubmit = (values, bag) => {
    bag.setSubmitting(true)
    setModalData({
      show: true,
      paymentMethod: values.paymentMethod.code,
      paymentMethodDesc: values.paymentMethod.name.toLowerCase(),
      close: () => { bag.setSubmitting(false); setModalData({ show: false }) }
    })
  }

  return (
    <div className='mt-5 mb-5'>
      <InStorePaymentModal
        close={() => setModalData({ show: false })}
        {...modalData}
        orderId={order._id}
        friendlyOrderId={order.id}
        total={order.total}
        update={setOrder}
      />
      <Row className='align-items-end justify-content-between mb-2'>
        <Col xs='auto'>
          <h4>Payment</h4>
        </Col>
        <Col xs='auto' className={`d-flex align-items-center ${order.paid ? 'text-success' : 'text-body'}`}>
          <Icon icon={order.paid ? 'success' : 'loader'} size={16} />
          <span className='text-small ps-1'>{order.paid ? 'Paid' : 'Unpaid'}</span>
        </Col>
      </Row>
      <Row className='justify-content-between w-md-50'>
        {!order.paid && (
          <Col xs={12}>
            <Formik
              initialValues={{
                promoCode: {
                  discount: order.promoCode?.discount ?? 0,
                  code: order.promoCode?.code ?? ''
                }
              }}
              enableReinitialize={true}
            >
              <PromoCodeFieldWrapper />
            </Formik>
          </Col>
        )}
        <Col xs={12} className='text-muted d-flex align-items-center'>
          {order.paid || ![ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role) || order.cancelled
            ? <PaymentMethodSelection paymentMethod={order.paymentMethod} />
            : (
              <Formik
                initialValues={{ paymentMethod: find(Object.values(PAYMENT_METHOD), pm => pm.code === order.paymentMethod) }}
                onSubmit={handleSubmit}
              >
                {formik => (
                  <Form noValidate className='w-100' onSubmit={formik.handleSubmit}>
                    <Dropdown className='w-100' onSelect={eventKey => formik.setFieldValue('paymentMethod', Object.values(PAYMENT_METHOD)[eventKey])}>
                      <Dropdown.Toggle variant='input' className='w-100 justify-content-between'>
                        <PaymentMethodSelection paymentMethod={formik.values.paymentMethod.code} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='w-100 bg-white shadow-sm'>
                        {Object.values(PAYMENT_METHOD).slice(1).map((pm, i) => (
                          <Dropdown.Item key={pm.code} eventKey={i + 1}>
                            <Icon icon={pm.icon} size={16} />
                            <span className='ms-1'>{pm.name}</span>
                          </Dropdown.Item>

                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button
                      type='submit'
                      disabled={formik.isSubmitting}
                      variant='primary'
                      className='w-100 mt-2'
                    >Confirm payment</Button>
                  </Form>
                )}
              </Formik>
              )
          }
        </Col>
      </Row>
    </div>
  )
}

export default PaymentStatus

const PaymentMethodSelection = ({ paymentMethod }) => {
  const currValue = find(Object.values(PAYMENT_METHOD), pm => pm.code === paymentMethod)

  return (
    <span>
      <Icon icon={currValue.icon} size={16} />
      <span className='ms-1'>{currValue.name}</span>
    </span>
  )
}

PaymentMethodSelection.propTypes = {
  paymentMethod: string.isRequired
}
