import { Container } from 'react-bootstrap'
import { Navigate, useSearchParams } from 'react-router-dom'
import { ROUTES } from '../routes'
import PasswordReset from '../components/password-reset/PasswordReset'
import EmailConfirmation from '../components/EmailConfirmation'
import { useEffect, useState } from 'react'
import useDataService from '../hooks/useDataService'
import InvalidEmailActionLink from '../components/password-reset/InvalidEmailAcionLink'
import EmailChangeConfirmation from '../components/EmailChangeConfirmation'

const EmailActionHanlder = () => {
  const [searchParams] = useSearchParams()
  const [errorMessage, setErrorMessage] = useState()

  const mode = searchParams.get('mode')
  const actionCode = searchParams.get('code') ?? searchParams.get('oobCode')

  const { apiVerifyEmailActionCode } = useDataService()

  useEffect(() => {
    if (actionCode && ['password-change', 'onboarding', 'email-change'].includes(mode)) {
      apiVerifyEmailActionCode(actionCode).then(res => {
        if (!res?.ok) {
          setErrorMessage(res.errorMessage)
        }
      })
    }
  }, [])

  if (!(mode && actionCode)) {
    return <Navigate to={ROUTES.Home}/>
  }

  return (
    <Container className='h-100 mx-md-0 px-md-0 mw-750'>
      { errorMessage
        ? <InvalidEmailActionLink message={errorMessage} />
        : <>
          { mode === 'password-change' && <PasswordReset actionCode={actionCode}/> }
          { mode === 'onboarding' && <PasswordReset actionCode={actionCode}/> }
          { mode === 'verifyEmail' && <EmailConfirmation actionCode={actionCode}/> }
          { mode === 'email-change' && <EmailChangeConfirmation actionCode={actionCode} /> }
        </>
      }
    </Container>
  )
}

export default EmailActionHanlder
