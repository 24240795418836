import { DateTime } from 'luxon'

export class Tournament {
  name = ''
  startDate = DateTime.now().set({ second: 0, millisecond: 0 }).toISODate()
  endDate = DateTime.now().plus({ days: 7 }).set({ second: 0, millisecond: 0 }).toISODate()
  location = {}
  addOns = [
    {
      _id: 'RESTR_LABOUR',
      title: 'Labour cost',
      price: 30
    },
    {
      _id: 'OVERGRIP',
      title: 'Overgrip',
      price: 5
    }
  ]
}
