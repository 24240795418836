import { useFormikContext } from 'formik'
import { Col, Form, Row } from 'react-bootstrap'

const DateRangeFields = () => {
  const { values, errors, touched, handleChange } = useFormikContext()
  return (
    <Row>
      <Form.Group as={Col} xs={12} md={6} controlId='startDate' className='mb-2'>
        <h5>Start date</h5>
        <Form.Control
          name='startDate'
          type='date'
          value={values.startDate}
          onChange={handleChange}
          isInvalid={!!errors.startDate && touched.startDate}
        />
        <Form.Control.Feedback type='invalid'>{errors.startDate}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} xs={12} md={6} controlId='endDate' className='mb-2'>
        <h5>End date</h5>
        <Form.Control
          name='endDate'
          type='date'
          value={values.endDate}
          onChange={handleChange}
          isInvalid={!!errors.endDate && touched.endDate}
        />
        <Form.Control.Feedback type='invalid'>{errors.endDate}</Form.Control.Feedback>
      </Form.Group>
    </Row>
  )
}

export default DateRangeFields
