import { snakeCase } from 'lodash'
import { Container } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import UnpaidOrdersReport from '../components/reports/UnpaidOrdersReport'
import RestringsReport from '../components/reports/RestringsReport'
import withTournamentSelectionRequired from '../components/generic/withTournamentSelectionRequired'

const Report = () => {
  const { id } = useParams()
  const chartId = snakeCase(id).toUpperCase()

  return (
    <Container fluid className='mx-md-0 px-md-0'>
      {chartId === 'RESTRINGS_BY_TOURNAMENT' && <RestringsReport />}
      {chartId === 'UNPAID_BY_TOURNAMENT' && <UnpaidOrdersReport/>}
    </Container>
  )
}

export default withTournamentSelectionRequired(Report)
