import { useContext } from 'react'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../routes'

const withTournamentSelectionRequired = (WrappedComponent) => {
  const TournamentSelectionRequired = (props) => {
    const { tournament } = useContext(SharedDataContext)

    if (!tournament) {
      return <Navigate to={ROUTES.SelectTournament} />
    }

    return <WrappedComponent {...props} />
  }
  return TournamentSelectionRequired
}

export default withTournamentSelectionRequired
