import { capitalize } from 'lodash'

export const parseChartsData = (data) => {
  if (data.length > 0) {
    const result = [Object.keys(data[0]).map(k => {
      switch (k) {
        case 'tooltip':
          return { role: 'tooltip' }
        default:
          return capitalize(k)
      }
    }), ...data.map(i => Object.values(i))]
    return result
  } else {
    return []
  }
}
