import PropTypes from 'prop-types'
import { Alert, Button, Col, Container, Row } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import OrderList from '../order-list/OrderList'
import Icon from '../../icons/Icon'
import { useContext } from 'react'
import OrdersPlaceholder from './OrdersPlaceholder'
// import User from '../common/User'
import { Formik } from 'formik'
import { SharedDataContext } from '../../contexts/SharedDataContext'

const propTypes = {
  user: PropTypes.object.isRequired
}

const PlayerDashboard = () => {
  const { features, tournament, user } = useContext(SharedDataContext)
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  return (
    <Container className='px-0'>
      {/* <User user={user} /> */}
      {currentUser?.emailVerified === false &&
        <Row className='mx-1 mb-2'>
          <Col xs={12}>
            <Alert variant='danger'>
              <Alert.Heading className='h5'>
                <Icon icon='mail' />
                <span>Verify your email address</span>
              </Alert.Heading>
              <p className='text-small text-muted'>
                Check your inbox! We sent you a link to verify your email address. Check the spam folder if you can't find the email.
              </p>
            </Alert>
          </Col>
        </Row>
      }
      <Formik
        initialValues={{
          sort: {
            title: 'Order ID (desc)',
            sortField: 'id',
            sortDirection: 'desc'
          },
          filters: {
            status: [],
            cancelled: true,
            stringer: [],
            orderDate: '',
            paymentStatus: []
          },
          search: {
            searchField: 'id',
            searchValue: ''
          }
        }}
      >
        {user?.orders?.length > 0
          ? <OrderList />
          : <OrdersPlaceholder />
        }

      </Formik>
      {features?.createOrder && new Date() < new Date(tournament.closedDate) && <div className='position-sticky bottom-0'>
        <Row className='mx-1 mb-2 py-1'>
          <Col xs={12} md={6}>
            <Button
              type='button'
              variant='primary'
              className='w-100 mb-1'
              onClick={() => navigate(ROUTES.Order)}
            >
              <Icon icon='plus' size={16} />
              <span>Create re-string</span>
            </Button>
          </Col>
          <Col xs={12} md={6}>
            <Button
              type='button'
              disabled={user?.orders?.length === 0}
              variant='info'
              className='w-100'
              onClick={() => navigate(`${ROUTES.Order}?reloadLast=true`)}
            >
              <Icon icon='reload' size={16} />
              <span>Load last re-string</span>
            </Button>
          </Col>
        </Row>
      </div>
      }
    </Container>
  )
}

PlayerDashboard.propTypes = propTypes

export default PlayerDashboard
