import PropTypes from 'prop-types'
import { Button, InputGroup } from 'react-bootstrap'

const propTypes = {
  value: PropTypes.number.isRequired,
  increment: PropTypes.func.isRequired,
  decrement: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  bordered: PropTypes.bool
}

const StepperInput = ({ value, increment, decrement, min, max, bordered }) => {
  return (
    <InputGroup className='w-auto stepper-input'>
      <Button
        type='button'
        variant={bordered ? 'input-bordered' : 'input'}
        onClick={decrement}
        disabled={min ? value <= min : false}
      >-</Button>
      <Button
        type='button'
        variant={bordered ? 'input-bordered' : 'input'}
        className='pe-none'
      >{value}</Button>
      <Button
        type='button'
        variant={bordered ? 'input-bordered' : 'input'}
        onClick={increment}
        disabled={max ? value >= max : false}
      >+</Button>
    </InputGroup>
  )
}

StepperInput.propTypes = propTypes

export default StepperInput
