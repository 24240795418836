import { Container, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'
import Icon from '../icons/Icon'

const Analytics = () => {
  const navigate = useNavigate()
  const dashboards = [
    {
      name: 'Tournament overview',
      desc: 'General tournament metrics',
      path: 'tournament-overview'
    },
    {
      name: 'Stringer Workload',
      desc: 'Stringer metrics per tournament',
      path: 'stringer-workload'
    },
    {
      name: 'Player Insights',
      desc: 'Player metrics per tournament',
      path: 'player-insights'
    },
    {
      name: 'String & Racquet Insights',
      desc: 'Top string and racquet models used',
      path: 'string-racquet-insights'
    }
  ]

  return (
    <Container fluid className='mx-md-0 px-md-0'>
      <Row className='mx-1 mb-2'>
        <h2>Analytics Dashboards</h2>
      </Row>
      <Table bordered striped hover>
        <thead>
          <tr>
            <th className='border-left-0 text-muted'>Name</th>
            <th className='border-right-0 text-muted'>Description</th>
          </tr>
        </thead>
        <tbody>
          {dashboards.map(r => (
            <tr key={r.path} onClick={() => navigate(`${ROUTES.Analytics}/${r.path}`)}>
              <td className='border-left-0'>{r.name}</td>
              <td className='border-right-0 text-muted'>
                <div className='d-flex justify-content-between'>
                  <span>{r.desc}</span>
                  <Icon icon='forward' size={24}/>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}

export default Analytics
