import PromoCodeField from './PromoCodeField'
import useDataService from '../../../hooks/useDataService'
import { useContext } from 'react'
import { OrderContext } from '../../../contexts/OrderContext'

const PromoCodeFieldWrapper = () => {
  const { apiPatchPromoCode } = useDataService()
  const { order, setOrder } = useContext(OrderContext)

  const saveCode = async (values) => {
    const res = await apiPatchPromoCode(order._id, values.code)
    if (res) {
      setOrder(res)
    } else {
      throw new Error()
    }
  }

  const removeCode = async () => {
    const res = await apiPatchPromoCode(order._id, null)
    if (res) {
      setOrder(res)
    }
  }

  return <PromoCodeField removable={!order.paid} onApply={saveCode} onRemove={removeCode} className='px-0 mb-2' />
}

export default PromoCodeFieldWrapper
