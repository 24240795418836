import { Col, Container, Row } from 'react-bootstrap'
import StringerWorkloadChart from '../components/charts/StringerWorkloadChart'
import { useContext, useState } from 'react'
import { SharedDataContext } from '../contexts/SharedDataContext'
import TournamentDropdown from '../components/common/TournamentDropdown'
import withTournamentSelectionRequired from '../components/generic/withTournamentSelectionRequired'
import StatsRow from '../components/charts/StatsRow'
import DropOffTimesChart from '../components/charts/DropOffTimesChart'
import DailyTotalsChart from '../components/charts/DailyTotalsChart'
import DueTimesChart from '../components/charts/DueTimesChart'

const TournamentOverviewDashboard = () => {
  const { tournament } = useContext(SharedDataContext)
  const [selectedTournament, setSelectedTournament] = useState(tournament)
  // const date = '2024-08-21'
  // DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  return (
    <Container fluid className='mx-md-0 px-md-0'>
      <Row className='mx-0 px-1 pb-3 mb-3 border-bottom justify-content-between align-items-end'>
        <Col xs='auto'>
          <h3>Tournament Overview</h3>
        </Col>
      </Row>
      <Container>
        <Row className='gy-1 mb-3'>
          <Col xs={12} md={6}>
            <TournamentDropdown value={selectedTournament} handleSelect={(t) => setSelectedTournament(t)} />
          </Col>
        </Row>
      </Container>
      <Container className='mb-4'>
        <StatsRow event={selectedTournament}/>
      </Container>
      <Container>
        <Row className='bg-white rounded gx-0 gy-2 pb-2'>
          <Col xs={12}>
            <StringerWorkloadChart event={selectedTournament} />
          </Col>
          <Col xs={12}>
            <DailyTotalsChart event={selectedTournament}/>
          </Col>
          <Col xs={6}>
            <DropOffTimesChart event={selectedTournament} />
          </Col>
          <Col xs={6}>
            <DueTimesChart event={selectedTournament}/>
          </Col>
        </Row>

      </Container>
    </Container>
  )
}

export default withTournamentSelectionRequired(TournamentOverviewDashboard)
