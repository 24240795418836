import { useEffect, useState } from 'react'
import useDataService from '../../hooks/useDataService'
import { Col, Row } from 'react-bootstrap'
import { object, string } from 'prop-types'

const propTypes = {
  event: object.isRequired,
  tensionUnits: string.isRequired
}

const TensionStats = ({ event, tensionUnits }) => {
  const { apiGetMetrics } = useDataService()
  const [data, setData] = useState()

  const defaultValue = {
    averageCross: 0,
    averageMain: 0,
    max: ['N/A', 0],
    min: ['N/A', 0]
  }

  const lbToKg = num => num * 0.45359237
  const kgToLb = num => num / 0.45359237

  useEffect(() => {
    apiGetMetrics(event._id, 'tension-stats').then(res => {
      const data = res.length > 0 ? { ...res[0], units: 'kg' } : defaultValue
      console.log(res)
      setData(data)
    })
  }, [event])

  useEffect(() => {
    if (data) {
      if (tensionUnits === 'lb' && data.units !== 'lb') {
        setData({
          averageCross: kgToLb(data.averageCross),
          averageMain: kgToLb(data.averageMain),
          min: [data.min[0], kgToLb(data.min[1])],
          max: [data.max[0], kgToLb(data.max[1])],
          units: 'lb'
        })
      } else if (tensionUnits === 'kg' && data.units !== 'kg') {
        setData({
          averageCross: lbToKg(data.averageCross),
          averageMain: lbToKg(data.averageMain),
          min: [data.min[0], lbToKg(data.min[1])],
          max: [data.max[0], lbToKg(data.max[1])],
          units: 'kg'
        })
      }
    }
  }, [tensionUnits, data])

  if (!data) {
    return null
  }

  return (
    <Row>
      <Col>
          <div className='bg-white rounded p-2 text-center'>
            <h5 className='mb-2'>Highest tension</h5>
            <p className='fs-5 text-body'>{data.max[1].toFixed(2)} {tensionUnits}</p>
            <p>{data.max[0]}</p>
          </div>
        </Col>
      <Col>
          <div className='bg-white rounded p-2 text-center'>
            <h5 className='mb-2'>Lowest tension</h5>
            <p className='fs-5 text-body'>{data.min[1].toFixed(2)} {tensionUnits}</p>
            <p>{data.min[0]}</p>
          </div>
        </Col>
      <Col>
          <div className='bg-white rounded p-2 text-center'>
            <h5 className='mb-2'>Average tension</h5>
            <p>Mains: <span className='fs-5 text-body'>{data.averageMain.toFixed(2)} {tensionUnits}</span></p>
            <p>Cross: <span className='fs-5 text-body'>{data.averageCross.toFixed(2)} {tensionUnits}</span></p>
          </div>
        </Col>
    </Row>
  )
}

TensionStats.propTypes = propTypes

export default TensionStats
