import { Formik } from 'formik'
import { Button, Form } from 'react-bootstrap'
import * as yup from 'yup'
import useAuth from '../../hooks/useAuth'
import PasswordField from '../signup/PasswordField'
import { func, string } from 'prop-types'

const propTypes = {
  onLogIn: func.isRequired,
  buttonText: string
}

const LogInForm = ({ onLogIn, buttonText }) => {
  const { loginWithEmailAndPassword } = useAuth()

  const loginSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Invalid email'),
    password: yup.string().required('Password is required')
  })

  const handleLogin = async (values) => {
    const success = await loginWithEmailAndPassword(values.email, values.password)
    if (success) {
      onLogIn(values.password)
    }
  }

  return (
    <Formik
      initialValues={{
        email: '',
        password: ''
      }}
      validationSchema={loginSchema}
      validateOnChange={true}
      onSubmit={handleLogin}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group controlId="email" className='mb-5'>
            <Form.Control
              type="text"
              value={values.email}
              onChange={handleChange}
              placeholder="Email"
              isInvalid={!!errors.email && touched.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <PasswordField
            controlId='password'
            value={values.password}
            isInvalid={!!errors.password && touched.password}
            error={errors.email}
            handleChange={handleChange}
            handleBlur={handleBlur}
          />
          <Button
            className="w-100"
            type="Submit"
            disabled={isSubmitting}
          >
            {isSubmitting
              ? 'Loading'
              : buttonText ?? 'Log In'
            }
          </Button>
        </Form>
      )}
    </Formik>
  )
}

LogInForm.propTypes = propTypes

export default LogInForm
