import { isEmpty } from 'lodash'
import { Button, Col, Container, Row } from 'react-bootstrap'
import useDataService from '../hooks/useDataService'
import { useContext, useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import { date, object, string } from 'yup'
import { DateTime } from 'luxon'
import useAuth from '../hooks/useAuth'
import { ROLE } from '../helpers'
import PlayerInvoiceForm from '../components/invoices/PlayerInvoiceForm'
import AdminInvoiceForm from '../components/invoices/AdminInvoiceForm'
import { SharedDataContext } from '../contexts/SharedDataContext'
import withCompleteProfileRequired from '../components/generic/withCompleteProfileRequired'
import { usePDF } from '@react-pdf/renderer'
import PDFInvoice from '../components/invoices/PDFInvoice'
import Icon from '../icons/Icon'

const Invoices = () => {
  const { currentUser } = useAuth()
  const [action, setAction] = useState()
  const { setBanner } = useContext(SharedDataContext)
  const [invoiceFile, updateInvoiceFile] = usePDF({ document: PDFInvoice({ data: [] }) })
  const { apiGetInvoiceData, apiEmailInvoice } = useDataService()
  const csvLinkRef = useRef(null)
  const [error, setError] = useState()
  const [emailRecipient, setEmailRecipient] = useState()

  const ACTION = {
    Download: 'download',
    Preview: 'preview',
    Email: 'email'
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        resolve(reader.result)
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const playerInvoiceSchema = object({
    range: string().required(),
    startDate: date().when('range', {
      is: 'byDate',
      then: s => s.required('Enter the start date'),
      otherwise: s => s.notRequired()
    }),
    endDate: date().when('range', {
      is: 'byDate',
      then: s => s.required('Enter the end date'),
      otherwise: s => s.notRequired()
    }).when('startDate', (startDate, yup) => {
      return startDate && yup.min(startDate)
    }),
    event: object({
      _id: string()
    }).default(undefined).when('range', {
      is: 'byEvent',
      then: s => s.required(),
      otherwise: s => s.notRequired()
    })
  })

  const adminInvoiceSchema = object({
    customer: object({
      _id: string().required(),
      invoiceRecipient: string().email('Please enter a valid email address').required('Email is required')
    })
  }).concat(playerInvoiceSchema)

  const handleAction = async (values, bag, action) => {
    setError()
    const validation = await bag.setTouched({
      range: true,
      event: true,
      startDate: true,
      endDate: true,
      customer: {
        _id: true,
        invoiceRecipient: true
      }
    }, true)

    if (isEmpty(validation)) {
      const data = await apiGetInvoiceData({
        event: values.event?._id,
        startDate: values.startDate,
        endDate: values.endDate,
        customer: values.customer?._id
      })
      if (data.length > 0) {
        updateInvoiceFile(PDFInvoice({ data }))
        setAction(action)
        if (action === ACTION.Email) {
          setEmailRecipient({
            customer: values.customer._id,
            invoiceRecipient: values.customer.invoiceRecipient
          })
        }
      } else {
        setError('There are no orders in the selected range')
      }
    }
  }

  useEffect(() => {
    if (invoiceFile.blob && !invoiceFile.loading) {
      switch (action) {
        case ACTION.Download:
          csvLinkRef.current.click()
          setAction()
          break
        case ACTION.Preview:
          openInNewTab(invoiceFile.url)
          setAction()
          break
        case ACTION.Email:
          console.log()
          blobToBase64(invoiceFile.blob)
            .then(str => {
              return apiEmailInvoice({ ...emailRecipient, base64Content: str.split(',')[1] })
            })
            .then(res => {
              if (res.ok) {
                setBanner('Email sent!')
              }
            })
            .finally(() => setAction())
          break
        default:
          console.log('Invoice action is null or not supported')
      }
    }
  }, [invoiceFile.blob, invoiceFile.loading])

  return (
    <Container fluid className='mx-md-0 px-md-0 mw-750'>
      <a href={invoiceFile.url} ref={csvLinkRef} download='stringr_invoice.pdf' className="d-none">Invoice</a>
      <Row className='mx-0 px-1 pb-3 mb-3 border-bottom justify-content-between align-items-end'>
        <Col xs='auto'>
          <h3>Generate an invoice</h3>
          <p>Select a date range and {currentUser.role === ROLE.Player ? 'donwload' : 'email'} a PDF invoice.</p>
        </Col>
      </Row>

      <Formik
        initialValues={{
          range: 'byEvent',
          startDate: DateTime.now().set({ month: 1, day: 1 }).toISODate(),
          endDate: DateTime.now().toISODate(),
          customer: {
            _id: '',
            invoiceRecipient: ''
          }
        }}
        validationSchema={currentUser.role === ROLE.Player ? playerInvoiceSchema : adminInvoiceSchema}
      >
        {formik => (
          <>
            {currentUser.role === ROLE.Player
              ? <PlayerInvoiceForm />
              : <AdminInvoiceForm />
            }
            <Row className='justify-content-around px-2 gy-1'>
              {currentUser.role === ROLE.Player
                ? (
                    <Col xs={12}>
                      <Button
                        type='button'
                        variant='primary'
                        className='w-100'
                        disabled={!!action}
                        onClick={() => handleAction(formik.values, formik, ACTION.Download)}
                      >
                        {action === ACTION.Download
                          ? <Icon icon='loader' />
                          : <span>Download invoice</span>
                        }
                      </Button>
                    </Col>
                  )
                : (
                    <>
                      <Col xs={12} md={6}>
                        <Button
                          type='button'
                          variant='secondary'
                          className='w-100'
                          disabled={!!action}
                          onClick={() => handleAction(formik.values, formik, ACTION.Preview)}
                        >
                          {action === ACTION.Preview
                            ? <Icon icon='loader' />
                            : (
                              <>
                                <span>Preview</span>
                                <Icon icon='open' size={16} />
                              </>
                              )
                          }
                        </Button>
                      </Col>
                      <Col xs={12} md={6}>
                        <Button
                          type='button'
                          variant='primary'
                          className='w-100'
                          disabled={!!action}
                          onClick={() => handleAction(formik.values, formik, ACTION.Email)}
                        >
                          {action === ACTION.Email
                            ? <Icon icon='loader' />
                            : <span>Send invoice</span>
                          }
                        </Button>
                      </Col>
                    </>
                  )
              }
            </Row>
          </>
        )}
      </Formik>
      {error &&
        <Row>
          <Col>
            <p className='text-danger text-center mt-2'>{error}</p>
          </Col>
        </Row>
      }
    </Container>
  )
}

export default withCompleteProfileRequired(Invoices)
