import { useContext, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import LocationInfo from '../location/LocationInfo'
import ApprovalPendingAlert from './ApprovalPendingAlert'
import { EventContext } from '../../contexts/EventContext'
import ServicePriceForm from './ServicePriceForm'
import { Formik } from 'formik'
import { array, number, object, string } from 'yup'
import useDataService from '../../hooks/useDataService'

const EventInfo = () => {
  const { event, refresh } = useContext(EventContext)
  const [editing, setEditing] = useState(false)
  const { apiPatchAddOns } = useDataService()

  const handleServicePricesUpdate = async (values, bag) => {
    console.log(values)
    bag.setSubmitting(true)
    await apiPatchAddOns(event._id, values)
    await refresh()
    bag.setSubmitting(false)
    setEditing(false)
  }

  return (
    <Container className='ps-md-5 pt-2 ms-md-0 mw-750'>
      <ApprovalPendingAlert show={!event.approved} />
      <Row className='mb-3'>
        <Col xs={12}>
          <h4 className='mb-2'>Location</h4>
        </Col>
        <LocationInfo location={event.location} />
      </Row>

      <Row className='mb-3 align-items-center'>
        <Col>
          <h4>Service prices</h4>
        </Col>
        <Col xs={6}>
          {!editing &&
            <Button
              variant='primary'
              onClick={() => setEditing(true)}
            >
              Edit
            </Button>
          }
        </Col>
      </Row>
      {!editing && event.addOns.map((a, i) => (
        <Row key={a._id} className='mb-1 justify-content-between'>
          <Col>
            <h5>{a.title}</h5>
          </Col>
          <Col>
            <h6>${a.price.toFixed(2)}</h6>
          </Col>
        </Row>
      ))}

      {editing &&
        <Formik
          initialValues={{ addOns: event.addOns }}
          onSubmit={handleServicePricesUpdate}
          validationSchema={object({
            addOns: array().of(object({
              _id: string().required(),
              price: number().min(0, 'Invalid price').required('Enter the price')
            })).required().min(1)
          })}
        >
          {formik => (
            <Form onSubmit={formik.handleSubmit} noValidate>
              <ServicePriceForm/>
              <Row className='mt-3'>
                <Col xs={6} md={5}>
                  <Button
                    type='button'
                    variant='danger'
                    className='w-100'
                    onClick={() => setEditing(false)}
                    disabled={formik.isSubmitting}
                  >Cancel</Button>
                </Col>
                <Col xs={6} md={5}>
                  <Button
                    type='submit'
                    variant='primary'
                    className='w-100'
                    disabled={formik.isSubmitting}
                  >Save</Button>
                </Col>
              </Row>
            </Form>

          )}
        </Formik>
      }
    </Container>
  )
}

export default EventInfo
