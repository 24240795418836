export class RacquetModel {
  fullName = ''
  brand = ''
  name = ''
  other

  constructor (other = false) {
    this.other = other
  }
}
