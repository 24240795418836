import { Col, Container, Offcanvas, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import UserSearch from '../components/common/UserSearch'
import { ROLE } from '../helpers'
import UserProfile from '../components/profile/UserProfile'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../routes'
import useDataService from '../hooks/useDataService'

const Users = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { apiGetUser } = useDataService()
  const [user, setUser] = useState()
  const [show, setShow] = useState(false)

  const handleUserLookup = (user) => {
    navigate(`${ROUTES.Users}/${user._id}`)
    setUser(user)
  }

  useEffect(() => {
    if (id && (!user || user._id !== id)) {
      apiGetUser(id).then(setUser)
    }
  }, [id])

  useEffect(() => {
    if (user) {
      setShow(true)
    }
  }, [user])

  return (
    <Container className='h-100 px-0 mx-0 pt-2'>
      <Row className='h-100'>
        <Col md={5} className='ps-md-4 border-md-right'>
          <Row className='flex-column'>
            <Col xs={12}><h3 className='mb-2'>Find a user</h3></Col>
            <UserSearch roles={[ROLE.Player, ROLE.Admin, ROLE.Stringer]} onUserSelected={handleUserLookup} isInvalid={false} />
          </Row>
        </Col>
        <Col md={7}>
          <Offcanvas show={show} onHide={() => setShow(false)} responsive='md' placement='bottom'>
            <Offcanvas.Header closeButton className='justify-content-end'/>
            <Offcanvas.Body>
              {user && <UserProfile user={user} setUser={setUser} /> }
            </Offcanvas.Body>
          </Offcanvas>
        </Col>
      </Row>
    </Container>
  )
}

export default Users
