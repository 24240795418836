import { Button, Col, Form, Row } from 'react-bootstrap'
import { useFormikContext } from 'formik'
import * as yup from 'yup'
import withFormStepWrapper from './withFormStepWrapper'
import { useState } from 'react'
import { func, object } from 'prop-types'
import BackButton from '../common/BackButton'

const validationSchema = yup.object({
  playerId: yup.string().required()
})

const propTypes = {
  values: object.isRequired,
  onContinue: func.isRequired,
  onBack: func.isRequired
}

const PlayerIdStep = (props) => {
  const { values, onContinue, onBack } = props
  const [showField, setShowField] = useState(values.playerId !== '')

  if (showField) {
    return <PlayerIdField {...props} />
  } else {
    return (
      <div>
        <div className='mb-3'>
          <BackButton onClick={onBack} text='Back'/>
        </div>
        <div className='mx-3'>
          <h2 className='mb-3'>Are you an ATP/WTA player?</h2>
          <p className='mb-3'>You will be asked to enter your verified player ID if you select Yes.</p>
          <Row className='flex-column gy-2'>
            <Col xs={12}>
              <Button type='button' variant='primary' className='w-100' onClick={() => setShowField(true)}>Yes</Button>
            </Col>
            <Col xs={12}>
              <Button type='button' variant='info' className='w-100' onClick={() => onContinue(values)}>No</Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

PlayerIdStep.propTypes = propTypes

const PlayerIdField = withFormStepWrapper(() => {
  const formik = useFormikContext()

  return (
    <Form.Group controlId="playerId" className="mb-5">
      <h2 className='mb-3'>What is your ATP/WTA player ID?</h2>
      <p className='mb-3'>Please enter only verified player ID. Go back to skip this step.</p>
      <Form.Control
        type="text"
        placeholder="Enter player ID"
        value={formik.values.playerId}
        onChange={formik.handleChange}
        onBeforeInput={formik.handleBlur}
        autoFocus
      />
    </Form.Group>
  )
}, validationSchema)

export default PlayerIdStep
