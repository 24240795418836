import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import * as PropTypes from 'prop-types'

const propTypes = {
  init: PropTypes.bool
}

const Paywall = ({ init }) => {
  const [show, setShow] = useState(init)

  return (
    <Modal show={show} onHide={() => setShow(false)} size='lg' backdrop='static' centered>
      <Modal.Header closeButton>
        <Modal.Title>Thank you for using Stringr!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Your subscription has expired. Please renew your subscription and contact <a href='mailto:artem@stringr.pro'>artem@stringr.pro</a> to continue using Stringr.</p>
      </Modal.Body>
    </Modal>
  )
}

Paywall.propTypes = propTypes

export default Paywall
