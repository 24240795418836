import { Col, Row } from 'react-bootstrap'
import Icon from '../../icons/Icon'
import { useContext } from 'react'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { string } from 'prop-types'

const propTypes = {
  className: string
}

const CurrentEventWidget = ({ className }) => {
  const { tournament } = useContext(SharedDataContext)
  const navigate = useNavigate()

  return (
    <Col xs='auto' role='button' className={`w-100 mb-3 ${className}`} onClick={() => navigate(ROUTES.SelectTournament)}>
      <Row>
        <Col xs={2} className='text-accent'>
          <Icon icon='location' size={16} />
        </Col>
        <Col xs={10} className='text-body'>
          <p className='text-truncate' title={tournament?.name ?? ''}>{tournament?.name ?? 'Select event'}</p>
        </Col>
      </Row>
    </Col>
  )
}

CurrentEventWidget.propTypes = propTypes

export default CurrentEventWidget
