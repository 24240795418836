import { useFormikContext } from 'formik'
import { Form } from 'react-bootstrap'
import * as yup from 'yup'
import withFormStepWrapper from './withFormStepWrapper'

const validationSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required')
})

const NameStep = (props) => {
  const formik = useFormikContext()

  return (
    <div>
      <h2 className='mb-3'>What is your name?</h2>
      <Form.Group controlId="firstName" className='mb-5'>
        <Form.Control
          type="text"
          placeholder="First name"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBeforeInput={formik.handleBlur}
          isInvalid={!!formik.errors.firstName && formik.touched.firstName}
          autoFocus
        />
        <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId="lastName" className="mb-5">
        <Form.Control
          type="text"
          placeholder="Last name"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBeforeInput={formik.handleBlur}
          isInvalid={!!formik.errors.lastName && formik.touched.lastName}
        />
        <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
      </Form.Group>
    </div>
  )
}

export default withFormStepWrapper(NameStep, validationSchema)
