import { string } from 'prop-types'
import { Button, Container } from 'react-bootstrap'
import { AlertHeading, CustomAlert } from '../generic/CustomAlert'
import Icon from '../../icons/Icon'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'

const propTypes = {
  message: string.isRequired
}

const InvalidEmailActionLink = ({ message }) => {
  const navigate = useNavigate()

  return (
    <Container>
      <CustomAlert variant='danger' type='error'>
        <AlertHeading>
          <Icon icon='error' />
          <span>Invalid link</span>
        </AlertHeading>
        <p className='text-muted'>{message}</p>
      </CustomAlert>
      <Button className='w-100' onClick={() => navigate(ROUTES.Home)}>Got it!</Button>
    </Container>
  )
}

InvalidEmailActionLink.propTypes = propTypes

export default InvalidEmailActionLink
