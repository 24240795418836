import { useFormikContext } from 'formik'
import { Form } from 'react-bootstrap'
import * as yup from 'yup'
import withFormStepWrapper from './withFormStepWrapper'

const validationSchema = yup.object({
  email: yup.string().required('Email is required').email('Invalid email')
})

const EmailStep = () => {
  const formik = useFormikContext()

  return (
    <Form.Group controlId="email" className="mb-5">
      <h2 className='mb-3'>Enter your email address</h2>
      <Form.Control
        type="text"
        placeholder="youremail@mail.com"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBeforeInput={formik.handleBlur}
        autoFocus
      />
    </Form.Group>
  )
}

export default withFormStepWrapper(EmailStep, validationSchema)
