import { Button, Col, Offcanvas, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import BackButton from '../common/BackButton'
import useDataService from '../../hooks/useDataService'
import { useContext, useEffect, useState } from 'react'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import UserIcon from '../common/UserIcon'

const propTypes = {
  orderId: PropTypes.string,
  friendlyOrderId: PropTypes.string,
  update: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  initialSelected: PropTypes.string
}

const StringerSelection = ({ orderId, initialSelected, friendlyOrderId, update, show, close }) => {
  const { apiPatchStringer } = useDataService()
  const { tournament } = useContext(SharedDataContext)
  const [selected, setSelected] = useState(initialSelected)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setSelected(initialSelected)
  }, [initialSelected])

  const handleSelect = (id) => (e) => {
    e.stopPropagation()
    setSelected(id)
  }

  const handleUpdateStringer = async (e) => {
    e.stopPropagation()
    setSubmitting(true)
    const res = await apiPatchStringer(orderId, selected)
    update(res)
    setSubmitting(false)
    setSelected()
    close()
  }

  const handleClose = (e) => {
    setSelected()
    close()
  }

  return (
    <Offcanvas show={show} onHide={handleClose} placement='end'>
      <Offcanvas.Header>
        <BackButton onClick={handleClose}/>
        <Offcanvas.Title className='flex-grow-1 text-center'>
          Stringers
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {tournament.stringers.map(s => (
          <Row
            key={s._id}
            className={`stringer-card bg-white rounded py-2 mx-1 mb-2 align-items-center ${selected === s._id ? 'active' : ''}`}
            role='button'
            onClick={handleSelect(s._id)}
          >
            <Col xs='auto'>
              <UserIcon user={s}/>
            </Col>
            <Col>
              <span className='fs-5 fw-medium'>{s.firstName} {s.lastName}</span>
            </Col>
          </Row>
        ))}
        <div className='text-center'>
          <Button
            type='button'
            variant='primary'
            className='mt-1'
            disabled={!selected || submitting}
            onClick={handleUpdateStringer}
          >Assign to #{friendlyOrderId}</Button>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

StringerSelection.propTypes = propTypes

export default StringerSelection
