import { Col, Image, Nav, Row } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { ROUTES } from '../routes'
import useAuth from '../hooks/useAuth'
import PropTypes from 'prop-types'
import User from './common/User'
import { useEffect, useState } from 'react'
import Icon from '../icons/Icon'
import CurrentEventWidget from './common/CurrentEventWidget'
import LogoutButton from './LogoutButton'
import { ROLE } from '../helpers'

const Menu = () => {
  const { currentUser } = useAuth()
  const location = useLocation()
  const [menuItems, setMenuItems] = useState([])

  useEffect(() => {
    if (currentUser) {
      if (ROLE.Admin === currentUser.role) {
        setMenuItems([dashboard, events, customers, reports, invoices, analytics, myProfile, aboutUs])
      } else if ([ROLE.SiteOwner, ROLE.SuperUser].includes(currentUser.role)) {
        setMenuItems([dashboard, events, users, reports, invoices, analytics, myProfile, aboutUs])
      } else if (currentUser.role === ROLE.Stringer) {
        setMenuItems([dashboard, events, myProfile, aboutUs])
      } else {
        setMenuItems([myRestrings, invoices, myProfile, aboutUs])
      }
    } else {
      setMenuItems([home, aboutUs])
    }
  }, [currentUser])

  const home = {
    title: 'Home',
    icon: 'home',
    href: ROUTES.Home
  }
  const aboutUs = {
    title: 'About Us',
    icon: '',
    href: ROUTES.AboutUs
  }
  const myRestrings = {
    title: 'My re-strings',
    icon: 'home',
    href: ROUTES.Dashboard
  }
  const dashboard = {
    title: 'Dashboard',
    icon: 'home',
    href: ROUTES.Dashboard
  }
  const events = {
    title: 'Events',
    icon: 'location',
    href: ROUTES.SelectTournament
  }

  const customers = {
    title: 'Customers',
    icon: 'people',
    href: ROUTES.Customers
  }

  const users = {
    title: 'Users',
    icon: 'people',
    href: ROUTES.Users
  }

  const reports = {
    title: 'Reports',
    icon: 'file',
    href: ROUTES.Reports
  }
  const invoices = {
    title: 'Invoices',
    icon: 'dollar',
    href: ROUTES.Invoices
  }

  const myProfile = {
    title: 'My Profile',
    icon: 'person',
    href: ROUTES.MyProfile
  }

  const analytics = {
    title: 'Analytics',
    icon: 'analytics',
    href: ROUTES.Analytics
  }

  return (
    <Nav as={Row} className='flex-column flex-nowrap align-items-center align-items-lg-start mx-5 mx-md-0 pt-5 pt-md-0 ps-lg-3 position-md-sticky top-0' activeKey={location.pathname}>
      <Col xs='auto' className='d-none d-md-block mb-5 mt-5 pe-0'>
        <Image src='/images/logo.svg' className='px-1 w-75' />
      </Col>

      <User />

      { currentUser && <CurrentEventWidget className='d-none d-lg-block' />}

      <Col xs='auto' className='d-flex flex-column pe-0 w-100'>
        {menuItems.map(i => (
          <MenuItem key={i.href} item={i} />
        ))}
      </Col>

      <Col xs='auto' className='d-flex flex-md-column flex-grow-1 justify-content-between justify-content-md-end align-items-end align-items-md-center align-items-lg-start pe-0 w-100 mb-5'>
        {currentUser
          ? <>
            <SocialLinks className='d-md-none' />
            <Col xs='auto'>
              <LogoutButton responsive />
            </Col>
          </>
          : <SocialLinks />
        }
      </Col>
      <div id='navbar-spacer' className='d-block d-md-none' />
    </Nav>
  )
}

const menuItemPropTypes = {
  item: PropTypes.object.isRequired
}

const MenuItem = ({ item }) => {
  return (
    <Nav.Link
      as={Link}
      to={item.href}
      eventKey={item.href}
      className='px-0 d-flex align-items-center justify-content-between justify-content-md-center justify-content-lg-start border-bottom border-md-bottom-none pb-2 mb-md-1'
    >
      <Icon icon={item.icon} className='d-none d-md-block d-lg-none' />
      <span className='d-md-none d-lg-block'>{item.title}</span>
      <Icon icon='forward' size={18} className='d-md-none' />
    </Nav.Link>
  )
}

MenuItem.propTypes = menuItemPropTypes

const SocialLinks = ({ ...props }) => {
  return (
    <Col xs='auto' className='d-flex flex-md-column flex-lg-row align-items-md-center justify-content-md-end   py-1' {...props}>
      <a href='https://www.instagram.com/stringrtennis/'><Icon icon='instagram' className='me-1 me-md-0 me-lg-1 mb-1' /></a>
      <a href='https://www.facebook.com/StringrApp'><Icon icon='facebook' className='mb-1'/></a>
    </Col>
  )
}

export default Menu
