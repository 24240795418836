import { find } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { Col, Dropdown, FormControl } from 'react-bootstrap'
import useDataService from '../../hooks/useDataService'
import { useFormikContext } from 'formik'
import Feedback from 'react-bootstrap/esm/Feedback'
import { useRootClose } from 'react-overlays'
import LocationModal from './LocationModal'

const LocationsDropdown = () => {
  const { values, errors, touched, setFieldValue, setTouched } = useFormikContext()
  const [locations, setLocations] = useState([])
  const [show, setShow] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [search, setSearch] = useState('')
  const [refresh, setRefresh] = useState(1)
  const ref = useRef()

  const { apiGetLocations } = useDataService()

  useEffect(() => {
    apiGetLocations().then(setLocations)
  }, [refresh])

  const handleRootClose = event => {
    event.stopPropagation()
    event.preventDefault()
    setShow(false)
  }

  useRootClose(ref, handleRootClose, { disabled: !show })

  const handleSelect = (locationId) => {
    console.log(locationId)
    if (locationId === 'new') {
      setShowModal(true)
    } else {
      const l = find(locations, l => l._id === locationId)
      console.log(l)
      setFieldValue('location', l)
    }
    setShow(false)
  }

  const saveLocation = (l) => {
    setFieldValue('location', l)
    setShowModal(false)
    setRefresh(refresh + 1)
  }

  return (
    <Dropdown show={show} onSelect={handleSelect} className='mb-3'>
      <LocationModal show={showModal} saveLocation={saveLocation} onHide={() => setShowModal(false)} />
        <FormControl
          type="text"
          placeholder='Search locations...'
          onClick={() => {
            setTouched('location', true)
            setFieldValue('location', undefined)
            setSearch('')
            setShow(true)
          }}
          onChange={e => setSearch(e.target.value)}
          value={values.location?._id ? `${values.location.name} (${values.location.address})` : search}
          isInvalid={!!errors?.location?._id && touched.location}
        />
        <Feedback type='invalid'>{errors?.location?._id}</Feedback>
        <Dropdown.Menu ref={ref} className='w-100 shadow-lg mt-1 bg-white' id='player-search'>
          {locations.filter(l => search.length > 0 ? l.name.toLowerCase().includes(search.toLowerCase()) : true).map(l => (
            <Dropdown.Item key={l._id} eventKey={l._id} className='d-flex ms-0 py-1 px-3 justify-content-between'>
              <Col xs='auto' className='align-self-center'>
                <span>{l.name}</span>
              </Col>
              <Col xs='auto' className='align-self-center'>
                <span className='text-muted'>{l.address}</span>
              </Col>
            </Dropdown.Item>
          ))}
          <Dropdown.Item eventKey='new' className='ms-0 py-1 px-3'>
            <u>+ Add location</u>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
  )
}

export default LocationsDropdown
