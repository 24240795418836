import { Button } from 'react-bootstrap'
import Icon from '../../icons/Icon'
import PropTypes from 'prop-types'

const propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string
}

const BackButton = ({ onClick, text }) => {
  return (
    <Button
      type='button'
      variant='icon-wrapper'
      onClick={onClick}
    >
      <Icon icon='back' size={16}/>
      <span>{text}</span>
    </Button>
  )
}

BackButton.propTypes = propTypes

export default BackButton
