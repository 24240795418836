import PropTypes from 'prop-types'
import { useContext, useEffect } from 'react'
import { Button, Container, Form } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import PasswordField from '../signup/PasswordField'
import useDataService from '../../hooks/useDataService'
import { SharedDataContext } from '../../contexts/SharedDataContext'

const propTypes = {
  actionCode: PropTypes.string.isRequired
}

const PasswordReset = ({ actionCode }) => {
  const { currentUser } = useAuth()
  const { apiUpdatePasswordWithToken } = useDataService()
  const navigate = useNavigate()
  const { setBanner } = useContext(SharedDataContext)

  useEffect(() => {
    if (currentUser) {
      navigate(ROUTES.Dashboard)
    }
  }, [currentUser])

  const handlePasswordReset = async (values) => {
    await apiUpdatePasswordWithToken(actionCode, values.password)
    setBanner('Password updated successfully!')
    navigate(ROUTES.Home)
  }

  return (
    <Container>
      <Formik
        validationSchema={object({
          password: string().required('Password is required').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/, { message: 'Password must be at least 6 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character.' })
        })}
        validateOnChange={true}
        initialValues={{
          password: ''
        }}
        onSubmit={handlePasswordReset}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          errors
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <PasswordField
              controlId='password'
              value={values.password}
              isInvalid={!!errors.password && touched.password}
              handleBlur={handleBlur}
              handleChange={handleChange}
              error={errors.password}
              label="Let's set a new password"
            />
            <Button
              type='submit'
              variant='primary'
              className='w-100'
            >
              Confirm
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

PasswordReset.propTypes = propTypes

export default PasswordReset
