import { object } from 'prop-types'
import AnalyticsChart from './AnalyticsChart'
import { parseChartsData } from './chart-helpers'

const propTypes = {
  event: object.isRequired
}

const RacquetBrandsChart = ({ event }) => {
  const CHART_ID = 'racquet-brand-stats'

  const parseData = parseChartsData

  const options = {
    colors: [
      '#64B0BA',
      '#64B97B',
      '#A95E79',
      '#E7DA65',
      '#F46B6B',
      '#DEA9DC',
      '#818FDA',
      '#F4A56B'
    ],
    legend: {
      position: 'top',
      maxLines: 3
    }
  }

  return (
    <AnalyticsChart
      title='Racquet Brands'
      chartId={CHART_ID}
      parseData={parseData}
      chartType="PieChart"
      options={options}
      event={event}
    />
  )
}

RacquetBrandsChart.propTypes = propTypes

export default RacquetBrandsChart
