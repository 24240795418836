import { Container } from 'react-bootstrap'
import LogInForm from '../components/login/LogInForm'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'
import useAuth from '../hooks/useAuth'

const StaffLogIn = () => {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser) {
      navigate(ROUTES.Dashboard)
    }
  }, [currentUser])

  return (
    <Container>
        <LogInForm />
    </Container>
  )
}

export default StaffLogIn
