import { Button } from 'react-bootstrap'
import useAuth from '../hooks/useAuth'
import Icon from '../icons/Icon'
import { bool } from 'prop-types'

const propTypes = {
  responsive: bool
}

const LogoutButton = ({ responsive }) => {
  const { logout } = useAuth()

  return (
    <Button variant='logout' onClick={logout} className='px-1'>
      <Icon icon='logout' size={24} className='d-none d-md-inline d-lg-none' />
      <Icon icon='logout' size={18} className='d-inline d-md-none d-lg-inline' />
      <span className={responsive ? 'd-md-none d-lg-inline' : ''}>Log Out</span>
    </Button>
  )
}

LogoutButton.propTypes = propTypes

export default LogoutButton
