import { parseChartsData } from './chart-helpers'
import { object } from 'prop-types'
import { pick, assign, unionBy } from 'lodash'
import AnalyticsChart from './AnalyticsChart'

const propTypes = {
  event: object.isRequired
}

const StringerWorkloadChart = ({ event }) => {
  const CHART_ID = 'stringer-restring-time-count'

  const fillValue = {
    AM: 0,
    PM: 0,
    'On court': 0,
    'N/A': 0,
    total: 0
  }

  const parseData = data => {
    if (data.length === 0) {
      return data
    }
    const allStringers = event.stringers.map(s => ({
      _id: s._id,
      stringer: s.fullName,
      ...fillValue
    }))
    const filledArray = unionBy(data, allStringers, s => s._id)
    const totalRacquets = filledArray.reduce((a, b) => a + b.total, 0)
    const average = {
      average: totalRacquets / event.stringers.length,
      tooltip: totalRacquets / event.stringers.length
    }
    const parsedData = parseChartsData(filledArray.map(s => assign(pick(s, ['stringer', 'AM', 'PM', 'On court', 'N/A']), average)))
    const border = ['', 0, 0, 0, 0, average.average, average.average]
    parsedData.push(border)
    parsedData.splice(1, 0, border)
    return parsedData
  }

  const options = {
    isStacked: true,
    colors: ['#64B97B', '#A95E79', '#F4A56B', '#818FDA'],
    seriesType: 'bars',
    series: { 4: { type: 'line', visibleInLegend: true, color: 'black', lineWidth: 2, lineDashStyle: [10, 6] } },
    legend: {
      position: 'right',
      alignment: 'center'
    },
    chartArea: {
      right: 150
    }
  }

  return (
    <AnalyticsChart
      title='Stringer Workload'
      chartId={CHART_ID}
      chartType='ComboChart'
      parseData={parseData}
      options={options}
      event={event}
    />
  )
}

StringerWorkloadChart.propTypes = propTypes

export default StringerWorkloadChart
