import PropTypes from 'prop-types'
import { Button, Col } from 'react-bootstrap'

const propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func
}

const SocialLogin = ({ id, type, onClick }) => {
  // const typeLogo = {
  //   google: '/g-logo.png',
  //   facebook: '/f-logo.png',
  //   apple: '/test.png'
  // }

  const typeName = {
    google: 'Google',
    facebook: 'Facebook',
    apple: 'Apple'
  }
  switch (type) {
    case typeName.apple.toLowerCase():
      return (
        <Col xs={4}>
          <Button variant='info' className='w-100' id={id} onClick={onClick}>
            <img src="/images/apple.png"/>
          </Button>
        </Col>
      )
    case typeName.facebook.toLowerCase():
      return (
        <Col xs={4}>
          <Button variant='info' className='w-100' id={id} onClick={onClick}>
            <img src="/images/facebook.png"/>
          </Button>
        </Col>
      )
    case typeName.google.toLowerCase():
      return (
        <Col xs={4}>
          <Button variant='info' className='w-100' id={id} onClick={onClick}>
            <img src="/images/google.png"/>
          </Button>
        </Col>
      )
    default:
      return null
  }
}

SocialLogin.propTypes = propTypes

export default SocialLogin
