import { Col, Container, Image, Row } from 'react-bootstrap'

const AboutUs = () => {
  return (
    <Container>
      <h2 className='px-md-3'>About Stringr</h2>
      <Row>
        <Col xs={12} md={6} className='p-2 p-md-4'>
          <p>Stringr is an on-demand racquet stringing platorm. We provide racquet stringing services for players from their phone or computer. Think of this as Uber for stringing.</p>
        </Col>
        <Col xs={12} md={6} className='p-2 p-md-4'>
          <Image src='/images/racquet-1.jpg' rounded fluid/>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className='p-2 p-md-4'>
          <h3 className='text-muted text-center text-md-start'>Stringr connects tennis players with local stringers.</h3>
        </Col>
      </Row>
      <Row className='flex-wrap-reverse'>
        <Col xs={12} md={6} className='p-2 p-md-4'>
          <Image src='/images/racquet-2.jpg' rounded fluid/>
        </Col>
        <Col xs={12} md={6} className='p-2 p-md-4'>
          <p>
            The process of getting your racquet restrung can be tedious and lengthy, as the racquet has to be dropped off
            at a pro store and picked up after at least an hour.
          </p>
          <p>
            If the modern technology allows to spawn chauffeurs with a single phone tap,
            why can't we do the same for racquet restringing?
          </p>
        </Col>
      </Row>
      <Row className='mb-4'>
        <Col xs={12} md={6} className='p-2 p-md-4'>
          <p>
            With Stringr, you can place your order online, specifying your stringing preferences and the pickup location.
            A stringer will arrive to pick up your racquets, restring them and then deliver them to the location of your choice.
            No more going to the pro store!
          </p>
          <p>
            Stringr also gives local stringers an opportunity to expand their clientele by meeting more players and stringring more racquets.
          </p>
        </Col>
        <Col xs={12} md={6} className='p-2 p-md-4'>
          <Image src='/images/racquet-3.jpg' rounded fluid/>
        </Col>
        <Col xs={12} className='p-2 p-md-4'>
          <h3 className='text-muted text-center text-md-start'>
            The whole restringing process is made much simpler with Stringr.
          </h3>
        </Col>
      </Row>
    </Container>
  )
}

export default AboutUs
