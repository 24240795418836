import PropTypes from 'prop-types'
import { Formik } from 'formik'
import * as yup from 'yup'
import { Button, Col, Form, Row } from 'react-bootstrap'
import useDataService from '../../hooks/useDataService'
import { ROUTES } from '../../routes'
import { useNavigate } from 'react-router-dom'
import BackButton from '../common/BackButton'

const propTypes = {
  next: PropTypes.bool.isRequired
}

const PasswordResetRequest = ({ next }) => {
  const navigate = useNavigate()
  const { apiRequestPasswordChange } = useDataService()

  const passwordResetSchema = yup.object({
    email: yup.string().required('Email is required').email('Invalid email')
  })

  const handleSubmit = async (data) => {
    await apiRequestPasswordChange(data.email)
    next()
  }

  return (
    <Formik
      initialValues={{
        email: ''
      }}
      validationSchema={passwordResetSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        touched,
        errors,
        handleSubmit,
        handleChange
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <BackButton onClick={() => navigate(ROUTES.Home)} text='Cancel'/>
          <h2>Trouble logging in?</h2>
          <Form.Group controlId="email" className='mb-5'>
            <Form.Label className="mt-5">Please enter your email to reset your password</Form.Label>
            <Form.Control
              type="text"
              value={values.email}
              onChange={handleChange}
              placeholder="Enter your email"
              isInvalid={!!errors.email && touched.email}
            />
            <Form.Control.Feedback type="invalid">{errors?.email}</Form.Control.Feedback>
          </Form.Group>
          <Button
            type='submit'
            variant='primary'
            className='w-100'
          >
            Reset password
          </Button>
          <Row className='align-items-center mt-5 mb-5'>
            <Col xs={5}><hr /></Col>
            <Col xs={2} className='text-muted text-center'>OR</Col>
            <Col xs={5}><hr /></Col>
          </Row>
          <Button
            type='button'
            variant='info'
            className='w-100'
            onClick={() => navigate(ROUTES.SignUp)}
          >
            Create new account
          </Button>
        </Form>

      )}
    </Formik>
  )
}

PasswordResetRequest.propTypes = propTypes

export default PasswordResetRequest
