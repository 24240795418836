import { object } from 'prop-types'
import { parseChartsData } from './chart-helpers'
import { DateTime } from 'luxon'
import AnalyticsChart from './AnalyticsChart'

const propTypes = {
  event: object.isRequired
}

const DailyTotalsChart = ({ event }) => {
  const CHART_ID = 'order-date-count'

  const parseData = data => parseChartsData(data.map(v => ({
    ...v,
    _id: DateTime.fromISO(v._id).toJSDate()
  })))

  const options = {
    colors: ['#64B0BA'],
    legend: {
      position: 'none'
    }
  }

  return (
    <AnalyticsChart
      title='Daily Totals'
      chartId={CHART_ID}
      chartType="ColumnChart"
      parseData={parseData}
      options={options}
      formatters={[{
        type: 'DateFormat',
        column: 0,
        options: {
          pattern: 'MMM d',
          timeZone: DateTime.now().offset / 60
        }
      }]}
      event={event}
    />
  )
}

DailyTotalsChart.propTypes = propTypes

export default DailyTotalsChart
