export class Customer {
  new = false
  _id = ''
  firstName = ''
  lastName = ''
  playerId = ''
  email = ''
  phone = ''
  color = ''
  orders = []

  constructor (newCustomer) {
    this.new = newCustomer
  }
}
