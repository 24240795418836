import { Button, Col, Form, ListGroup, Row, Nav } from 'react-bootstrap'
import Feedback from 'react-bootstrap/esm/Feedback'
import { useFormikContext } from 'formik'
import useDataService from '../../hooks/useDataService'
import { useContext, useEffect, useState } from 'react'
import ListPagination from '../common/ListPagination'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import { DateTime } from 'luxon'
import useAuth from '../../hooks/useAuth'
import { ROLE } from '../../helpers'
import Icon from '../../icons/Icon'
import { SharedDataContext } from '../../contexts/SharedDataContext'

const EventList = () => {
  const TABS = {
    Active: 'active',
    Archived: 'archived',
    PendingApproval: 'pendingapproval',
    Upcoming: 'upcoming'
  }

  const { currentUser } = useAuth()
  const { user } = useContext(SharedDataContext)
  const navigate = useNavigate()
  const formik = useFormikContext()
  const pageSize = 5
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [activeKey, setActiveKey] = useState(TABS.Active)
  const [totalPages, setTotalPages] = useState(0)
  const { apiGetTournaments } = useDataService()
  const [tournaments, setTournaments] = useState([])

  useEffect(() => {
    if (user) {
      let filters
      switch (activeKey) {
        case TABS.PendingApproval:
          filters = { approved: false }
          break
        case TABS.Active:
          filters = {
            approved: true,
            openBefore: DateTime.now().toISODate(),
            closedAfter: DateTime.now().toISODate()
          }
          break
        case TABS.Archived:
          filters = {
            approved: true,
            closedBefore: DateTime.now().toISODate()
          }
          break
        case TABS.Upcoming:
          filters = {
            approved: true,
            openAfter: DateTime.now().toISODate()
          }
          break
      }
      filters.search = search
      apiGetTournaments(filters, page, pageSize).then(res => {
        setTournaments(res.tournaments)
        setTotalPages(res.totalPages)
      })
    }
  }, [user, activeKey, search, page])

  return (
    <Row className='flex-column h-100'>
      <Col xs={12}>
        <h2>Select an event</h2>
        <h5 className='text-muted mb-3'>Choose your drop-off and pick-up locations.</h5>

        <Row>
          <Col>
            <Form.Control
                id='search'
                className="mb-2"
                placeholder="Type to search..."
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
              <span><Icon icon='search' /></span>
          </Col>
          {[ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role) && (
            <Col xs={12} md='auto'>
              <Button
                type='button'
                variant='primary'
                className='w-100 mb-1'
                onClick={() => navigate(ROUTES.CreateTournament)}
              >
                <Icon icon='plus' size={16} />
                <span>Create event</span>
              </Button>
            </Col>
          )}
        </Row>

        <Nav variant='tabs' onSelect={setActiveKey} activeKey={activeKey}>
          <Nav.Item>
            <Nav.Link eventKey={TABS.Active}>Active</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={TABS.Archived}>Archived</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={TABS.Upcoming}>Upcoming</Nav.Link>
          </Nav.Item>
          {[ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role) && <Nav.Item>
            <Nav.Link eventKey={TABS.PendingApproval}>Pending Approval</Nav.Link>
          </Nav.Item>
          }
        </Nav>
      </Col>
      {tournaments.length > 0
        ? (
        <Col xs={12}>
          <Form noValidate onSubmit={formik.handleSubmit} id='tournament-selection-form' className='mt-1 mb-3'>
            <ListGroup>
              {tournaments.map(t => (
                <ListGroup.Item key={t._id} className='d-flex justify-content-between align-items-center'>
                  <div className='d-flex flex-column'>
                    <h5>{t.name}</h5>
                    <span className='text-small text-muted'>{t.location.name}</span>
                    <span className='text-small text-muted'>{t.location.address}</span>
                    <span className='text-small text-muted'>{DateTime.fromISO(t.startDate).toLocaleString(DateTime.DATE_MED)} - {DateTime.fromISO(t.endDate).toLocaleString(DateTime.DATE_MED)}</span>
                  </div>
                  <div style={{ minWidth: 'fit-content' }}>
                    {[ROLE.SiteOwner, ROLE.SuperUser, ROLE.Admin].includes(currentUser.role) &&
                      <Button
                        type='button'
                        className='me-1'
                        variant='ghost'
                        onClick={() => navigate(`${ROUTES.Tournaments}/${t._id}`)}
                      >
                        Manage
                      </Button>
                    }
                    {[TABS.Active, TABS.Archived].includes(activeKey) &&
                      <Button
                        type='button'
                        variant='light'
                        active={formik.values.tournament?._id === t._id}
                        onClick={(e) => formik.setFieldValue('tournament', t, true)}
                      >
                        Select
                      </Button>
                    }
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Feedback
              type='invalid'
              className={formik.errors?.tournament ? 'd-block' : ''}
            >
              {formik.errors?.tournament}
            </Feedback>
          </Form>

          <ListPagination page={page} setPage={setPage} totalPages={totalPages} />
        </Col>)
        : (
          <Col xs={12} className='text-center my-3'>
            <p>No events found.</p>
          </Col>
          )
      }

      <Col xs={12} className='text-center'>
        <Button
          type='button'
          onClick={formik.handleSubmit}
          variant='primary'
          className='w-100 w-md-auto mb-4'
          disabled={!(formik.values.tournament._id && formik.values.tournament._id !== '')}
        >
          Continue
        </Button>
      </Col>
    </Row>
  )
}

export default EventList
