import { useEffect, useState } from 'react'
import useDataService from '../../hooks/useDataService'
import { Col, Row } from 'react-bootstrap'
import { parseStatus, STATUS } from '../../helpers'
import { object, string } from 'prop-types'

const propTypes = {
  event: object.isRequired,
  stringer: string
}

const StatsRow = ({ event, stringer }) => {
  const { apiGetMetrics } = useDataService()
  const [data, setData] = useState()

  const defaultValue = {
    total: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0
  }

  useEffect(() => {
    apiGetMetrics(event._id, 'status-count', { stringer }).then(res => {
      const data = res.length > 0 ? res : [defaultValue]
      setData(data)
    })
  }, [event, stringer])

  if (!data) {
    return null
  }

  return (
    <Row>
      <Col>
          <div className='bg-white rounded p-2 text-center'>
            <h5 className='mb-2'>Total</h5>
            <p className='fs-5 text-body'>{data[0].total}</p>
          </div>
        </Col>
      {[
        STATUS.Open.index,
        STATUS.Complete.index,
        STATUS.PickedUp.index
      ].map(k => (
        <Col key={k}>
          <div className='bg-white rounded p-2 text-center'>
            <h5 className='mb-2'>{parseStatus(k).name}</h5>
            <p className='fs-5 text-body'>{data[0][k]}</p>
          </div>
        </Col>
      ))}
    </Row>
  )
}

StatsRow.propTypes = propTypes

export default StatsRow
