import PromoCodeField from './PromoCodeField'
import useDataService from '../../../hooks/useDataService'
import { useFormikContext } from 'formik'

const DraftModePromoCodeFieldWrapper = () => {
  const { apiValidatePromoCode } = useDataService()
  const { setFieldValue } = useFormikContext()

  const validateCode = async (values) => {
    const res = await apiValidatePromoCode(values.code)
    if (res) {
      setFieldValue('promoCode', res)
    } else {
      throw new Error()
    }
  }

  const removeCode = () => setFieldValue('promoCode', { code: '', discount: 0 })

  return <PromoCodeField title removable onApply={validateCode} onRemove={removeCode} className='px-3 mb-3' />
}

export default DraftModePromoCodeFieldWrapper
