import { Image } from 'react-bootstrap'

const Loader = () => {
  return (
    <div className='text-center'>
      <Image src='/images/spinner.gif' width='50px' />
    </div>
  )
}
export default Loader
