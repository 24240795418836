import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'

const propTypes = {
  show: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  bodyText: PropTypes.string.isRequired,
  actionButtonText: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  danger: PropTypes.bool
}

const ConfirmationModal = ({ show, headerText, bodyText, actionButtonText, handleCancel, handleConfirm, danger }) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <h5>{headerText}</h5>
      </Modal.Header>
      <Modal.Body>
        <p className='text-body'>{bodyText}</p>
      </Modal.Body>
      <Modal.Footer className='flex-column flex-md-row'>
        <Button variant={danger ? 'danger' : 'primary'} className='flex-grow-1 align-self-stretch' onClick={handleConfirm}>
          {actionButtonText}
        </Button>
        <Button variant="ghost-neutral" className='flex-grow-1 align-self-stretch' onClick={handleCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

ConfirmationModal.propTypes = propTypes

export default ConfirmationModal
