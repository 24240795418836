import { number } from 'prop-types'
import { Col, Row } from 'react-bootstrap'

const propTypes = {
  size: number.isRequired,
  startAt: number,
  current: number.isRequired
}

const ProgressBar = ({ size, startAt = 0, current }) => {
  return (
    <Row className='gx-2 mx-2'>
    {Array(startAt).fill(null).map((e, i) => (
      <Col xs={12 / size} key={i} >
        <hr className='w-100 progress-section completed' />
      </Col>
    ))}
    {Array(size - startAt).fill(null).map((e, i) => (
      <Col xs={12 / size} key={i} >
        <hr className={`w-100 progress-section ${i <= current ? 'completed' : ''}`} />
      </Col>
    ))}
  </Row>
  )
}

ProgressBar.propTypes = propTypes

export default ProgressBar
