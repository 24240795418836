import { useContext, useEffect } from 'react'
import { Col, Collapse, Row } from 'react-bootstrap'
import { SharedDataContext } from '../../contexts/SharedDataContext'

const Banner = () => {
  const { banner, setBanner } = useContext(SharedDataContext)

  useEffect(() => {
    let timeout

    if (banner) {
      timeout = setTimeout(() => {
        setBanner()
      }, 3000)
    }

    return () => clearTimeout(timeout)
  }, [banner])

  return (
    <Collapse in={!!banner}>
      <Row className="justify-content-center text-white bg-success">
        <Col xs='auto' className='py-1'>{banner ?? <>&nbsp;</>}</Col>
      </Row>
    </Collapse>
  )
}

export default Banner
