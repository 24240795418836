import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import { ROUTES } from '../routes'

function TokenRefresh () {
  const navigate = useNavigate()

  const { refreshToken } = useAuth()

  useEffect(() => {
    refreshToken().then(() => navigate(ROUTES.Dashboard))
  }, [])

  return null
}

export default TokenRefresh
