import PropTypes from 'prop-types'
import { Button, Modal } from 'react-bootstrap'
import useDataService from '../../hooks/useDataService'
import { useContext, useState } from 'react'
import { OrderContext } from '../../contexts/OrderContext'

const propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

const StringerRemovalModal = ({ show, close }) => {
  const { order, setOrder } = useContext(OrderContext)
  const { apiDeleteStringer } = useDataService()
  const [submitting, setSubmitting] = useState(false)

  const handleConfirm = async () => {
    setSubmitting(true)
    const res = await apiDeleteStringer(order._id)
    setOrder(res)
    setSubmitting(false)
    close()
  }

  return (
    <Modal show={show}>
      <Modal.Header>
        <h5>Are you sure?</h5>
      </Modal.Header>
      <Modal.Body>
        <p className='text-body'>Do you want to remove the assigned stringer?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleConfirm} disabled={submitting}>
          Remove
        </Button>
        <Button variant="info" onClick={close} disabled={submitting}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

StringerRemovalModal.propTypes = propTypes

export default StringerRemovalModal
