import { RacquetModel } from './RacquetModel'
import { StringAxis } from './StringAxis'

export class Racquet {
  model = new RacquetModel()

  editingName = false
  sameMainsCrosses = true
  measure = 'lb'
  mains = new StringAxis()

  crosses = new StringAxis()

  knots = 4
  stencil = {
    logo: 'none',
    color: '-'
  }

  overgrip = false
  notes = ''
  quantity = 1
  coachRacquet = false

  addOns = []

  constructor (retailVenue) {
    this.mains = new StringAxis(!retailVenue)
    this.crosses = new StringAxis(!retailVenue)
  }
}
