import { useContext } from 'react'
import OrderDetailsStep from './OrderDetailsStep'
import { OrderFormContext } from '../../contexts/OrderFormContext'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import Checkout from './Checkout'
import useAuth from '../../hooks/useAuth'
import AdminCheckout from './AdminCheckout'
import { ROLE } from '../../helpers'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const CreateOrderManager = () => {
  const { currentUser } = useAuth()
  const { step, savedDraft } = useContext(OrderFormContext)

  switch (step) {
    case 0:
      return <OrderDetailsStep />
    case 1:
      return [ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role)
        ? <AdminCheckout />
        : <Elements options={{ clientSecret: savedDraft.clientSecret }} stripe={stripePromise}>
          <Checkout />
        </Elements>
  }
}

export default CreateOrderManager
