import { bool } from 'prop-types'
import { useContext } from 'react'
import { Alert, Button } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { ROLE } from '../../helpers'
import useDataService from '../../hooks/useDataService'
import { EventContext } from '../../contexts/EventContext'

const propTypes = {
  show: bool.isRequired
}

const ApprovalPendingAlert = ({ show }) => {
  const { currentUser } = useAuth()
  const { event, update } = useContext(EventContext)
  const { apiPatchTournamentApproval } = useDataService()

  const handleApprove = async () => {
    const t = await apiPatchTournamentApproval(event._id)
    update(t)
  }

  return (
    <Alert show={show} variant='secondary'>
      <Alert.Heading>Approval pending</Alert.Heading>
      <hr />
      <p>Players and tournament staff will not see this tournament until it is approved.</p>
      {currentUser.role === ROLE.SiteOwner && <div className='mt-2'>
          <Button onClick={handleApprove} variant='primary' className='w-100'>
            Approve tournament
          </Button>
      </div>}
    </Alert>
  )
}

ApprovalPendingAlert.propTypes = propTypes

export default ApprovalPendingAlert
