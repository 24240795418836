import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap'
import PropTypes from 'prop-types'
import BackButton from '../common/BackButton'
import { useFormikContext } from 'formik'

const propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
}

const PaymentStatusFilter = ({ show, close }) => {
  const formik = useFormikContext()

  const handleClear = () => {
    const values = formik.values
    values.filters.paymentStatus = []
    formik.resetForm(values)
  }

  return (
    <Offcanvas show={show} onHide={close} placement='end'>
      <Offcanvas.Header>
        <BackButton onClick={close} />
        <Offcanvas.Title className='flex-grow-1 text-center'>
          Payment
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form noValidate className='ms-5 me-5 mb-5'>
          <Form.Group controlId='filters.status'>
            {[true, false].map(s => (
              <Form.Check
                key={s}
                type="checkbox"
                name='filters.paymentStatus'
                onChange={formik.handleChange}
                id={`${s}`}
                label={s ? 'paid' : 'pending'}
                value={s}
                checked={formik.values.filters.paymentStatus.filter(v => v === s.toString()).length === 1}
              />

            ))}
          </Form.Group>
        </Form>
        <Row className='justify-content-between mt-5'>
          <Col xs='auto' className='flex-grow-1'>
            <Button
              disabled={formik.values.filters.paymentStatus.length === 0}
              type='button'
              variant='info'
              className='w-100'
              onClick={handleClear}
            >Clear all</Button>
          </Col>
          <Col xs='auto' className='flex-grow-1'>
            <Button
              type='button'
              variant='primary'
              className='w-100'
              onClick={close}
            >Save</Button>
          </Col>
        </Row>
      </Offcanvas.Body>
    </Offcanvas>
  )
}

PaymentStatusFilter.propTypes = propTypes

export default PaymentStatusFilter
