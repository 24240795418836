import { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { OrderContext } from '../../contexts/OrderContext'
import { DateTime } from 'luxon'
import RacquetAccordion from './RacquetAccordion'
import StringerAssignment from './StringerAssignment'
import BackButton from '../common/BackButton'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'
import StatusControl from './StatusBadge'
import UserIcon from '../common/UserIcon'

const StringerOrderView = () => {
  const navigate = useNavigate()
  const { order, setOrder } = useContext(OrderContext)

  return (
    <Container>
      <Row className='justify-content-between align-items-center'>
        <Col xs='auto' className='row gx-3 align-items-center'>
          <Col xs='auto'>
            <BackButton onClick={() => navigate(ROUTES.Dashboard)} />
          </Col>
          <Col xs='auto'>
            <h2 className='mb-0'>#{order?.id}</h2>
          </Col>
          <Col xs='auto' className='align-self-end'>
            <p className='text-small'>{DateTime.fromISO(order.orderDate).toLocaleString(DateTime.DATETIME_SHORT)}</p>
          </Col>
        </Col>
        <Col xs='auto' className='row gx-1 justify-content-around align-items-stretch'>
          <Col xs='auto'>
            <StatusControl friendlyOrderId={order.id} orderId={order._id} status={order.status} update={setOrder} />
          </Col>
        </Col>
      </Row>
      <h4>Info</h4>
      <p className='text-small'>Scheduled pickup date & time</p>
      <p className='text-body pb-1'>{DateTime.fromISO(order.dueDate).toLocaleString(DateTime.DATETIME_SHORT)}</p>
      <p className='text-small'>Tournament</p>
      <p className='text-body'>{order.tournament.name}</p>
      <p className='text-small'>Customer</p>
      <Row className='align-items-center'>
        <Col xs='auto'>
          <UserIcon user={order.customer} />
        </Col>
        <Col xs='auto'>
          <p className='text-body'>{order.customer.firstName} {order.customer.lastName}</p>
          <p className='text-body'>{order.customer.email}</p>
        </Col>
      </Row>
      <hr />
      <h4>Re-string</h4>
      <RacquetAccordion racquets={order.racquets} />
      <Row className='my-4 mx-1'>
        <Col xs={6}>
          <h5 className='text-body'>Grand  total</h5>
        </Col>
        <Col xs={6} className='text-end'>
          <h5 className='text-body'>${order.total.toFixed(2)}</h5>
        </Col>
      </Row>
      <StringerAssignment />
    </Container>
  )
}

export default StringerOrderView
