import { useContext, useState } from 'react'
import { OrderContext } from '../../contexts/OrderContext'
import Icon from '../../icons/Icon'
import { Button, Col, Row } from 'react-bootstrap'
import StringerSelection from './StringerSelection'
import StringerRemovalModal from './StringerRemovalModal'
import useAuth from '../../hooks/useAuth'
import UserIcon from '../common/UserIcon'
import { ROLE } from '../../helpers'

const StringerAssignment = () => {
  const { currentUser } = useAuth()
  const { order, setOrder } = useContext(OrderContext)
  const [showSelection, setShowSelection] = useState(false)
  const [showModal, setShowModal] = useState(false)

  return (
    <Row className='mt-5 mb-5 align-items-center'>
      <StringerSelection
        orderId={order._id}
        friendlyOrderId={order.id}
        update={setOrder}
        friendlyOrderIdshow={showSelection}
        show={showSelection}
        close={() => setShowSelection(false)}
      />
      <StringerRemovalModal show={showModal} close={() => setShowModal(false)} />
      <Col xs='auto'>
        {order.stringer
          ? <UserIcon user={order.stringer}/>
          : <Icon icon='frown' size='3.5rem' />
        }
      </Col>
      <Col className='d-flex flex-column'>
        <p className='text-small text-muted'>Assigned stringer</p>
        {order.stringer
          ? <h5>{order.stringer.firstName} {order.stringer.lastName}</h5>
          : <h5 className='text-muted'>None</h5>
        }
      </Col>
      {[ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role) && <Col className='text-end'>
        <Button
          type='button'
          variant='ghost'
          onClick={order.stringer ? () => setShowModal(true) : () => setShowSelection(true)}
        >
          {order.stringer ? 'Remove' : 'Assign'}
        </Button>
      </Col>
      }
    </Row>
  )
}

export default StringerAssignment
