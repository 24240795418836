import useAuth from '../../hooks/useAuth'
import { useContext } from 'react'
import { SharedDataContext } from '../../contexts/SharedDataContext'
import UserIcon from './UserIcon'
import Icon from '../../icons/Icon'
import { Col } from 'react-bootstrap'
import { ROLE } from '../../helpers'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes'

const User = () => {
  const { currentUser } = useAuth()
  const { user } = useContext(SharedDataContext)
  const navigate = useNavigate()

  return (
    <Col xs='auto' className='d-none d-md-flex justify-content-start justify-content-md-center justify-content-lg-start align-items-center w-100 mt-5 mb-3 pe-0'>
      { currentUser
        ? <UserIcon user={user} onClick={currentUser.role === ROLE.Player ? () => navigate(`${ROUTES.MyProfile}`) : undefined } />
        : <Icon icon='user' size={32} />
      }
      <span className='d-md-none d-lg-block ms-1'>
        { currentUser
          ? <>{user?.firstName}<br/><span className='text-muted'>{currentUser.role}</span></>
          : <>Log In</>
        }
      </span>
    </Col>
  )
}

export default User
