import { Container } from 'react-bootstrap'
import EventForm from '../components/events/EventForm'

const CreateEvent = () => {
  return (
    <Container className='h-100 px-md-5 ms-md-0 mw-750'>
      <h2 className='mb-2'>Create tournament</h2>
      <h5 className='text-muted mb-3'>Set up a new tournament for upcoming orders.</h5>
      <hr/>
      <EventForm />
    </Container>
  )
}

export default CreateEvent
