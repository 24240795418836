import { capitalize } from 'lodash'
import { useContext, useState } from 'react'
import { Button, Col, Container, ListGroup, Row } from 'react-bootstrap'
import UserIcon from '../common/UserIcon'
import UserSearch from '../common/UserSearch'
import { ROLE } from '../../helpers'
import useDataService from '../../hooks/useDataService'
import NewUserModal from './NewUserModal'
import { EventContext } from '../../contexts/EventContext'
import Icon from '../../icons/Icon'
import ConfirmationModal from '../generic/ConfirmationModal'

const StaffManagement = () => {
  const { event, update, refresh } = useContext(EventContext)
  const { apiUpdateTournamentStaff, apiRemoveTournamentStaff } = useDataService()
  const [showNewUserModal, setShowNewUserModal] = useState(false)
  const [showRemoveStaffModal, setShowRemoveStaffModal] = useState(false)
  const [removalModalData, setRemovalModalData] = useState()

  const addStaff = async (u) => {
    const t = await apiUpdateTournamentStaff(event._id, u)
    update(t)
  }

  const handleRemove = async (user) => {
    setRemovalModalData({
      name: user.fullName,
      onConfirm: async () => {
        const t = await apiRemoveTournamentStaff(event._id, user._id)
        update(t)
        setShowRemoveStaffModal(false)
      }
    })
    setShowRemoveStaffModal(true)
  }

  return (
    <Container className='ps-md-5 pt-2 ms-md-0 mw-750'>
      <ConfirmationModal
        show={showRemoveStaffModal}
        headerText='Reset staff member?'
        bodyText={`Are you sure you want to remove ${removalModalData?.name} from this tournament?`}
        actionButtonText='Remove'
        handleCancel={() => setShowRemoveStaffModal(false)}
        handleConfirm={removalModalData?.onConfirm}
        danger
      />
      <NewUserModal show={showNewUserModal} onHide={() => setShowNewUserModal(false)} onCreated={refresh} />
      <h4>Tournament Staff</h4>
      <p className='ms-1'>Look up an existing user or onboard a new team member</p>
      <UserSearch roles={[ROLE.Admin, ROLE.Stringer]} onUserSelected={addStaff} limit={5} onNewUserRequested={() => setShowNewUserModal(true)} />
      <Row>
        {['admins', 'stringers'].map(role => (
          <Col xs={12} md={6} key={role} className='mt-2'>
            <h5>{capitalize(role)}</h5>
            {event[role].length > 0
              ? (
                <ListGroup variant='flush'>
                  {event[role].map(user => (
                    <ListGroup.Item key={user._id}>
                      <Row className='align-items-center justify-content-between no-wrap'>
                        <Col xs={2}>
                          <UserIcon user={user} />
                        </Col>
                        <Col xs={8} className='d-flex flex-column text-truncate'>
                          <span className='text-body'>{user.firstName} {user.lastName}</span>
                          <span className='text-small'>{user.email}</span>
                        </Col>
                        <Col xs={2}>
                          <Button
                            variant='icon-wrapper'
                            onClick={() => handleRemove(user)}
                          >
                            <Icon icon='close' size={14} />
                          </Button>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                )
              : <p>No {role} assigned yet.</p>
            }
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default StaffManagement
