import { Formik } from 'formik'
import { Button, Container, Form, Modal } from 'react-bootstrap'
import { object, string } from 'yup'
import { Location } from '../../classes/Location'
import * as PropTypes from 'prop-types'
import useDataService from '../../hooks/useDataService'

const propTypes = {
  show: PropTypes.bool.isRequired,
  saveLocation: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired
}

const LocationModal = ({ show, saveLocation, onHide }) => {
  const { apiCreateLocation } = useDataService()

  const locationSchema = object({
    name: string().required(),
    address: string().required(),
    phone: string().required().matches(/^[+]?[0-9]+$/),
    hours: string().required()
  })

  const createLocation = async (values, bag) => {
    bag.setSubmitting(true)
    const l = await apiCreateLocation(values)
    saveLocation(l)
  }

  return (
    <Modal show={show} fullscreen={true} onHide={onHide}>
      <Modal.Header closeButton className='mw-750 w-75 mx-auto'>
        <Modal.Title>Add new location</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className='mw-750'>
          <Formik
            initialValues={new Location()}
            validationSchema={locationSchema}
            onSubmit={createLocation}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit
            }) => (
              <Form noValidate onSubmit={evt => {
                console.log(errors)
                handleSubmit(evt)
              }}>
                <h5 className='mb-2'>Location name</h5>
                <Form.Group controlId='name' className='mb-5'>
                  <Form.Control
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    placeholder='Location name'
                    isInvalid={!!errors.name && touched.name}
                  />
                  <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>

                <h5 className='mb-2'>Address</h5>
                <Form.Group controlId='address' className='mb-5'>
                  <Form.Control
                    type="text"
                    value={values.address}
                    onChange={handleChange}
                    placeholder='Address'
                    isInvalid={!!errors.address && touched.address}
                  />
                  <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                </Form.Group>

                <h5 className='mb-2'>Phone</h5>
                <Form.Group controlId='phone' className='mb-5'>
                  <Form.Control
                    type="text"
                    value={values.phone}
                    onChange={handleChange}
                    placeholder='Phone number'
                    isInvalid={!!errors.phone && touched.phone}
                  />
                  <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                </Form.Group>

                <h5 className='mb-2'>Business hours</h5>
                <Form.Group controlId='hours' className='mb-5'>
                  <Form.Control
                    type="text"
                    value={values.hours}
                    onChange={handleChange}
                    placeholder='Location name'
                    isInvalid={!!errors.hours && touched.hours}
                  />
                  <Form.Control.Feedback type="invalid">{errors.hours}</Form.Control.Feedback>
                </Form.Group>
                <div className='text-center'>
                  <Button
                    type='submit'
                    variant='primary'
                  >Create</Button>
                </div>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

LocationModal.propTypes = propTypes

export default LocationModal
