import { object } from 'prop-types'
import { parseChartsData } from './chart-helpers'
import AnalyticsChart from './AnalyticsChart'

const propTypes = {
  event: object.isRequired
}

const DropOffTimesChart = ({ event }) => {
  const CHART_ID = 'drop-off-time-count'

  const parseData = data => {
    const parsedData = parseChartsData(data)
    if (parsedData.length === 2) {
      parsedData.push([parsedData[1][0] + 1, 0])
    }
    return parsedData
  }

  const options = {
    colors: ['#64B0BA'],
    legend: {
      position: 'none'
    },
    hAxis: {
      ticks: [
        { v: 3, f: '3am' },
        { v: 6, f: '6am' },
        { v: 9, f: '9am' },
        { v: 12, f: '12pm' },
        { v: 15, f: '3pm' },
        { v: 18, f: '6pm' },
        { v: 21, f: '9pm' },
        { v: 24, f: '12am' }
      ]
    }
  }

  return (
    <AnalyticsChart
      title='Drop-Off Times'
      chartId={CHART_ID}
      chartType="ColumnChart"
      parseData={parseData}
      options={options}
      event={event}
    />
  )
}

DropOffTimesChart.propTypes = propTypes

export default DropOffTimesChart
