import { Navigate, useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { ROUTES } from '../../routes'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import Unauthorized from '../../pages/Unauthorized'

const propTypes = {
  children: PropTypes.element,
  roles: PropTypes.array
}

function PrivateRoute ({ children, roles }) {
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUser) {
      navigate(ROUTES.Home)
    }
  }, [currentUser])

  // user context is NULL => user is not logged in => redirect to login page
  if (!currentUser) {
    return <Navigate to={ROUTES.Home}/>
  }

  if (roles && !roles.includes(currentUser.role)) {
    return <Unauthorized />
  }

  // none of the scenarios above returned => user is allowed to see this resource
  return children
}

PrivateRoute.propTypes = propTypes

export default PrivateRoute
