import { find } from 'lodash'
import { Dropdown, Form } from 'react-bootstrap'
import Feedback from 'react-bootstrap/esm/Feedback'
import PropTypes from 'prop-types'
import useDataService from '../../hooks/useDataService'
import { forwardRef, useEffect, useState } from 'react'
import Icon from '../../icons/Icon'
import { useFormikContext } from 'formik'

const propTypes = {
  axis: PropTypes.string.isRequired
}

const StringSelection = ({ axis }) => {
  const { values, errors, handleChange, touched, setFieldValue } = useFormikContext()

  return (
    <div className='mb-5'>
      <Form.Group controlId={`${axis}.ownStrings`} className='mb-2'>
        <Form.Check
          name={`${axis}.ownStrings`}
          type="checkbox"
          checked={values[axis].strings.ownStrings}
          onChange={e => {
            setFieldValue(`${axis}.strings.ownStrings`, e.target.checked)
            if (!e.target.checked) {
              setFieldValue(`${axis}.strings`, { ownStrings: false })
            }
          }}
          label="I will bring my own strings"
        />
      </Form.Group>
      <StringsDropdown
        fieldName={`${axis}.strings`}
        fieldValue={values[axis].strings}
        error={errors[axis]?.strings?.title}
        touched={touched[axis]?.strings?.id}
        ownStrings={values[axis].strings?.ownStrings}
      />

      {values[axis].strings.title === 'other' &&
        <Form.Group controlId={`${axis}.strings.other`} className='mb-5'>
          <Form.Control
            type="text"
            value={values[axis].strings.other}
            onChange={handleChange}
            placeholder="Specify other strings"
            isInvalid={!!errors[axis]?.strings?.other && touched[axis]?.strings?.other}
          />
          <Form.Control.Feedback type="invalid">{errors[axis]?.strings?.other}</Form.Control.Feedback>
        </Form.Group>
      }
    </div>
  )
}

const dropdownPropTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.object.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
  ownStrings: PropTypes.bool
}

const StringsDropdown = ({ fieldName, fieldValue, error, touched, ownStrings }) => {
  const { setFieldValue } = useFormikContext()
  const { apiGetStrings } = useDataService()
  const [strings, setStrings] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    apiGetStrings().then(setStrings)
  }, [])

  const handleSelect = async eventKey => {
    if (eventKey === 'other') {
      setFieldValue(fieldName, { ownStrings: true, title: 'other', other: '' }, true)
    } else {
      const selected = find(strings, s => s._id === eventKey)
      await setFieldValue(fieldName, { ...selected, ownStrings }, true)
    }
  }

  return (
    <Dropdown
      onSelect={handleSelect}
      className="mb-2"
    >
      <Dropdown.Toggle
        variant='input'
        className={`w-100 justify-content-between ${error && touched ? 'is-invalid' : ''}`}
      >
        {fieldValue.title ? fieldValue.title : 'Select strings' }
      </Dropdown.Toggle>
      <Feedback type='invalid'>Select strings</Feedback>
      <Dropdown.Menu as={CustomMenu} search={search} setSearch={setSearch} className='w-100 shadow-lg mt-1 bg-white'>
        {strings.filter(s => s.title.toLowerCase().includes(search.toLowerCase())).map(s => (
          <Dropdown.Item key={s._id} eventKey={s._id}>{s.title}</Dropdown.Item>
        ))}
        { ownStrings &&
          <Dropdown.Item eventKey="other"><u>+ Add option</u></Dropdown.Item>
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}
StringsDropdown.propTypes = dropdownPropTypes

const CustomMenu = forwardRef(
  ({ children, search, setSearch, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <div className='m-1'>
          <Form.Control
            autoFocus
            className="mb-1 border"
            placeholder="Type to filter..."
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </div>
        <ul className="list-unstyled">
          {children}
        </ul>
      </div>
    )
  }
)
CustomMenu.displayName = 'CustomMenu'
CustomMenu.propTypes = {
  children: PropTypes.any,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  style: PropTypes.any,
  className: PropTypes.string,
  'aria-labelledby': PropTypes.string
}

const CustomToggle = forwardRef(({ children, onClick }, ref) => {
  return (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
      <Icon icon='drop-arrow' />
    </a>
  )
})

CustomToggle.displayName = 'CustomToggle'
CustomToggle.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func
}

StringSelection.propTypes = propTypes

export default StringSelection
