import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import useAuth from '../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../routes'

const propTypes = {
  actionCode: PropTypes.string.isRequired
}

const EmailConfirmation = ({ actionCode }) => {
  const { verifyEmail, currentUser, reloadUser } = useAuth()
  const navigate = useNavigate()
  const [done, setDone] = useState(false)

  useEffect(() => {
    verifyEmail(actionCode).then(() => {
      if (currentUser) {
        reloadUser()
      }
      setDone(true)
    })
  }, [])

  useEffect(() => {
    if (done) {
      navigate(ROUTES.Dashboard)
    }
  }, [done])

  return <p>Loading...</p>
}

EmailConfirmation.propTypes = propTypes

export default EmailConfirmation
