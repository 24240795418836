import { Button } from 'react-bootstrap'

const Style = () => {
  return (
    <div>
      <h1>Heading 1</h1>
      <h2>Heading 1</h2>
      <h3>Heading 1</h3>
      <h4>Heading 1</h4>
      <h5>Heading 1</h5>
      <span>Test body text</span>
      <p className='text-small'>Test test</p>
      <p>Primary button<br/><Button variant='primary'>Button text</Button></p>
      <p>Secondary blue (secondary) button<br/><Button variant='secondary'>Button text</Button></p>
      <p>Secondary grey (info) button<br/><Button variant='info'>Button text</Button></p>
      <p>Tertiary (light) button<br/><Button variant='light'>Button text</Button></p>
      <p>Ghost button<br/><Button variant='ghost'>Button text</Button></p>
      <p>Danger button<br/><Button variant='danger'>Button text</Button></p>
    </div>
  )
}

export default Style
