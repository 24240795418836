import { useEffect, useState } from 'react'
import useDataService from '../../hooks/useDataService'
import { Chart } from 'react-google-charts'
import { array, bool, func, object, string } from 'prop-types'
import Loader from './Loader'
import { DateTime } from 'luxon'

const propTypes = {
  chartId: string.isRequired,
  chartType: string.isRequired,
  parseData: func.isRequired,
  options: object.isRequired,
  formatters: array,
  event: object.isRequired,
  title: string.isRequired,
  filters: object,
  calculateTicks: bool
}

const AnalyticsChart = ({ chartId, chartType, parseData, options, formatters, event, title, filters, calculateTicks }) => {
  const { apiGetMetrics } = useDataService()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()

  const [commonOptions, setCommonOptions] = useState({
    fontName: 'DM Sans',
    backgroundColor: '#F7F7F8'
    // chartArea: {
    //   left: 0,
    //   right: 0
    // }
  })

  useEffect(() => {
    setLoading(true)
    apiGetMetrics(event._id, chartId, filters).then(res => {
      const parsedData = parseData(res)
      if (calculateTicks) {
        setCommonOptions({
          ...commonOptions,
          hAxis: {
            ticks: parsedData.slice(1).map(row => ({
              v: row[0],
              f: DateTime.fromJSDate(row[0]).toFormat('MMM d')
            }))
          }
        })
      }
      setLoading(false)
      setData(parsedData)
    })
  }, [event, filters])

  useEffect(() => {
    console.log(commonOptions)
  }, [commonOptions])

  if (loading) {
    return <Loader />
  } else if (data?.length === 0) {
    return (
    <div className='text-center'>
      <h5>{title}</h5>
      No data to display
    </div>
    )
  }

  return (
    <div>
      <h5 className='text-center'>{title}</h5>
      <Chart
        chartType={chartType}
        data={data}
        options={{
          ...commonOptions,
          ...options
        }}
        formatters={formatters}
        loader={<Loader />}
      />
    </div>
  )
}

AnalyticsChart.propTypes = propTypes

export default AnalyticsChart
