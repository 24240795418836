import { useRef, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import useRootClose from 'react-overlays/useRootClose'
import InStorePaymentModal from '../order-view/InStorePaymentModal'
import useDataService from '../../hooks/useDataService'
import { func, object } from 'prop-types'
import { ROLE } from '../../helpers'
import useAuth from '../../hooks/useAuth'

const propTypes = {
  order: object.isRequired,
  update: func.isRequired
}

const PaymentControlCell = ({ order, update }) => {
  const ref = useRef()
  const { currentUser } = useAuth()
  const { apiGetOrder } = useDataService()
  const [show, setShow] = useState(false)
  const [modalData, setModalData] = useState({ show: false })

  const handleRootClose = event => {
    event.stopPropagation()
    event.preventDefault()
    setShow(false)
  }

  useRootClose(ref, handleRootClose, { disabled: !show })

  const handleSelect = async (e, eventKey) => {
    e.stopPropagation()
    console.log(eventKey)
    const o = await apiGetOrder(order._id)
    setModalData({
      show: true,
      paymentMethod: eventKey,
      paymentMethodDesc: eventKey === 'eft' ? 'in-store' : 'prize money',
      total: o.total
    })
  }

  if (![ROLE.Admin, ROLE.SuperUser, ROLE.SiteOwner].includes(currentUser.role)) {
    return order.paid ? 'Paid' : 'Not paid'
  } else if (order.paid) {
    return 'Paid'
  } else if (order.paymentMethod === 'prize') {
    return 'Prize Money'
  } else if (order.cancelled) {
    return 'Not paid'
  } else {
    return (
      <>
        {modalData.show && (
          <InStorePaymentModal
            {...modalData}
            orderId={order._id}
            friendlyOrderId={order.id}
            update={update}
            close={() => {
              setShow(false)
              setModalData({ show: false })
            }}
          />
        )}
        <Dropdown show={show}>
          <Dropdown.Toggle
            variant="link"
            className='px-0'
            onClick={e => {
              console.log('in onclick', show)
              e.stopPropagation()
              setShow(!show)
            }}
          >Confirm</Dropdown.Toggle>
          <Dropdown.Menu ref={ref} className='w-100 bg-white shadow-sm'>
            <Dropdown.Item onClick={e => handleSelect(e, 'eft')} eventKey='eft'>In-store</Dropdown.Item>
            <Dropdown.Item onClick={e => handleSelect(e, 'prize')} eventKey='prize'>Prize Money</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    )
  }
}

PaymentControlCell.propTypes = propTypes

export default PaymentControlCell
